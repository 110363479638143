var DrawUtil = typeof exports === "undefined" ? function numeric() {} : exports;
if (typeof global !== "undefined") {
  global.DrawUtil = DrawUtil;
}

DrawUtil.fctCalcRectangleCoordinate = function fctCalcRectangleCoordinate(
  x1,
  y1,
  x2,
  y2,
  imageLocation,
  imageSize
) {
  if (!!imageLocation && !!imageSize) {
    let xmin = imageLocation.x;
    let xmax = imageLocation.x + imageSize.width;
    let ymin = imageLocation.y;
    let ymax = imageLocation.y + imageSize.height;

    x1 = fctCheckMinMax(x1, xmin, xmax);
    y1 = fctCheckMinMax(y1, ymin, ymax);
    x2 = fctCheckMinMax(x2, xmin, xmax);
    y2 = fctCheckMinMax(y2, ymin, ymax);
  }

  let width = Math.abs(x1 - x2);
  let height = Math.abs(y1 - y2);

  let x = x1 < x2 ? x1 : x2;
  let y = y1 < y2 ? y1 : y2;

  return {
    x: x,
    y: y,
    width: width,
    height: height,
  };
};

function fctCheckMinMax(p, min, max) {
  if (p < min) return min;
  else if (p > max) return max;
  else return p;
}

DrawUtil.fctCalcRectangleCoordinateForCenterStart =
  function fctCalcRectangleCoordinateForCenterStart(size1, size2) {
    return {
      x: fctCalcCoordinateForCenterStart(size1.width, size2.width),
      y: fctCalcCoordinateForCenterStart(size1.height, size2.height),
    };
  };

DrawUtil.fctCalcRectangleCoordinateForCenterEnd =
  function fctCalcRectangleCoordinateForCenterEnd(size1, size2) {
    return {
      x: fctCalcCoordinateForCenterEnd(size1.width, size2.width),
      y: fctCalcCoordinateForCenterEnd(size1.height, size2.height),
    };
  };

function fctCalcCoordinateForCenterStart(length1, length2) {
  // return Math.abs(length1 - length2) / 2;
  return (length1 - length2) / 2;
}

function fctCalcCoordinateForCenterEnd(length1, length2) {
  // return Math.abs(length1 - length2) / 2 + ((length1 < length2) ? length1 : length2);
  return fctCalcCoordinateForCenterStart(length1, length2) + length2;
}

DrawUtil.fctGetImage = function fctGetImage(imageUrl) {
  var image = new window.Image();

  return new Promise((ok, no) => {
    image.onload = () => {
      return ok(image);
    };
    image.src = imageUrl;
  });
};

/****************************************************************************************************
 * 파라미터
 *      transformBoxId, transformBoxX, transformBoxY : 선택한 변형 커서의 ID 및 좌표
 *      selectedBoundingBoxX, selectedBoundingBoxY, selectedBoundingBoxWidth, selectedBoundingBoxHeight : 선택되어 있는 바운딩박스의 좌표 및 크기
 *      imageSize : 원시이미지 크기 {width, height}
 *      TRANSFORMBOXSIZE : 변형 조정 박스 크기 (int)
 *      TRANSFORM_MINIMUM_LENGTH : 변형 최소 크기 (int)
 * 리턴
 *      변형된 바운딩 박스의 좌표 및 크기 : { x, y, x2, y2, width, height }
 *
 ****************************************************************************************************/
DrawUtil.fctGetTransformTargetCoordinate =
  function fctGetTransformTargetCoordinate(
    transformBoxId,
    transformBoxX,
    transformBoxY,
    imageSize,
    selectedBoundingBoxX,
    selectedBoundingBoxY,
    selectedBoundingBoxWidth,
    selectedBoundingBoxHeight,
    TRANSFORMBOXSIZE,
    TRANSFORM_MINIMUM_LENGTH
  ) {
    let halfSize = TRANSFORMBOXSIZE / 2;

    var tx = transformBoxX + halfSize;
    var ty = transformBoxY + halfSize;

    var xa = selectedBoundingBoxX;
    var ya = selectedBoundingBoxY;
    var xb = xa + selectedBoundingBoxWidth;
    var yb = ya + selectedBoundingBoxHeight;

    if (
      transformBoxId === "1" ||
      transformBoxId === "8" ||
      transformBoxId === "7"
    ) {
      xa = tx;

      if (xb - xa <= TRANSFORM_MINIMUM_LENGTH) {
        xa = xb - TRANSFORM_MINIMUM_LENGTH;
      } else if (xa <= 0) {
        xa = 0;
      }
    }

    if (
      transformBoxId === "3" ||
      transformBoxId === "4" ||
      transformBoxId === "5"
    ) {
      xb = tx;

      if (xb - xa <= TRANSFORM_MINIMUM_LENGTH) {
        xb = xa + TRANSFORM_MINIMUM_LENGTH;
      } else if (xb >= imageSize.width) {
        xb = imageSize.width;
      }
    }

    if (
      transformBoxId === "1" ||
      transformBoxId === "2" ||
      transformBoxId === "3"
    ) {
      ya = ty;

      if (yb - ya <= TRANSFORM_MINIMUM_LENGTH) {
        ya = yb - TRANSFORM_MINIMUM_LENGTH;
      } else if (ya <= 0) {
        ya = 0;
      }
    }

    if (
      transformBoxId === "5" ||
      transformBoxId === "6" ||
      transformBoxId === "7"
    ) {
      yb = ty;

      if (yb - ya <= TRANSFORM_MINIMUM_LENGTH) {
        yb = ya + TRANSFORM_MINIMUM_LENGTH;
      } else if (yb >= imageSize.height) {
        yb = imageSize.height;
      }
    }

    return {
      x: xa,
      y: ya,
      x2: xb,
      y2: yb,
      width: xb - xa,
      height: yb - ya,
    };
  };

/**
 * 함수 설명
 * @param {RectInfo} targetRectInfo 변형하고자 하는 사각형 정보 (x, y, w, h)
 * @param {int} TRANSFORMBOXSIZE 변형 조정 박스 크기
 * @returns
 *      {
 *          boxes : [ {key, box: {x, y, width, height}} ] // 변형 커서 8개의 Key 및 사각형 정보
 *          lines : [] // 바운딩 박스 테두리
 *          boundingBox : [ {x, y, width, height} ] // 바운딩 박스 사각형 정보
 *      }
 */
DrawUtil.fctGetTransformBoxCoordinates = function fctGetTransformBoxCoordinates(
  targetRectInfo,
  TRANSFORMBOXSIZE
) {
  let halfSize = TRANSFORMBOXSIZE / 2;

  let x = targetRectInfo.x;
  let y = targetRectInfo.y;
  let width = targetRectInfo.width;
  let height = targetRectInfo.height;

  let x1 = x - halfSize;
  let y1 = y - halfSize;
  let x2 = x1 + width;
  let y2 = y1 + height;
  let hx = (x2 - x1) / 2 + x1;
  let hy = (y2 - y1) / 2 + y1;

  /********************************
   * 1 2 3
   * 8   4
   * 7 6 5
   ********************************/
  var transformBoxes = {
    boxes: [
      {
        key: "1",
        box: {
          x: x1,
          y: y1,
          width: TRANSFORMBOXSIZE,
          height: TRANSFORMBOXSIZE,
        },
      },
      {
        key: "2",
        box: {
          x: hx,
          y: y1,
          width: TRANSFORMBOXSIZE,
          height: TRANSFORMBOXSIZE,
        },
      },
      {
        key: "3",
        box: {
          x: x2,
          y: y1,
          width: TRANSFORMBOXSIZE,
          height: TRANSFORMBOXSIZE,
        },
      },
      {
        key: "4",
        box: {
          x: x2,
          y: hy,
          width: TRANSFORMBOXSIZE,
          height: TRANSFORMBOXSIZE,
        },
      },
      {
        key: "5",
        box: {
          x: x2,
          y: y2,
          width: TRANSFORMBOXSIZE,
          height: TRANSFORMBOXSIZE,
        },
      },
      {
        key: "6",
        box: {
          x: hx,
          y: y2,
          width: TRANSFORMBOXSIZE,
          height: TRANSFORMBOXSIZE,
        },
      },
      {
        key: "7",
        box: {
          x: x1,
          y: y2,
          width: TRANSFORMBOXSIZE,
          height: TRANSFORMBOXSIZE,
        },
      },
      {
        key: "8",
        box: {
          x: x1,
          y: hy,
          width: TRANSFORMBOXSIZE,
          height: TRANSFORMBOXSIZE,
        },
      },
    ],
    lines: [x, y, x + width, y, x + width, y + height, x, y + height, x, y],
    boundingBox: { x: x, y: y, width: width, height: height },
  };

  return transformBoxes;
};

/**
 * https://velog.io/@baby_dev/%EC%9E%90%EB%B0%94%EC%8A%A4%ED%81%AC%EB%A6%BD%ED%8A%B8-%EC%9D%B4%EB%AF%B8%EC%A7%80-%EB%A6%AC%EC%82%AC%EC%9D%B4%EC%A7%95
 */
DrawUtil.fctGetThumbnail = function fctGetThumbnail(
  imageUrl,
  newWidth,
  newHeight
) {
  let image = new Image();
  let canvas = document.createElement("canvas");

  function resize() {
    // 이미지 가로너비 기준을 맞추어 비율 고정하여 축소 후 가운데 정렬 (썸네일에서 상하는 잘림)
    let width = image.width;
    let height = image.height;

    height *= newWidth / width;
    width = newWidth;

    // if (width > height) {
    //     if (width > maxSize) {
    //         height *= newWidth / width;
    //         width = newWidth;
    //     }
    // } else {
    //     if (height > maxSize) {
    //         width *= maxSize / height;
    //         height = maxSize;
    //     }
    // }

    // console.log({
    //     ow:image.width,
    //     oh:image.height,
    //     nw:width,
    //     nh:height,
    //     cw:newWidth,
    //     ch:newHeight,
    // });

    canvas.width = newWidth;
    canvas.height = newHeight;
    canvas
      .getContext("2d")
      .drawImage(
        image,
        (newWidth - width) / 2,
        (newHeight - height) / 2,
        width,
        height
      );

    // let a = DrawUtil.fctBlobFromURI(imageUrl);
    // let b = DrawUtil.fctBlobFromURI(canvas.toDataURL("image/jpeg"));

    // console.log({
    //     oriImgLength: imageUrl.length,
    //     oriBlbLength: a,
    //     thuImgLength: canvas.toDataURL("image/jpeg").length,
    //     thuBlbLength: b,
    // })

    // console.log(canvas.toDataURL("image/jpeg"));
    // console.log(window.URL.createObjectURL(b));

    return {
      oriWidth: image.width,
      oriHeight: image.height,
      thumbnail: canvas.toDataURL("image/jpeg"),
    };
  }

  return new Promise((ok, no) => {
    image.onload = () => {
      return ok(resize());
    };
    image.src = imageUrl;
  });
};

DrawUtil.fctBlobFromURI = function fctBlobFromURI(dataURI) {
  const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0
      ? atob(dataURI.split(",")[1])
      : unescape(dataURI.split(",")[1]);
  const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};

// DrawUtil.fctGetThumbnail = function fctGetThumbnail(image, maxWidth) {
//     let img = new Image();
//     img.src = image;

//     //canvas에 이미지 객체를 리사이징해서 담는 과정
//     var canvas = document.createElement("canvas");
//     // canvas.width = '100px'; //리사이징하여 그릴 가로 길이
//     // canvas.height ='100px'; //리사이징하여 그릴 세로 길이
//     canvas.width = maxWidth;
//     canvas.height = maxWidth;
//     canvas.getContext("2d").drawImage(img, 0, 0, maxWidth, maxWidth);

//     var dataURL = canvas.toDataURL("image/png"); //png => jpg 등으로 변환 가능

//     // console.log({oriL: image.src.length, newL: dataURL.length});
//     console.log(dataURL);
//     console.log(typeof(img))
// }

// 추론데이터에 대해 NMS를 수행 후 원래 남아있는 리스트와 비교하여 2차 처리
DrawUtil.filterAnnotationByNMS = function filterAnnotationByNMS(
  annotations,
  infAnnotations,
  threshold
) {
  let nmsList = performNMS(infAnnotations, threshold);
  let finalList = removeOverlaps(annotations, nmsList, threshold);
  return finalList;
};

function performNMS(annotations, threshold) {
  // addList를 스코어가 높은 순서로 정렬
  annotations.sort((a, b) => b.prob - a.prob);

  for (let i = 0; i < annotations.length; i++) {
    for (let j = i + 1; j < annotations.length; ) {
      if (
        calculateIoU(annotations[i].boundingBox, annotations[j].boundingBox) >
        threshold
      ) {
        annotations.splice(j, 1);
      } else {
        j++;
      }
    }
  }
  return annotations;
}

// 기존 어노테이션과 추론 결과를 비교하여 겹치는 추론을 제거하는 함수
function removeOverlaps(annotations, addList, threshold) {
  for (let i = 0; i < annotations.length; i++) {
    for (let j = 0; j < addList.length; ) {
      if (
        calculateIoU(annotations[i].boundingBox, addList[j].boundingBox) >
        threshold
      ) {
        if (annotations[i].labelId !== addList[j].labelId)
          annotations[
            i
          ].infInfo = `\n예측 ${addList[j].label}(${addList[j].prob}%)`;
        addList.splice(j, 1);
      } else {
        j++;
      }
    }
  }
  return addList;
}

function calculateIoU(box1, box2) {
  const x1 = Math.max(box1.x, box2.x);
  const y1 = Math.max(box1.y, box2.y);
  const x2 = Math.min(box1.x + box1.width, box2.x + box2.width);
  const y2 = Math.min(box1.y + box1.height, box2.y + box2.height);

  const intersection = Math.max(0, x2 - x1) * Math.max(0, y2 - y1);
  const union =
    box1.width * box1.height + box2.width * box2.height - intersection;

  return intersection / union;
}
