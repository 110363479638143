import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { useParams, useNavigate } from "react-router-dom";
import Lodash from "lodash";

import Box from "@mui/material/Box";

import * as U2ReduxSlice from "../../Store/ReduxSlice";
import U2RoundedBox from "../../Components/Common/U2RoundedBox";
import U2ImageAnnotatorImageList from "./U2ImageAnnotatorImageList";
import U2ImageAnnotatorBoard from "./U2ImageAnnotatorBoard";
import U2ImageAnnotatorToolBox from "./U2ImageAnnotatorToolBox";
import U2ImageInfoEditDialog from "./U2ImageInfoEditDialog";
import U2ImagesInfoEditDialog from "./U2ImagesInfoEditDialog";
import U2ImageSetManagerLabelEditDialog from "../ImageSetManager/U2ImageSetManagerLabelEditDialog";
import SourceImageEntity from "../../API/SourceImageEntity";
import AnnotationEntity from "../../API/AnnotationEntity";

import U2MessageBox from "../../Components/Common/U2MessageBox";
import DrawUtil from "../../Utils/DrawUtil";
import ConvertUtil from "../../Utils/ConvertUtil";
import AnnotatorAPI from "../../API/AnnotatorAPI";
import AjouSpecimenAPI from "../../API/AjouSpecimenAPI";
import {
  IMAGEANNOTATIORBUTTONIDS,
  WINDOWSIZE,
  SOURCESTATUS,
  ANNOTATORIMAGELIST_POPUPMENU,
  ACLCODE,
} from "../../Enums/Enums";
import U2ImageAnnotatorCBCToolBox from "./U2ImageAnnotatorCBCToolBox";

function Annotator(props) {
  const TRANSFORMBOXSIZE = 8;
  const TRANSFORM_MINIMUM_LENGTH = 20;

  // 다중 추론 저장을 위해 uniq key 통합
  var keyincrement = -1;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _myInfo = useSelector((state) => state.defaultReducer.myInfo);

  /**
   * 프로젝트 ID
   */
  const { projectId, sourceImageSetId, p_imageId } = useParams();

  /**
   * 원시이미지셋 ID
   */
  // const {  } = useParams();

  /**
   * 메시지 박스 정보
   */
  const [_openMessageBox, setOpenMessageBox] = useState({
    open: false,
    title: "",
    description: "",
    okButtonCaption: "",
    cancelButtonCaption: "",
    onClose: null,
  });

  /**
   * 라벨 에디터 팝업 실행 여부
   * Boolean
   */
  const [_IslabelEditOpen, setIsLabelEditOpen] = useState(false);

  // String 선택된 도구 버튼 ID
  const [_selectedToolButtonId, setSelectedToolButtonId] = useState();
  // String 선택된 라벨 버튼 ID
  const [_selectedLabelButtonId, setSelectedLabelButtonId] = useState();

  const [_konvaImageLayerScale, setKonvaImageLayerScale] = useState({
    scale: 1,
    scaleBy: 0,
  });
  const [_moveToCenterTrigger, setMoveToCenterTrigger] = useState(1);

  /**
   * 선택된 이미지 정보
   * {
   *      imageId
   *      imageUrl
   *      width
   *      height
   *      status
   * }
   */
  const [_selectedImage, setSelectedImage] = useState();

  const [_labelStrokeEnabled, setLabelStrokeEnabled] = useState(true);
  const [_labelFillEnabled, setLabelFillEnabled] = useState(true);
  const [_labelManualEnabled, setLabelManualEnabled] = useState(true);
  const [_labelIntelliEnabled, setLabelIntelliEnabled] = useState(true);

  /**
   * 어노테이션 목록
   * Array [{
   *      annotationId: Int 어노테이션 ID
   *      label: String 라벨 값
   *      boundingBox: RectInfo 바운딩박스 사각형 정보
   *          RectInfo: { x, y, width, height }
   *      color: String 라벨 색상
   *      isSelected: Boolean 선택여부
   * }]
   */
  const [_annotations, setAnnotations] = useState([]);

  /**
   * 이전 어노테이션 목록
   * _annotations와 동일 구조
   * 실행 취소 시 _annotations에 대체
   * null : 되돌리지 않음(이미지 전환 시)
   * [] : 빈 리스트로 되돌림(이미지 첫 작업 시)
   */
  const [_beforeAnnotations, setBeforeAnnotations] = useState(null);
  /**
   * 이전 이미지 상태, 이전 이미지셋 상태
   * null : 되돌리지 않음(변경된 적 없을 시)
   * 그외 : SOURCESTATUS Enum, 상태가 다르면 되돌림
   */
  const [_beforeImageStatus, setBeforeImageStatus] = useState(null);
  const [_beforeImageSetStatus, setBeforeImageSetStatus] = useState(null);
  /**
   * 실행 취소 활성화 여부
   */
  const [_IsEditUndoDisabled, setIsEditUndoDisabled] = useState(true);

  /**
   * 선택된 어노테이션
   * Obj
   */
  const [_selectedAnnotation, setSelectedAnnotation] = useState(null);

  /**
   * 선택된 바운딩박스 사각형 정보
   * { x, y, width, height }
   */
  const [_selectedBoundingBox, setSelectedBoundingBox] = useState(null);
  /**
   * 바운딩 박스 드래그 상태 값
   * true : 드래그 중
   */
  const [_boundingBoxDraging, setBoundingBoxDraging] = useState(false);
  /**
   * 바운딩 박스 변형 커서
   * Array [{
   *      boxes: Array 8개의 변형 커서 사각형 정보
   *          Array : [{ x, y, width, height }]
   *      lines: Array 선택된 바운딩 박스 각 모서리의 좌표
   *          Array : [{ x1, y1, x2, y2, x3, y3, x4, y4 }]
   * }]
   */
  const [_transformBoxes, setTransformBoxes] = useState({
    boxes: [],
    lines: [],
  });

  /**
   * 라벨 목록 (@LibraryLabel)
   */
  const [_labels, setLabels] = useState([]);

  /**
   * 원시이미지셋 정보 (@InfoSourceImageSet)
   */
  const [_sourceImageSet, setSourceImageSet] = useState({
    sourceImageSetStatus: "",
  });

  /**
   * 이미지목록
   * Array [{
   *      img: String 이미지 URL
   *      title: String 이미지 제목
   *      status: SOURCESTATUS 이미지 라벨링 상태
   * }]
   */
  const [_sourceImages, setSourceImages] = useState([]);

  /**
   * 인텔리 모델 목록
   */
  const [_intelliModes, setIntelliModels] = useState([]);

  /**
   * 이미지 일괄 추가 다이얼로그 실행 여부
   */
  const [_imagesInfoEditDialogIsOpen, setImagesInfoEditDialogIsOpen] =
    useState(false);

  /**
   * 이미지 일괄 추가 파일 목록
   */
  const [_imagesInfoEditDialogFiles, setImagesInfoEditDialogFiles] = useState(
    []
  );

  /**
   * 이미지 추가 / 수정 다이얼로그 실행 여부
   */
  const [_imageInfoEditDialogIsOpen, setImageInfoEditDialogIsOpen] =
    useState(false);

  /**
   * 이미지 추가 / 수정 다이얼로그 실행 모드
   */
  const [_imageInfoEditDialogIsEditMode, setImageInfoEditDialogIsEditMode] =
    useState(false);

  /**
   * 이미지 추가 / 수정 다이얼로그에 바인딩할 이미지
   */
  const [_imageInfoEditDialogData, setImageInfoEditDialogData] = useState({
    image: "",
  });

  /**
   * 아주대 검체인 경우 CBC 정보 저장
   */
  const [_ajouCBC, setAjouCBC] = useState("");

  /****************************************************************************************************
   *
   * Data Load
   *
   ****************************************************************************************************/
  useEffect(() => {
    async function fctLoad() {
      dispatch(U2ReduxSlice.isBackdropOpen_set(true));

      // 프로젝트 정보 로드
      // let project = await AnnotatorAPI.GetProject(projectId);

      // 원시 이미지 셋 로드
      let sourceImageSet = await AnnotatorAPI.GetSourceImageSet(
        projectId,
        sourceImageSetId
      );

      if (projectId === "10000" && sourceImageSetId === "10000") {
        // console.log(sourceImageSetId)

        setSourceImageSet({ sourceImageSetStatus: SOURCESTATUS.RELEASE });

        // 원시 이미지 목록 로드
        let sourceImages = await AnnotatorAPI.GetSourceImageBySourceImageSetId(
          sourceImageSetId
        );
        setSourceImages(sourceImages.data);

        // 라벨 로드
        let labels = await AnnotatorAPI.GetLabelByProjectId(projectId);
        setLabels(labels.data.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1)));

        // 인텔리 모델 목록 로드
        // let models = await AnnotatorAPI.GetIntelliModels();
        // setIntelliModels(models.data.sort((a, b) => a.weight_name > b.weight_name ? 1 : -1));

        dispatch(
          U2ReduxSlice.breadcrumbs_set([
            { label: "PBS Morphology", link: "/" },
            { label: "검증용 이미지 셋", link: "" },
          ])
        );
      } else if (sourceImageSet.data.length > 0) {
        setSourceImageSet(sourceImageSet.data[0]);

        // 원시 이미지 목록 로드
        let sourceImages;

        if (!!p_imageId) {
          sourceImages = await AnnotatorAPI.GetSourceImage(
            sourceImageSetId,
            p_imageId
          );
        } else {
          sourceImages = await AnnotatorAPI.GetSourceImageBySourceImageSetId(
            sourceImageSetId
          );
        }

        setSourceImages(sourceImages.data);

        //아주대 검체 로드(해당시)
        // let ajouCBC = await AjouSpecimenAPI.GetCBC("1041W11");
        if (sourceImageSet.data[0].sourceImageSetTagId === 3) {
          let ajouCBC = await AjouSpecimenAPI.GetCBC(
            sourceImageSet.data[0].sourceImageSetName.split("-")[0]
          );
          if (ajouCBC.status === 200) setAjouCBC(ajouCBC.data);
        }

        // 라벨 로드
        let labels = await AnnotatorAPI.GetLabelByProjectId(projectId);
        setLabels(labels.data.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1)));

        // 인텔리 모델 목록 로드
        // let models = await AnnotatorAPI.GetIntelliModels();
        // setIntelliModels(models.data.sort((a, b) => a.weight_name > b.weight_name ? 1 : -1));

        dispatch(
          U2ReduxSlice.breadcrumbs_set([
            { label: "PBS Morphology", link: "/" },
            { label: sourceImageSet.data[0].sourceImageSetName, link: "" },
          ])
        );
      } else {
        alert("잘못된 접근 입니다");
        navigate("/");
      }

      dispatch(U2ReduxSlice.isBackdropOpen_set(false));
    }
    fctLoad();
  }, [projectId, sourceImageSetId, dispatch, navigate, p_imageId]);

  /**
   * 단축키 등록
   */
  useHotkeys(
    "delete",
    () => {
      fctImageAnotationDelete(_selectedAnnotation.annotationId);
    },
    [_selectedAnnotation]
  );
  useHotkeys(
    "m",
    () => {
      handlerImageAnnotatorToolBox_SelectedToolButtonChanged(
        IMAGEANNOTATIORBUTTONIDS.TOOL_MOVE
      );
    },
    []
  );
  useHotkeys(
    "b",
    () => {
      handlerImageAnnotatorToolBox_SelectedToolButtonChanged(
        IMAGEANNOTATIORBUTTONIDS.TOOL_BOUNDINGBOX
      );
    },
    [_sourceImageSet.sourceImageSetStatus]
  );

  /**
   * 툴박스 이벤트 - 도구 버튼 클릭
   */
  const handlerImageAnnotatorToolBox_SelectedToolButtonChanged = (
    selectedToolButtonId,
    modelName
  ) => {
    if (
      selectedToolButtonId !== IMAGEANNOTATIORBUTTONIDS.TOOL_BOUNDINGBOX ||
      (selectedToolButtonId === IMAGEANNOTATIORBUTTONIDS.TOOL_BOUNDINGBOX &&
        fctIsEditable(true)) ||
      (selectedToolButtonId === IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLIBOX &&
        fctIsEditable(true)) ||
      (selectedToolButtonId === IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLILABEL &&
        fctIsEditable(true)) ||
      selectedToolButtonId === IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLIMODEL
    ) {
      setSelectedToolButtonId(selectedToolButtonId);

      if (selectedToolButtonId === IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLIBOX) {
        fctIntelliBox();
      } else if (
        selectedToolButtonId === IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLILABEL
      ) {
        if (!!_selectedImage && modelName !== "모델 선택") {
          fctIntelliAnnotation(modelName);
        }
      } else if (
        selectedToolButtonId === IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLIMODEL
      ) {
        AnnotatorAPI.GetIntelliModels().then((models) => {
          setIntelliModels(
            models.data.sort((a, b) => (a.weight_name > b.weight_name ? 1 : -1))
          );
        });
      }
    } else {
      setSelectedToolButtonId("");
    }
  };

  /** DB 갱신 작업 Wrapper */
  const fctUpdateAnnotation = useCallback(
    async (list) => {
      //드래그 중 업데이트 방지
      if (!_boundingBoxDraging) {
        setBeforeAnnotations(_annotations);
        setIsEditUndoDisabled(false);
      }
      // console.log(list)
      const filteredList = list.filter(
        (item) => item.boundingBox !== undefined && item.boundingBox !== null
      );
      let annotations = await Promise.all(
        Lodash.map(filteredList, async (item) => {
          let entity = new AnnotationEntity({
            ...item,
            annotationId: item.annotationId < 0 ? -1 : item.annotationId,
          });
          let res = await AnnotatorAPI.UpdateAnnotation(
            sourceImageSetId,
            item.imageId,
            item.annotationId < 0 ? -1 : item.annotationId,
            entity.value
          );
          if (!!res.boundingBox) return null;

          res = {
            ...res.data,
            boundingBox: JSON.parse(res.data.boundingBox),
            label: !!item.infInfo
              ? res.data.label + item.infInfo
              : res.data.label,
          };
          return res;
        }).filter(Boolean)
      );

      return annotations;
    },
    [_annotations, sourceImageSetId, _boundingBoxDraging]
  );

  const fctIntelliBox = useCallback(() => {
    let imageId = _selectedImage.imageId;

    dispatch(U2ReduxSlice.isBackdropOpen_set(true));

    AnnotatorAPI.GetSourceImage(sourceImageSetId, imageId).then((res1) => {
      AnnotatorAPI.GetIntelliBoundingBox(
        _myInfo.memberId,
        res1.data[0].image
      ).then((res2) => {
        if (res2.data.result_code === "OK") {
          let entities = Lodash.map(res2.data.result, (inf) => {
            // let label = Lodash.find(_labels, (label) => label.label === inf.class);

            return {
              annotationId: keyincrement--,
              imageId: imageId,
              labelId: -1,
              label: "noname",
              isIntelli: true,
              isManual: false,
              prob: Math.trunc(inf.prob * 100),
              color: "#FFFF00",
              boundingBox: DrawUtil.fctCalcRectangleCoordinate(
                Number(inf.x1),
                Number(inf.y1),
                inf.x2,
                inf.y2
              ),
            };
          });

          setBeforeAnnotations(_annotations);
          setIsEditUndoDisabled(false);
          setAnnotations((state) => {
            return [...state, ...entities.filter((item) => !!item)];
          });
        } else {
          dispatch(
            U2ReduxSlice.snackbar_open(
              "바운딩박스를 찾는 중 오류가 발생하였습니다"
            )
          );
        }

        dispatch(U2ReduxSlice.isBackdropOpen_set(false));
      });
    });
  }, [
    sourceImageSetId,
    _selectedImage,
    _myInfo,
    dispatch,
    keyincrement,
    _annotations,
  ]);

  /**
   * 직전 상태와 비교하여 어노테이션과 이미지,이미지셋 상태 복구
   */
  const fctUndoAnnotations = useCallback(async () => {
    dispatch(U2ReduxSlice.isBackdropOpen_set(true));

    if (_beforeAnnotations == null) {
      // 실행 취소를 했거나 실행취소를 할 상태가 아닌 경우 종료
      dispatch(U2ReduxSlice.isBackdropOpen_set(false));
      return;
    }

    const map = new Map();

    _beforeAnnotations.forEach((item) => {
      // 어노테이션 비교를 위해 어노테이션ID값을 키값으로 사용
      const key = item.annotationId;
      map.set(key, item);
    });

    _annotations.forEach(async (item) => {
      const key = item.annotationId;
      if (map.has(key)) {
        const originalItem = map.get(key);
        // 매핑 정보에서 제거하여 중복 검사 방지
        map.delete(key);
        // 데이터 동일성 비교
        if (JSON.stringify(originalItem) === JSON.stringify(item)) {
        } else {
          // console.log(`데이터가 다름: ${JSON.stringify(item)}`);
          // console.log(key,item, originalItem)
          await AnnotatorAPI.UpdateAnnotation(
            sourceImageSetId,
            item.imageId,
            item.annotationId,
            originalItem
          );
        }
      } else {
        // console.log(`이전에 없는 데이터(추가된 데이터, 삭제): ${JSON.stringify(item)}`);
        await AnnotatorAPI.DeleteAnnotation(
          sourceImageSetId,
          item.imageId,
          item.annotationId
        );
      }
    });

    // 남은 매핑 정보 출력 (이전에만 남아있는 데이터, 삭제된 데이터)
    map.forEach(async (item) => {
      // console.log(`현재에 없는 데이터(삭제된 데이터, 재 추가): ${JSON.stringify(item)}`);
      let entity = new AnnotationEntity({
        ...item,
        annotationId: -1,
      });
      await AnnotatorAPI.UpdateAnnotation(
        sourceImageSetId,
        item.imageId,
        -1,
        entity.value
      );
    });

    // 선택한 어노테이션이 있을 시 선택 해제
    if (!!_selectedAnnotation) {
      let annotation = _beforeAnnotations.find(
        (item) => item.annotationId === _selectedAnnotation.annotationId
      );
      if (annotation === undefined) {
        fctBoundingBoxUnselect();
      } else {
        let boxes = DrawUtil.fctGetTransformBoxCoordinates(
          annotation.boundingBox,
          TRANSFORMBOXSIZE
        );
        setTransformBoxes(boxes);
      }
    }

    // console.log({
    //     _sourceImageSet:_sourceImageSet,
    //     _beforeImageSetStatus:_beforeImageSetStatus,
    //     notEqauls: _sourceImageSet.sourceImageSetStatus !== _beforeImageSetStatus
    // })

    //이미지나 이미지셋 상태가 직전에 변경됬을 경우 되돌리기
    if (
      _beforeImageStatus !== null &&
      _selectedImage.status !== _beforeImageStatus
    ) {
      // 이미지 상태 변경
      let entity = new SourceImageEntity(
        { imageId: _selectedImage.imageId, status: _beforeImageStatus },
        _sourceImages
      );
      fctSourceImageUpdate(entity, setSourceImages);
      // 알림 메시지
      dispatch(
        U2ReduxSlice.snackbar_open("원시이미지 상태가 실행 취소 되었습니다.")
      );
    }

    if (
      _beforeImageSetStatus !== null &&
      _sourceImageSet.sourceImageSetStatus !== _beforeImageSetStatus
    ) {
      // 이미지셋 상태 변경
      let entity = {
        ..._sourceImageSet,
        sourceImageSetStatus: _beforeImageSetStatus,
      };
      AnnotatorAPI.UpdateSourceImageSet(
        projectId,
        sourceImageSetId,
        entity
      ).then((res) => {
        if (res.data.length > 0) {
          entity = res.data[0];
          setSourceImageSet(entity);
          // 알림 메시지
          dispatch(
            U2ReduxSlice.snackbar_open(
              "원시이미지셋 상태가 실행 취소 되었습니다."
            )
          );
        }
      });
    }

    setIsEditUndoDisabled(true);
    setAnnotations(_beforeAnnotations);
    dispatch(U2ReduxSlice.isBackdropOpen_set(false));
  }, [
    sourceImageSetId,
    _annotations,
    _beforeAnnotations,
    _selectedAnnotation,
    dispatch,
    _beforeImageStatus,
    _beforeImageSetStatus,
    _selectedImage,
    _sourceImageSet,
    _sourceImages,
    projectId,
    fctSourceImageUpdate,
  ]);

  /** 인텔리 라벨링 기능을 수행하는 함수 */
  const fctIntelliAnnotation = useCallback(
    (modelName) => {
      let imageId = _selectedImage.imageId;

      dispatch(U2ReduxSlice.isBackdropOpen_set(true));

      // AI 서버에서 인퍼런스 수행
      AnnotatorAPI.GetSourceImage(sourceImageSetId, imageId).then((res1) => {
        AnnotatorAPI.GetIntelliAnnotation(
          _myInfo.memberId,
          modelName,
          imageId,
          res1.data[0].width,
          res1.data[0].height,
          res1.data[0].image
        ).then((res2) => {
          console.log(res2);
          // res2.data { result_code, result, error_trace }
          // 정상
          //      result_code = OK
          //      result = [] : 어노테이션 배열
          // 오류
          //      result_code = ERR
          //      error_trace = '' : 오류내용
          if (res2.data.result_code === "OK") {
            let entities = Lodash.map(res2.data.result, (inf) => {
              let label = Lodash.find(
                _labels,
                (label) => label.label === inf.class
              );

              // 삭제된 라벨에 대해 구 모델로 추론 시 대체
              if (inf.class === "LargePlt") {
                label = label = Lodash.find(
                  _labels,
                  (label) => label.label === "PLT"
                );
              } else if (
                inf.class === "Metamyelocyte" ||
                inf.class === "Promyelocyte"
              ) {
                label = label = Lodash.find(
                  _labels,
                  (label) => label.label === "Myelocyte"
                );
              }

              if (!!label) {
                return {
                  annotationId: keyincrement--,
                  imageId: imageId,
                  labelId: label.labelId,
                  isIntelli: true,
                  isManual: false,
                  label: label.label,
                  prob: Math.trunc(inf.prob * 100),
                  color: label.color,
                  boundingBox: DrawUtil.fctCalcRectangleCoordinate(
                    Number(inf.x1),
                    Number(inf.y1),
                    inf.x2,
                    inf.y2
                  ),
                };
              } else {
                return {
                  annotationId: keyincrement--,
                  imageId: imageId,
                  labelId: -1,
                  label: inf.class,
                  isIntelli: true,
                  isManual: false,
                  prob: Math.trunc(inf.prob * 100),
                  color: "#FFFF00",
                  boundingBox: DrawUtil.fctCalcRectangleCoordinate(
                    Number(inf.x1),
                    Number(inf.y1),
                    inf.x2,
                    inf.y2
                  ),
                };
              }
            });
            // NMS 수행
            entities = DrawUtil.filterAnnotationByNMS(
              _annotations,
              entities,
              0.6
            );
            // 추론 결과 자동 저장
            fctUpdateAnnotation([
              ..._annotations,
              ...entities.filter((item) => !!item),
            ]).then((res) => {
              setAnnotations(res);
              dispatch(U2ReduxSlice.isBackdropOpen_set(false));
            });

            // 인텔리 라벨링 수행 후 상태 업데이트
            fctImageStatusUpdate();
          } else {
            dispatch(
              U2ReduxSlice.snackbar_open("인퍼런스 중 오류가 발생하였습니다")
            );
            dispatch(U2ReduxSlice.isBackdropOpen_set(false));
          }
        });
      });
    },
    [
      sourceImageSetId,
      _selectedImage,
      _myInfo,
      _labels,
      dispatch,
      keyincrement,
      _annotations,
      fctUpdateAnnotation,
      fctImageStatusUpdate,
    ]
  );

  /**
   * 툴박스 이벤트 - 편집 버튼 클릭
   */
  function handlerImageAnnotatorToolBox_EditButtonClick(buttonId) {
    if (buttonId === IMAGEANNOTATIORBUTTONIDS.EDIT_DELETE) {
      fctImageAnotationDelete(_selectedAnnotation.annotationId);
      // } else if (buttonId === IMAGEANNOTATIORBUTTONIDS.EDIT_SAVE) {
      //     fctSaveAnnotations();
    } else if (buttonId === IMAGEANNOTATIORBUTTONIDS.EDIT_UNDO) {
      fctUndoAnnotations();
    }
  }

  /**
   * 툴박스 이벤트 - 라벨 클릭
   */
  function handlerImageAnnotatorToolBox_SelectedLabelButtonChanged(
    selectedLabelButtonId
  ) {
    setSelectedLabelButtonId(selectedLabelButtonId);

    // 선택된 어노테이션의 라벨을 변경
    if (fctIsEditable(true)) {
      if (!!_selectedAnnotation) {
        let find = _labels.find(
          (item) => item.labelId === selectedLabelButtonId
        );
        let color = find.color;
        let label = find.label;

        setSelectedAnnotation({
          annotationId: _selectedAnnotation.annotationId,
          labelId: selectedLabelButtonId,
          labelId2: _selectedAnnotation.labelId2,
          label: label,
          prob: null,
          isManual: true,
          color: color,
        });

        let entity = new AnnotationEntity(
          {
            annotationId: _selectedAnnotation.annotationId,
            labelId: selectedLabelButtonId,
            labelId2: _selectedAnnotation.labelId2,
            label: label,
            prob: null,
            isManual: true,
            color: color,
          },
          _annotations
        );
        fctImageAnotationModify(_selectedAnnotation.annotationId, entity);
        fctImageAnnotationUpdate(entity);
      }
    }
  }

  /**
   * 툴박스 이벤트 - 라벨 클릭 (더블 라벨링 라벨2)
   */
  function handlerImageAnnotatorToolBox_SelectedLabelButtonChanged2(
    selectedLabelButtonId
  ) {
    // 라벨2 삭제버튼 동작
    if (selectedLabelButtonId === null) {
      setSelectedAnnotation({
        annotationId: _selectedAnnotation.annotationId,
        labelId: _selectedAnnotation.labelId,
        labelId2: null,
        label: _selectedAnnotation.label,
        prob: null,
        isManual: true,
        color: _selectedAnnotation.color,
      });

      let entity = new AnnotationEntity(
        {
          annotationId: _selectedAnnotation.annotationId,
          labelId: _selectedAnnotation.labelId,
          labelId2: null,
          label: _selectedAnnotation.label,
          prob: null,
          isManual: true,
          color: _selectedAnnotation.color,
        },
        _annotations
      );
      // console.log("라벨변경:", entity);

      fctImageAnotationModify(_selectedAnnotation.annotationId, entity);
      fctImageAnnotationUpdate(entity);
    }
    // 선택된 어노테이션의 라벨을 변경
    else if (fctIsEditable(true)) {
      if (!!_selectedAnnotation) {
        let find = _labels.find(
          (item) => item.labelId === selectedLabelButtonId
        );
        let label2 = find.label;

        setSelectedAnnotation({
          annotationId: _selectedAnnotation.annotationId,
          labelId: _selectedAnnotation.labelId,
          labelId2: selectedLabelButtonId,
          label: _selectedAnnotation.label,
          label2: label2,
          prob: null,
          isManual: true,
          color: _selectedAnnotation.color,
        });

        let entity = new AnnotationEntity(
          {
            annotationId: _selectedAnnotation.annotationId,
            labelId: _selectedAnnotation.labelId,
            labelId2: selectedLabelButtonId,
            label: _selectedAnnotation.label,
            label2: label2,
            prob: null,
            isManual: true,
            color: _selectedAnnotation.color,
          },
          _annotations
        );
        // console.log("라벨변경:", entity);

        fctImageAnotationModify(_selectedAnnotation.annotationId, entity);
        fctImageAnnotationUpdate(entity);
      }
    }
  }

  /**
   * 툴박스 이벤트 - 줌 버튼 클릭
   */
  function handlerZoomButtonClick(e) {
    if (!!_selectedImage) {
      fctZoom(e > 1);
    }
  }

  /**
   * 확대 / 축소
   */
  function fctZoom(isZoomIn) {
    let oldScale = _konvaImageLayerScale.scale;
    let scaleBy = isZoomIn ? 0.2 : -0.2;
    let newScale =
      Math.round(Math.max(0.2, Math.min(4.1, oldScale + scaleBy)) * 10) / 10;
    if (newScale >= 0.3 && newScale <= 4) {
      setKonvaImageLayerScale({ scale: newScale, scaleBy: scaleBy });
    }
  }

  /**
   * 툴박스 이벤트 - 센터 버튼 클릭
   */
  function handlerMoveToCenterButtonClick() {
    setMoveToCenterTrigger((state) => state + 1);
  }

  /**
   * 이미지 리스트에서 이미지 선택
   */
  function handlerThumbnailClick(imageUrl, imageId) {
    // console.log({imageUrl:imageUrl, imageId:imageId})
    if (
      !!imageId &&
      (!_selectedImage ||
        (!!_selectedImage && _selectedImage.imageId !== imageId))
    ) {
      dispatch(U2ReduxSlice.isBackdropOpen_set(true));

      fctBoundingBoxUnselect();

      AnnotatorAPI.GetSourceImage(sourceImageSetId, imageId).then((res) => {
        if (res.data.length > 0) {
          setSelectedImage({
            imageId: res.data[0].imageId,
            imageUrl: res.data[0].image,
            width: res.data[0].width,
            height: res.data[0].height,
            status: res.data[0].status,
          });

          AnnotatorAPI.GetAnnotation(sourceImageSetId, imageId).then((res) => {
            // boundingBox 를 JSON Object 로 변환하기 위해 map 을 사용함
            let data = Lodash.map(res.data, (item) => ({
              ...item,
              boundingBox: JSON.parse(item.boundingBox),
            }));
            // console.log(data);
            setBeforeAnnotations(data);
            setIsEditUndoDisabled(true);
            setAnnotations(data);
            dispatch(U2ReduxSlice.isBackdropOpen_set(false));
          });

          // 이미지를 화면 중앙으로 이동
          handlerMoveToCenterButtonClick();
        }
      });
    }
  }

  function handlerImageAnnotatorBoard_BoundingBoxAdded(
    selectedImageId,
    selectedLabelButtonId,
    boundingBoxRectInfo
  ) {
    fctImageAnotationAdd(
      selectedImageId,
      selectedLabelButtonId,
      boundingBoxRectInfo
    );
  }

  /**
   * 이미지 영역에서 어노테이션 선택
   */
  function handlerImageAnnotatorBoard_BoundingBoxClick(boundingBoxKey) {
    if (!!boundingBoxKey) {
      fctBoundingBoxSelect(boundingBoxKey);
    } else {
      fctBoundingBoxUnselect();
    }
  }

  /**
   * 어노테이션 리스트에서 어노테이션 선택
   */
  function handlerImageAnnotatorToolBox_SelectedAnnotationButtonChanged(
    boundingBoxKey
  ) {
    fctBoundingBoxSelect(boundingBoxKey);
  }

  /**
   * 변형 커서를 드래그 할 때 선택한 바운딩 박스의 크기를 변경
   */
  function handlerImageAnnotorBoard_TransformBoxDragMove(
    transformBoundingBoxRect,
    imageLocation
  ) {
    // console.log({ x: transformBoundingBoxRect.target.x(), y: transformBoundingBoxRect.target.y() })

    //드래그 전 이전 상태 저장
    if (!_boundingBoxDraging) {
      setBeforeAnnotations(_annotations);
      setIsEditUndoDisabled(false);
    }
    setBoundingBoxDraging(true);

    let halfSize = TRANSFORMBOXSIZE / 2;

    let transformBoxId = transformBoundingBoxRect.target.id();

    // 선택한 변형 커서의 이동 거리를 이용하여 선택 영역 사각형 좌표 계산
    let targetRectInfo = DrawUtil.fctGetTransformTargetCoordinate(
      transformBoxId,
      transformBoundingBoxRect.target.x() - imageLocation.x,
      transformBoundingBoxRect.target.y() - imageLocation.y,
      { width: _selectedImage.width, height: _selectedImage.height },
      _selectedBoundingBox.x,
      _selectedBoundingBox.y,
      _selectedBoundingBox.width,
      _selectedBoundingBox.height,
      TRANSFORMBOXSIZE,
      TRANSFORM_MINIMUM_LENGTH
    );

    // 변형 커서 위치 계산 (변형 크기 반영)
    let transformBoxes = DrawUtil.fctGetTransformBoxCoordinates(
      targetRectInfo,
      TRANSFORMBOXSIZE
    );

    // console.log({
    //     ty: targetRectInfo.y,
    //     th: targetRectInfo.height,
    //     imgH: _selectedImage.height,
    //     tny: targetRectInfo.y + targetRectInfo.height + imageLocation.y - halfSize
    // })

    // 변형 커서 위치 강제 조정 (최소 크기 유지 & 이미지 크기 내 유지 - 가로)
    if (
      targetRectInfo.width <= TRANSFORM_MINIMUM_LENGTH ||
      targetRectInfo.x <= 0 ||
      targetRectInfo.x2 >= _selectedImage.width
    ) {
      if (
        transformBoxId === "1" ||
        transformBoxId === "8" ||
        transformBoxId === "7"
      ) {
        transformBoundingBoxRect.target.x(
          targetRectInfo.x + imageLocation.x - halfSize
        );
      } else if (
        transformBoxId === "3" ||
        transformBoxId === "4" ||
        transformBoxId === "5"
      ) {
        transformBoundingBoxRect.target.x(
          targetRectInfo.x + targetRectInfo.width + imageLocation.x - halfSize
        );
      }
    }

    // 변형 커서 위치 강제 조정 (최소 크기 유지 & 이미지 크기 내 유지 - 세로)
    if (
      targetRectInfo.height <= TRANSFORM_MINIMUM_LENGTH ||
      targetRectInfo.y <= 0 ||
      targetRectInfo.y2 >= _selectedImage.height
    ) {
      if (
        transformBoxId === "1" ||
        transformBoxId === "2" ||
        transformBoxId === "3"
      ) {
        transformBoundingBoxRect.target.y(
          targetRectInfo.y + imageLocation.y - halfSize
        );
      } else if (
        transformBoxId === "5" ||
        transformBoxId === "6" ||
        transformBoxId === "7"
      ) {
        transformBoundingBoxRect.target.y(
          targetRectInfo.y + targetRectInfo.height + imageLocation.y - halfSize
        );
      }
    }

    // 변형 커서 위치 강제 조정 (중앙 변형 커서 드래그 시 중앙 위치 고정 - 가로)
    // if (transformBoxId === '2' || transformBoxId === '6') {
    //     transformBoundingBoxRect.target.x(transformBoxes.boxes[1].box.x + imageLocation.x);
    // }

    // 변형 커서 위치 강제 조정 (중앙 변형 커서 드래그 시 중앙 위치 고정 - 세로)
    // if (transformBoxId === '4' || transformBoxId === '8') {
    //     transformBoundingBoxRect.target.y(transformBoxes.boxes[3].box.y + imageLocation.y);
    // }

    // 변형 커서 위치 강제 조정 (중앙 변형 커서 드래그 시 중앙 위치 고정 - 가로)
    if (transformBoxId === "2" || transformBoxId === "6") {
      let x = transformBoundingBoxRect.target.x() - imageLocation.x;
      if (x < transformBoxes.boxes[0].box.x) {
        transformBoundingBoxRect.target.x(
          transformBoxes.boxes[0].box.x + imageLocation.x
        );
      } else if (x > transformBoxes.boxes[2].box.x) {
        transformBoundingBoxRect.target.x(
          transformBoxes.boxes[2].box.x + imageLocation.x
        );
      }
    }

    // 변형 커서 위치 강제 조정 (중앙 변형 커서 드래그 시 중앙 위치 고정 - 세로)
    if (transformBoxId === "4" || transformBoxId === "8") {
      let y = transformBoundingBoxRect.target.y() - imageLocation.y;
      if (y < transformBoxes.boxes[0].box.y) {
        transformBoundingBoxRect.target.y(
          transformBoxes.boxes[0].box.y + imageLocation.y
        );
      } else if (y > transformBoxes.boxes[6].box.y) {
        transformBoundingBoxRect.target.y(
          transformBoxes.boxes[6].box.y + imageLocation.y
        );
      }
    }

    // console.log(targetRectInfo);

    // 변형 커서 위치 업데이트
    setTransformBoxes(transformBoxes);

    // 선택한 바운딩 박스 위치 및 크기 업데이트
    setSelectedBoundingBox(transformBoxes.boundingBox);

    // 바운딩 박스 목록 업데이트
    let entity = new AnnotationEntity(
      {
        annotationId: _selectedAnnotation.annotationId,
        boundingBox: { ...transformBoxes.boundingBox },
        isManual: true,
      },
      _annotations
    );
    fctImageAnotationModify(_selectedAnnotation.annotationId, entity);
  }

  /**
   * 변형커서 드래그 종료
   */
  function handlerImageAnnotorBoard_TransformBoxDragEnd() {
    // 변형커서 드래그 종료 시 DB 에 저장 함
    let entity = new AnnotationEntity(
      { annotationId: _selectedAnnotation.annotationId, isManual: true },
      _annotations
    );
    setBoundingBoxDraging(false);
    fctImageAnnotationUpdate(entity);
  }

  /**
   * 이미지 어노테이션 추가
   */
  function fctImageAnotationAdd(
    selectedImageId,
    selectedLabelButtonId,
    boundingBoxRectInfo
  ) {
    // 중복 되지 않도록 검사
    let exists = _annotations.some(
      (item) =>
        item.boundingBox.x === boundingBoxRectInfo.x &&
        item.boundingBox.y === boundingBoxRectInfo.y &&
        item.boundingBox.width === boundingBoxRectInfo.width &&
        item.boundingBox.height === boundingBoxRectInfo.height
    );

    if (!exists) {
      // console.log(_selectedLabelButtonId);
      let entity = new AnnotationEntity({
        imageId: selectedImageId,
        labelId: selectedLabelButtonId,
        isIntelli: false,
        isManual: true,
        boundingBox: boundingBoxRectInfo,
      });

      fctUpdateAnnotation([entity.value]).then((res) => {
        setAnnotations((state) => {
          return [...state, ...res];
        });
        fctImageStatusUpdate();
      });
    }
  }
  /**
   * 이미지 어노테이션 수정 (화면에서)
   */
  function fctImageAnotationModify(boundingBoxKey, entity) {
    setAnnotations((state) => {
      return state.map((item) =>
        item.annotationId === boundingBoxKey ? entity.value : item
      );
    });
  }

  /**
   * 이미지 어노테이션 수정내역을 DB 에 저장 (TransformBox DragEnd 시)
   */
  function fctImageAnnotationUpdate(entity) {
    if (!!entity) {
      fctUpdateAnnotation([entity.value]).then((res) => {
        // console.log("Update",res,entity)
        setAnnotations((state) =>
          state.map((item) =>
            item.annotationId === _selectedAnnotation.annotationId
              ? { ...res[0] }
              : item
          )
        );
        fctImageStatusUpdate();
      });
    }
  }

  /**
   * 이미지 어노테이션 삭제
   */
  function fctImageAnotationDelete(annotationId) {
    if (!!annotationId && fctIsEditable(true)) {
      AnnotatorAPI.DeleteAnnotation(
        sourceImageSetId,
        _selectedImage.imageId,
        annotationId
      ).then((res) => {
        setBeforeAnnotations(_annotations);
        setIsEditUndoDisabled(false);
        setAnnotations((state) =>
          state.filter((item) => item.annotationId !== annotationId)
        );
        fctImageStatusUpdate();
        fctBoundingBoxUnselect();
      });
    }
  }

  /**
   * 이미지 및 이미지셋 상태 변경 (어노테이션 추가/수정/삭제 시)
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function fctImageStatusUpdate() {
    setBeforeImageStatus(_selectedImage.status);
    setBeforeImageSetStatus(_sourceImageSet.sourceImageSetStatus);
    if (_selectedImage.status !== SOURCESTATUS.ING) {
      // 이미지 상태 변경
      let entity = new SourceImageEntity(
        { imageId: _selectedImage.imageId, status: SOURCESTATUS.ING },
        _sourceImages
      );
      fctSourceImageUpdate(entity, setSourceImages);

      // 알림 메시지
      dispatch(U2ReduxSlice.snackbar_open("원시이미지 상태가 변경되었습니다."));
    }

    if (_sourceImageSet.sourceImageSetStatus !== SOURCESTATUS.ING) {
      // 이미지셋 상태 변경
      let entity = {
        ..._sourceImageSet,
        sourceImageSetStatus: SOURCESTATUS.ING,
      };
      AnnotatorAPI.UpdateSourceImageSet(
        projectId,
        sourceImageSetId,
        entity
      ).then((res) => {
        if (res.data.length > 0) {
          entity = res.data[0];
          setSourceImageSet(entity);

          // 알림 메시지
          dispatch(
            U2ReduxSlice.snackbar_open("원시이미지셋 상태가 변경되었습니다.")
          );
        }
      });
    }
  }

  /**
   * 바운딩 박스 선택
   */
  function fctBoundingBoxSelect(boundingBoxKey) {
    let selectedAnnotation = _annotations.find(
      (item) => item.annotationId === boundingBoxKey
    );
    if (!!selectedAnnotation) {
      let boxes = DrawUtil.fctGetTransformBoxCoordinates(
        selectedAnnotation.boundingBox,
        TRANSFORMBOXSIZE
      );
      if (fctIsEditable(false)) {
        setTransformBoxes(boxes);
      } else {
        setTransformBoxes({ ...boxes, boxes: [] });
      }
      setSelectedBoundingBox(selectedAnnotation.boundingBox);
      setSelectedAnnotation(selectedAnnotation);
    }
  }

  /**
   * 바운딩 박스 선택 해제
   */
  function fctBoundingBoxUnselect() {
    setTransformBoxes({ boxes: [], lines: [] });
    setSelectedBoundingBox(null);
    setSelectedAnnotation(null);
  }

  /**
   * 라벨 편집창 실행 버튼 클릭
   */
  function handlerLabelEditorButton_Click() {
    setIsLabelEditOpen(true);
  }

  /**
   * 라벨 편집창 닫기 버튼 클릭
   */
  function handleLabelEditorCloseButton_Click() {
    setIsLabelEditOpen(false);

    AnnotatorAPI.GetLabelByProjectId(projectId).then((res) => {
      setLabels(res.data.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1)));
    });
  }

  /**
   * 이미지 추가 다이얼로그 열기 (복수 이미지)
   */
  function handlerAnnotatorImageList_ImagesAdded(files) {
    if (fctIsEditable(true)) {
      setImagesInfoEditDialogFiles(files);
      setImagesInfoEditDialogIsOpen(true);
    }
  }

  /**
   * 이미지 추가 다이얼로그 종료 (복수 이미지)
   */
  const handlerImagesInfoEditDialog_Submmit = useCallback(
    async (data, images) => {
      dispatch(U2ReduxSlice.isBackdropOpen_set(true));
      dispatch(U2ReduxSlice.backdropProgressValue_set(0));

      setImagesInfoEditDialogIsOpen(false);

      // 원시 이미지 저장
      for (var i = 0; i < images.length; i++) {
        let entity = new SourceImageEntity({
          ...images[i],
          ...data,
          sourceImageSetId: sourceImageSetId,
        });

        await AnnotatorAPI.UpdateSourceImage(
          sourceImageSetId,
          -1,
          entity.value
        );

        dispatch(
          U2ReduxSlice.backdropProgressValue_set(
            ((i + 1) * 100) / images.length
          )
        );
      }

      // 원시 이미지 목록 로드
      let sourceImages = await AnnotatorAPI.GetSourceImageBySourceImageSetId(
        sourceImageSetId
      );
      setSourceImages(sourceImages.data);

      dispatch(U2ReduxSlice.isBackdropOpen_set(false));
      dispatch(U2ReduxSlice.backdropProgressValue_set(""));
    },
    [sourceImageSetId, dispatch]
  );

  /**
   * 이미지 추가 다이얼로그 열기 (단일 이미지)
   */
  function handlerAnnotatorImageList_ImageAdded(fileName, image) {
    if (fctIsEditable(true)) {
      DrawUtil.fctGetThumbnail(image, 80, 70).then((res) => {
        let imageInfo = {
          imageId: -1,
          status: SOURCESTATUS.RAW,
          isRare: false,
          fileName: fileName,
          fileSize: image.length,
          title: fileName,
          specimenId: "",
          scanDate: ConvertUtil.DateToString(new Date()),
          scanOption: "",
          stain: "",
          memo: "",
          creation: new Date().toDateString(),
          image: image,
          thumbnail: res.thumbnail,
          width: res.oriWidth,
          height: res.oriHeight,
        };

        setImageInfoEditDialogData(imageInfo);
        setImageInfoEditDialogIsEditMode(false);
        setImageInfoEditDialogIsOpen(true);
      });
    }
  }

  /**
   * 이미지 정보 편집 다이얼로그 메뉴 클릭
   */
  function handlerAnnotatorImageList_PopupMenuClick(menuId, imageId) {
    if (fctIsEditable(true)) {
      if (menuId === ANNOTATORIMAGELIST_POPUPMENU.EDIT) {
        // 이미지 정보 편집 버튼 클릭
        let image = Lodash.find(
          _sourceImages,
          (item) => item.imageId === imageId
        );
        if (!!image) {
          setImageInfoEditDialogData(image);
          setImageInfoEditDialogIsEditMode(true);
          setImageInfoEditDialogIsOpen(true);
        }
      } else if (
        menuId === ANNOTATORIMAGELIST_POPUPMENU.RAW ||
        menuId === ANNOTATORIMAGELIST_POPUPMENU.ING ||
        menuId === ANNOTATORIMAGELIST_POPUPMENU.COMPLETE ||
        menuId === ANNOTATORIMAGELIST_POPUPMENU.VALID ||
        menuId === ANNOTATORIMAGELIST_POPUPMENU.INVALID
      ) {
        // 상태 변경 버튼 클릭
        let entity = new SourceImageEntity(
          { imageId: imageId, status: menuId },
          _sourceImages
        );
        fctSourceImageUpdate(entity, setSourceImages);
      } else if (menuId === ANNOTATORIMAGELIST_POPUPMENU.DELETE) {
        // 이미지 삭제 버튼 클릭
        setOpenMessageBox({
          open: true,
          title: "원시이미지를 삭제하시겠습니까?",
          description:
            "선택한 원시이미지가 삭제 됩니다. 삭제된 데이터는 복구할 수 없습니다.",
          okButtonCaption: "삭제",
          cancelButtonCaption: "취소",
          onClose: (button) => {
            if (button === "OK") {
              fctSourceImageDelete(imageId);
            }

            setOpenMessageBox({ open: false });
          },
        });
      }
    }
  }

  /**
   * 이미지 정보 편집 다이얼로그 종료 (완료)
   */
  function handlerImageInfoEditDialog_Submmit(data) {
    // console.log(data);
    if (_imageInfoEditDialogIsEditMode) {
      // 편집
      let entity = new SourceImageEntity(data, _sourceImages);

      fctSourceImageUpdate(entity, setSourceImages);
    } else {
      // 추가
      let entity = new SourceImageEntity({
        ...data,
        sourceImageSetId: sourceImageSetId,
        fileName: _imageInfoEditDialogData.fileName,
        fileSize: _imageInfoEditDialogData.fileSize,
        image: _imageInfoEditDialogData.image,
      });

      fctSourceImageSetAdd(entity, setSourceImages);
    }

    setImageInfoEditDialogIsOpen(false);
  }

  /**
   * 이미지 추가
   */
  function fctSourceImageSetAdd(entity, setStateFunction) {
    if (!!entity.value) {
      AnnotatorAPI.UpdateSourceImage(sourceImageSetId, -1, entity.value).then(
        (res) => {
          if (!!res.data) {
            // console.log(res.data);
            setStateFunction((state) => [...state, res.data]);
          }
        }
      );
    }
  }

  /**
   * 이미지 수정
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function fctSourceImageUpdate(entity, setStateFunction) {
    if (!!entity.keyColumn && !!entity.keyValue) {
      AnnotatorAPI.UpdateSourceImage(
        sourceImageSetId,
        entity.keyValue,
        entity.value
      ).then((res) => {
        if (!!res.data) {
          setStateFunction((state) =>
            Lodash.map(state, (item) =>
              item[entity.keyColumn] === entity.keyValue ? res.data : item
            )
          );

          if (!!_selectedImage) {
            setSelectedImage((state) => ({ ...state, ...entity.value }));
          }

          // InfoSourceImageSet.valLastDate 컬럼 업데이트 (이미지 검증완료일)
          AnnotatorAPI.UpdateSourceImageSetValLastDate(
            projectId,
            sourceImageSetId
          );
        }
      });
    }
  }

  /**
   * 이미지 삭제
   */
  function fctSourceImageDelete(imageId) {
    AnnotatorAPI.DeleteSourceImage(sourceImageSetId, imageId).then((res) => {
      if (res.status === 200) {
        // console.log({imageId: imageId, selectedImage: _selectedImage});
        setSourceImages((state) =>
          state.filter((item) => item.imageId !== imageId)
        );

        // InfoSourceImageSet.valLastDate 컬럼 업데이트 (이미지 검증완료일)
        AnnotatorAPI.UpdateSourceImageSetValLastDate(
          projectId,
          sourceImageSetId
        );

        if (!!_selectedImage && imageId === _selectedImage.imageId) {
          // 현재 선택되어 있던 이미지를 삭제하는 경우 화면을 비움
          fctBoundingBoxUnselect();
          setBeforeAnnotations(null);
          setIsEditUndoDisabled(true);
          setAnnotations([]);
          setSelectedImage(null);
        }
      } else {
        setOpenMessageBox({
          open: true,
          title: "이미지 삭제 불가",
          description: "이미지 삭제에 실패했습니다. 잠시후 다시 시도해보세요.",
          okButtonCaption: "확인",
          cancelButtonCaption: "",
          onClose: () => {
            setOpenMessageBox({ open: false });
          },
        });
      }
    });
  }

  /**
   * 어노테이터 보드 마우스 휠 동작 (줌)
   */
  function handlerImageAnnotatorBoard_Wheel(e) {
    fctZoom(e.evt.deltaY < 0);
  }

  /**
   * 이미지 편집 가능 여부
   */
  function fctIsEditable(isShowAlert) {
    if (_sourceImageSet.sourceImageSetStatus === SOURCESTATUS.RELEASE) {
      if (isShowAlert) {
        setOpenMessageBox({
          open: true,
          title: "어노테이션 수정 불가",
          description:
            "원시이미지셋이 배포상태입니다. 이미지 및 어노테이션을 수정할 수 없습니다.",
          okButtonCaption: "확인",
          cancelButtonCaption: "",
          onClose: () => {
            setOpenMessageBox({ open: false });
          },
        });
      }
      return false;
    } else if (
      !props.onACLCheck(
        ACLCODE.어노테이션_편집_실행,
        false,
        isShowAlert,
        "어노테이션 수정 권한이 없습니다"
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      {!!_sourceImageSet && (
        <Box id={"Main"} sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              height: "100%",
            }}
          >
            <U2RoundedBox sx={{ flexBasis: WINDOWSIZE.LEFTSIDEWIDTH }}>
              <U2ImageAnnotatorImageList
                images={_sourceImages}
                selectedImageId={!!_selectedImage ? _selectedImage.imageId : ""}
                onACLCheck={props.onACLCheck}
                onImageClick={handlerThumbnailClick}
                onImageAdded={handlerAnnotatorImageList_ImageAdded}
                onImagesAdded={handlerAnnotatorImageList_ImagesAdded}
                onImageListPopupMenuClick={
                  handlerAnnotatorImageList_PopupMenuClick
                }
              />
            </U2RoundedBox>
            <U2RoundedBox sx={{ flexGrow: 1 }}>
              <U2ImageAnnotatorBoard
                id="ImageAnnotatorBoard"
                selectedToolButton={_selectedToolButtonId}
                selectedLabelButton={_selectedLabelButtonId}
                width={
                  _ajouCBC !== ""
                    ? window.innerWidth - 470
                    : window.innerWidth - 320
                }
                isSubTab={_ajouCBC !== ""}
                height={window.innerHeight - 70}
                scale={_konvaImageLayerScale}
                moveToCenterTrigger={_moveToCenterTrigger}
                labelFillEnabled={_labelFillEnabled}
                labelStrokeEnabled={_labelStrokeEnabled}
                labelManualEnabled={_labelManualEnabled}
                labelIntelliEnabled={_labelIntelliEnabled}
                imageInfo={_selectedImage}
                annotations={_annotations}
                selectedAnnotation={_selectedAnnotation}
                selectedBoundingBox={_selectedBoundingBox}
                transformBoxes={_transformBoxes}
                onBoundingBoxAdded={(boundingBoxRectInfo) =>
                  handlerImageAnnotatorBoard_BoundingBoxAdded(
                    _selectedImage.imageId,
                    _selectedLabelButtonId,
                    boundingBoxRectInfo
                  )
                }
                onBoundingBoxClick={handlerImageAnnotatorBoard_BoundingBoxClick}
                onTransformBoxDragMove={
                  handlerImageAnnotorBoard_TransformBoxDragMove
                }
                onTransformBoxDragEnd={
                  handlerImageAnnotorBoard_TransformBoxDragEnd
                }
                onLayerWheel={handlerImageAnnotatorBoard_Wheel}
                onMoveToCenterButtonClick={handlerMoveToCenterButtonClick}
              />
            </U2RoundedBox>

            {_ajouCBC !== "" && (
              <U2RoundedBox sx={{ flexBasis: WINDOWSIZE.CBCSIDEWIDTH }}>
                <U2ImageAnnotatorCBCToolBox specimen={_ajouCBC} />
              </U2RoundedBox>
            )}
            <U2RoundedBox
              id={"MainRight"}
              sx={{ flexBasis: WINDOWSIZE.RIGHTSIDEWIDTH }}
            >
              <Box
                sx={{
                  overflow: "hidden",
                  overflowY: "scroll",
                  position: "fixed",
                  width: "156px",
                  height: `calc(100vh - 85px)`,
                  marginBottom: "100px",
                }}
              >
                <U2ImageAnnotatorToolBox
                  id="ImageAnnotatorToolBox"
                  annotations={_annotations}
                  _selectedAnnotation={_selectedAnnotation}
                  labels={_labels}
                  scale={_konvaImageLayerScale.scale}
                  memo={_sourceImageSet.memo ? _sourceImageSet.memo : ""}
                  selectedToolButtonId={_selectedToolButtonId}
                  intelliModels={_intelliModes}
                  isEditUndoDisabled={_IsEditUndoDisabled}
                  boundingBoxButtonEnabled={
                    _sourceImageSet.sourceImageSetStatus !==
                      SOURCESTATUS.RELEASE &&
                    props.onACLCheck(ACLCODE.어노테이션_편집_실행)
                  }
                  intelliButtonEnabled={props.onACLCheck(
                    ACLCODE.어노테이션_인텔리_실행
                  )}
                  saveButtonEnabled={
                    _sourceImageSet.sourceImageSetStatus !==
                      SOURCESTATUS.RELEASE &&
                    props.onACLCheck(ACLCODE.어노테이션_인텔리_실행)
                  }
                  labelEditButtonEnabled={props.onACLCheck(
                    ACLCODE.라벨_추가_실행
                  )}
                  onACLCheck={props.onACLCheck}
                  onToolButtonGroup_SelectedButtonChanged={
                    handlerImageAnnotatorToolBox_SelectedToolButtonChanged
                  }
                  onEditButtonGroup_ButtonClick={(buttonId) =>
                    handlerImageAnnotatorToolBox_EditButtonClick(buttonId)
                  }
                  onLabelButtonGroup_SelectedButtonChanged={
                    handlerImageAnnotatorToolBox_SelectedLabelButtonChanged
                  }
                  onLabelButtonGroup_SelectedButtonChanged2={
                    handlerImageAnnotatorToolBox_SelectedLabelButtonChanged2
                  }
                  onAnnotationButtonGroup_SelectedButtonChanged={
                    handlerImageAnnotatorToolBox_SelectedAnnotationButtonChanged
                  }
                  onZoomButtonClick={handlerZoomButtonClick}
                  onMoveToCenterButtonClick={handlerMoveToCenterButtonClick}
                  onLabelStrokeEnableChanged={(enabled) =>
                    setLabelStrokeEnabled(enabled)
                  }
                  onLabelFillEnableChanged={(enabled) =>
                    setLabelFillEnabled(enabled)
                  }
                  onLabelManualEnableChanged={(enabled) =>
                    setLabelManualEnabled(enabled)
                  }
                  onLabelIntelliEnableChanged={(enabled) =>
                    setLabelIntelliEnabled(enabled)
                  }
                  onLabelEditorButton_Click={handlerLabelEditorButton_Click}
                />
              </Box>
            </U2RoundedBox>
          </Box>
        </Box>
      )}
      <U2ImageSetManagerLabelEditDialog
        labels={_labels}
        projectId={projectId}
        open={_IslabelEditOpen}
        onClose={handleLabelEditorCloseButton_Click}
      />
      <U2ImageInfoEditDialog
        open={_imageInfoEditDialogIsOpen}
        isEditMode={_imageInfoEditDialogIsEditMode}
        imageInfo={_imageInfoEditDialogData}
        onClose={() => setImageInfoEditDialogIsOpen(false)}
        onSubmit={handlerImageInfoEditDialog_Submmit}
      />
      <U2ImagesInfoEditDialog
        open={_imagesInfoEditDialogIsOpen}
        files={_imagesInfoEditDialogFiles}
        sourceImageSetId={sourceImageSetId}
        onClose={() => setImagesInfoEditDialogIsOpen(false)}
        onSubmit={handlerImagesInfoEditDialog_Submmit}
      />
      <U2MessageBox
        open={_openMessageBox.open}
        title={_openMessageBox.title}
        description={_openMessageBox.description}
        okButtonCaption={_openMessageBox.okButtonCaption}
        cancelButtonCaption={_openMessageBox.cancelButtonCaption}
        onClose={_openMessageBox.onClose}
      />
    </>
  );
}

export default Annotator;
