import Lodash from "lodash";
import { SOURCESTATUS } from "../Enums/Enums";

class SourceImageEntity {
  keyColumn = "imageId";

  // 기본값
  value = {
    imageId: -1,
    // teamId,
    // userId,
    // sourceImageSetId,
    status: SOURCESTATUS.RAW,
    isRare: false,
    // fileName,
    // fileSize,
    // title,
    image: "",
    thumbnail: "",
    // specimenId,
    // scanDate,
    // scanOption,
    // stain,
    u2ModelCellInfo: "",
    di60ImageInfo: "",
    di60CellInfo: "",
  };

  constructor(input, originList) {
    if (!!originList) {
      // 기존 값을 수정할 경우
      let origin = Lodash.find(
        originList,
        (item) => item[this.keyColumn] === input[this.keyColumn]
      );
      this.value = { ...origin, ...input };
    } else {
      // 신규로 생성할 경우
      this.value = { ...this.value, ...input };
    }

    this.keyValue = this.value[this.keyColumn];
  }
}

export default SourceImageEntity;
