import Axios from "../Utils/AxiosUtil";

var MemberAPI =
  typeof exports === "undefined" ? function numeric() {} : exports;
if (typeof global !== "undefined") {
  global.MemberAPI = MemberAPI;
}

const SERVICE = "/api/member";

MemberAPI.GetMyACL = async function GetMyACL() {
  // await new Promise((resolve, reject) => setTimeout(resolve, 3000));

  return Axios.Get(SERVICE, `/me/ACL`);
};

export default MemberAPI;
