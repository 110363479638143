import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";

export default function U2NavigationBarIconButton(props) {
  return (
    <Tooltip title={props.tooltip}>
      <span>
        <Button
          disabled={props?.disabled}
          id={props.id}
          onClick={props.onClick}
          startIcon={props.icon}
          sx={{
            width: "100%",
            justifyContent: "flex-start",
            color: (theme) =>
              theme.palette.mode === "dark" ? "#DDDDDD" : "000000",
            bgcolor: props.isSelected ? "#555555" : "#1D1D1D",
            textTransform: "none",
          }}
        >
          {props.text}
        </Button>
      </span>
    </Tooltip>
  );
}
