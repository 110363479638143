import React from 'react';

const U2ImageAnnotatorCBCToolBox = (props) => {
  const containerStyle = {
    display: 'fixed',
    flexDirection: 'column',
    width: '150px',
    margin: '0 auto',
    padding: '0px',
  };

  const rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0',
    borderBottom: '1px solid #eee'
  };

  const keyStyle = {
    fontWeight: 'bold',
    textAlign: 'left',
    width: '50%',
  };

  const valueStyle = {
    textAlign: 'right',
    width: '50%'
  };

  return (
    <div style={containerStyle}>
      {Object.entries(props.specimen).map(([key, value]) => (
        <div key={key} style={rowStyle}>
          <div style={keyStyle}>{key}</div>
          <div style={valueStyle}>{value}</div>
        </div>
      ))}
    </div>
  );
};

export default U2ImageAnnotatorCBCToolBox;
