import Axios from "../Utils/AxiosUtil";

var AjouSpecimenAPI =
  typeof exports === "undefined" ? function numeric() {} : exports;
if (typeof global !== "undefined") {
  global.AjouSpecimenAPI = AjouSpecimenAPI;
}

const SERVICE = "/api/ajouSpecimen";

AjouSpecimenAPI.GetCBC = function GetCBC(specimenName) {
  return Axios.Get(SERVICE, `/CBC/${specimenName}`);
};


export default AjouSpecimenAPI;
