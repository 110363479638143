import { useState, useEffect } from "react";
import { Layer, Line, Text } from "react-konva";

function U2ImageAnnotatorBoardGuideLayer(props) {
  const _guideStrokeColor = "gray";
  const _guideStrokeWidth = 1;
  const _guideStrokeDash = [6, 2];
  const [_guidePositions, setGuidePositions] = useState({
    horizontal: [0, 0, 0, 0],
    vertical: [0, 0, 0, 0],
  });
  const [_drawMode, setDrawMode] = useState("");
  const [_drawMousePosition, setDrawMousePosition] = useState({ x: 0, y: 0 });
  const [_drawStartPosition, setDrawStartPosition] = useState({ x: 0, y: 0 });
  const [_guideLineVisible, setGuideLineVisible] = useState(true);

  useEffect(() => {
    if (props.mousePosition.y !== undefined) {
      setGuidePositions({
        horizontal: [
          0,
          props.mousePosition.y,
          window.innerWidth,
          props.mousePosition.y,
        ],
        vertical: [
          props.mousePosition.x,
          0,
          props.mousePosition.x,
          window.innerHeight,
        ],
      });
    }
  }, [props.mousePosition]);

  useEffect(() => {
    setDrawMode(props.drawMode);
  }, [props.drawMode]);

  useEffect(() => {
    setDrawMousePosition(props.drawMousePosition);
  }, [props.drawMousePosition]);

  useEffect(() => {
    setDrawStartPosition(props.drawStartPosition);
  }, [props.drawStartPosition]);

  useEffect(() => {
    setGuideLineVisible(props.guideLineVisible);
  }, [props.guideLineVisible]);

  return (
    <>
      <Layer>
        {process.env.NODE_ENV === "development" ? (
          <>
            <Text
              x={10}
              y={10}
              fontSize={14}
              fill={"#FFFFFF"}
              opacity={0.3}
              width={300}
              text={"DrawMode"}
            ></Text>
            <Text
              x={10}
              y={30}
              fontSize={14}
              fill={"#FFFFFF"}
              opacity={0.3}
              width={300}
              text={"Mouse (Label)"}
            ></Text>
            <Text
              x={10}
              y={50}
              fontSize={14}
              fill={"#FFFFFF"}
              opacity={0.3}
              width={300}
              text={"Mouse (Stage)"}
            ></Text>
            <Text
              x={10}
              y={70}
              fontSize={14}
              fill={"#FFFFFF"}
              opacity={0.3}
              width={300}
              text={"Start Position"}
            ></Text>

            <Text
              x={110}
              y={10}
              fontSize={14}
              fill={"#FFFFFF"}
              opacity={0.3}
              width={300}
              text={_drawMode}
            ></Text>
            <Text
              x={110}
              y={30}
              fontSize={14}
              fill={"#FFFFFF"}
              opacity={0.3}
              width={300}
              text={_drawMousePosition.x + " " + _drawMousePosition.y}
            ></Text>
            <Text
              x={110}
              y={50}
              fontSize={14}
              fill={"#FFFFFF"}
              opacity={0.3}
              width={300}
              text={props.mousePosition.x + " " + props.mousePosition.y}
            ></Text>
            <Text
              x={110}
              y={70}
              fontSize={14}
              fill={"#FFFFFF"}
              opacity={0.3}
              width={300}
              text={_drawStartPosition.x + " " + _drawStartPosition.y}
            ></Text>
          </>
        ) : (
          ""
        )}

        <Line
          visible={_guideLineVisible}
          points={_guidePositions.horizontal}
          stroke={_guideStrokeColor}
          strokeWidth={_guideStrokeWidth}
          dash={_guideStrokeDash}
        ></Line>
        <Line
          visible={_guideLineVisible}
          points={_guidePositions.vertical}
          stroke={_guideStrokeColor}
          strokeWidth={_guideStrokeWidth}
          dash={_guideStrokeDash}
        ></Line>
      </Layer>
    </>
  );
}

export default U2ImageAnnotatorBoardGuideLayer;
