import React from "react";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";

import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import CircleIcon from "@mui/icons-material/PanoramaFishEye";
import FilledCircleIcon from "@mui/icons-material/Brightness1";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HalfFilledCircleIcon from "@mui/icons-material/Contrast";
import ExclamationCircleIcon from "@mui/icons-material/ErrorOutline";

import {
  ACLCODE,
  ANNOTATORIMAGELIST_POPUPMENU,
  SOURCESTATUS_LOOKUPDATASOURCE,
} from "../../Enums/Enums";
import U2AuthComponent from "../../Components/Common/U2AuthComponent";

function U2ImageAnnotatorImageListPopupMenu(props) {
  return (
    <Menu
      open={props.open}
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Paper sx={{ width: 240, maxWidth: "100%", fontSize: "small" }}>
        <MenuList>
          <U2AuthComponent aclId={ACLCODE.원시이미지_수정_실행}>
            <MenuItem
              onClick={() =>
                props.onMenuClick(
                  ANNOTATORIMAGELIST_POPUPMENU.EDIT,
                  props.selectedItemId
                )
              }
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>원시이미지 정보수정</ListItemText>
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
          </U2AuthComponent>
          <U2AuthComponent aclId={ACLCODE.원시이미지_상태변경_실행}>
            {SOURCESTATUS_LOOKUPDATASOURCE.map(
              (item) =>
                // 이미지 상태에는 "배포" 가 없음
                item.code !== ANNOTATORIMAGELIST_POPUPMENU.RELEASE && (
                  <MenuItem
                    key={item.code}
                    onClick={() =>
                      props.onMenuClick(item.code, props.selectedItemId)
                    }
                  >
                    <ListItemIcon>
                      {" "}
                      {item.code === ANNOTATORIMAGELIST_POPUPMENU.RAW ? (
                        <CircleIcon fontSize="small" />
                      ) : item.code === ANNOTATORIMAGELIST_POPUPMENU.ING ? (
                        <HalfFilledCircleIcon fontSize="small" />
                      ) : item.code ===
                        ANNOTATORIMAGELIST_POPUPMENU.COMPLETE ? (
                        <FilledCircleIcon fontSize="small" />
                      ) : item.code === ANNOTATORIMAGELIST_POPUPMENU.VALID ? (
                        <CheckCircleIcon fontSize="small" />
                      ) : item.code === ANNOTATORIMAGELIST_POPUPMENU.INVALID ? (
                        <ExclamationCircleIcon fontSize="small" />
                      ) : (
                        <EditIcon fontSize="small" />
                      )}{" "}
                    </ListItemIcon>
                    <ListItemText>{item.value}</ListItemText>
                  </MenuItem>
                )
            )}
            <Divider sx={{ my: 0.5 }} />
          </U2AuthComponent>
          <U2AuthComponent aclId={ACLCODE.원시이미지_삭제_실행}>
            <MenuItem
              onClick={() =>
                props.onMenuClick(
                  ANNOTATORIMAGELIST_POPUPMENU.DELETE,
                  props.selectedItemId
                )
              }
            >
              <ListItemIcon>
                <DeleteOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>원시이미지 삭제</ListItemText>
            </MenuItem>
          </U2AuthComponent>
        </MenuList>
      </Paper>
    </Menu>
  );
}

export default U2ImageAnnotatorImageListPopupMenu;
