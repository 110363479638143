import { useState, useEffect, useRef } from "react";
import Lodash from "lodash";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";

import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import AnnotatorAPI from "../../API/AnnotatorAPI";
import U2ImageSetManagerLabelList from "./U2ImageSetManagerLabelList";
import U2MessageBox from "../../Components/Common/U2MessageBox";

function U2ImageSetManagerLabelEditDialog(props) {
  /**
   * 라벨 목록 (@LibraryLabel)
   */
  const [_labels, setLabels] = useState([]);

  const [_selectedlabelId, setSelectedlabelId] = useState("");
  const _labelEditRef = useRef();

  const [_isMessageBoxOpen, setIsMessageBoxOpen] = useState(false);

  /****************************************************************************************************
   *
   * Data Load
   *
   ****************************************************************************************************/
  useEffect(() => {
    if (props.open) {
      AnnotatorAPI.GetLabelByProjectId(props.projectId).then((res) => {
        setLabels(res.data.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1)));

        setSelectedlabelId("");
      });
    }
  }, [props.open, props.projectId]);

  /****************************************************************************************************
   *
   * Event Handlers
   *
   ****************************************************************************************************/

  /**
   * 라벨 추가 버튼 클릭
   */
  function handlerLabelAddButton_Click(e) {
    fctLabelEdit();
  }

  function handlerLabelEdit_KeyDown(e) {
    if (e.key === "Enter") {
      fctLabelEdit();
    }
  }

  /**
   * 라벨리스트의 라벨 삭제 버튼 클릭을 통한 라벨 삭제
   */
  function handlerLabelListLabelDeleteButton_Click(labelId) {
    fctLabelDelete(labelId);
  }

  /**
   * 라벨리스트의 라벨 선택 시 수정모드 진입
   */
  function handlerLabelListLabel_Click(labelId, label) {
    setSelectedlabelId(labelId);
    _labelEditRef.current.value = label;
  }

  /**
   * 라벨 추가 또는 수정
   */
  function fctLabelEdit() {
    let label = _labelEditRef.current.value;

    if (_selectedlabelId !== "") {
      fctLabelModify(_selectedlabelId, label);
    } else {
      fctLabelAdd(label);
    }
  }

  /**
   * 라벨 추가
   */
  function fctLabelAdd(label) {
    if (
      !!label &&
      !_labels.some(function (item) {
        return item.label === label;
      })
    ) {
      let color =
        "rgb(" +
        Lodash.random(200) +
        "," +
        Lodash.random(200) +
        "," +
        Lodash.random(200) +
        ")";
      let newItem = {
        projectId: props.projectId,
        labelId: -1,
        label: label,
        color: color,
      };

      AnnotatorAPI.UpdateLabel(props.projectId, -1, newItem).then((res) => {
        if (res.status === 200) {
          newItem = res.data;
          setLabels((state) => [...state, newItem]);
          _labelEditRef.current.value = "";
        }
      });
    }
  }

  /**
   * 라벨 수정
   */
  function fctLabelModify(selectedlabelId, label) {
    let editItem = _labels.find((item) => item.labelId === selectedlabelId);
    editItem.label = label;

    AnnotatorAPI.UpdateLabel(props.projectId, selectedlabelId, editItem).then(
      (res) => {
        if (res.data.length > 0) {
          setLabels((state) =>
            state.labelId === selectedlabelId ? editItem : state
          );
          setSelectedlabelId("");
          _labelEditRef.current.value = "";
        }
      }
    );
  }

  /**
   * 라벨 삭제
   */
  function fctLabelDelete(labelId) {
    AnnotatorAPI.GetLabel(props.projectId, labelId).then((res) => {
      if (res.data.length > 0) {
        if (res.data[0].isUsed) {
          setIsMessageBoxOpen(true);
        } else {
          AnnotatorAPI.DeleteLabel(props.projectId, labelId).then((res) => {
            setLabels((state) =>
              state.filter((item) => item.labelId !== labelId)
            );
          });
        }
      }
    });
  }

  function handleMessageBoxClose() {
    setIsMessageBoxOpen(false);
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>라벨 관리</DialogTitle>
        <DialogContent>
          <FormControl sx={{ p: 0, m: 0 }} fullWidth variant="outlined">
            {/* <InputLabel htmlFor="outlined-adornment-password">라벨</InputLabel> */}
            <OutlinedInput
              id="outlined-adornment-password"
              inputRef={_labelEditRef}
              type="text"
              onKeyDown={handlerLabelEdit_KeyDown}
              // label='라벨'
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title={_selectedlabelId !== "" ? "저장" : "추가"}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handlerLabelAddButton_Click}
                      edge="end"
                    >
                      {_selectedlabelId !== "" ? (
                        <SaveAsOutlinedIcon />
                      ) : (
                        <AddCircleOutlineIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
            />
          </FormControl>
          <U2ImageSetManagerLabelList
            labels={_labels}
            selectedlabelId={_selectedlabelId}
            ShowLabelDeleteButton={true}
            labelSelectEnabled={true}
            onLabel_Click={handlerLabelListLabel_Click}
            onLabelDeleteButton_Click={handlerLabelListLabelDeleteButton_Click}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>닫기</Button>
        </DialogActions>
      </Dialog>
      <U2MessageBox
        open={_isMessageBoxOpen}
        title={"라벨을 삭제할 수 없습니다."}
        description={
          "어노테이션으로 사용중인 라벨 입니다. 삭제를 위해 어노테이션을 먼저 삭제해주세요."
        }
        okButtonCaption={"확인"}
        cancelButtonCaption={""}
        onClose={handleMessageBoxClose}
      />
    </>
  );
}

export default U2ImageSetManagerLabelEditDialog;
