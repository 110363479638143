import { useSelector } from "react-redux";
import Lodash from "lodash";

function U2AuthComponent(props) {
  const _aclCode = useSelector((state) => state.defaultReducer.acl);

  return (
    <>
      {Array.isArray(props.aclId)
        ? Lodash.some(_aclCode, (item) =>
            Lodash.some(props.aclId, (subItem) => item === subItem)
          ) && props.children
        : Lodash.some(_aclCode, (item) => item === props.aclId) &&
          props.children}
    </>
  );
}

export default U2AuthComponent;
