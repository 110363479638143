var ConvertUtil =
  typeof exports === "undefined" ? function numeric() {} : exports;
if (typeof global !== "undefined") {
  global.ConvertUtil = ConvertUtil;
}

ConvertUtil.DateToString = function DateToString(date) {
  let yyyy = date.getFullYear();
  let dd = date.getDate();
  let mm = date.getMonth() + 1;

  return (
    yyyy +
    "-" +
    (mm > 10 ? mm : "0" + mm) +
    "-" +
    (dd > 10 ? dd : "0" + dd) +
    " 00:00:00"
  );
};

export default ConvertUtil;
