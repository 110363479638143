import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Lodash from "lodash";

import U2LayoutBaseItem from "./U2LayoutBaseItem";
import U2LabelerHeader from "../../Components/Common/U2LabelerHeader";
import U2MessageBox from "../../Components/Common/U2MessageBox";
import ImageSetManager from "../ImageSetManager";
import Annotator from "../Annotator";
import Demo from "../Demo";

import MemberAPI from "../../API/MemberAPI";
import * as U2ReduxSlice from "../../Store/ReduxSlice";

function Layout(props) {
  const THEMEDARK = 0;
  // const THEMELIGHT = 1;
  const CURRENTTHEME = THEMEDARK;
  // const CURRENTTHEME = THEMELIGHT;

  const themeColors = {
    bgColor1: ["#1D1D1D", "#FFFFFF"],
    bgColor2: ["#323232", "#999999"],
    bgColor3: ["#272727", "#888888"],
    txtlight1: ["#FFFFFF", "#AAAAAA"],
    txtlight2: ["#FFFFFF", "#DDDDDD"],
    txtlight3: ["#999999", "#DDDDDD"],
    txtlogo1: ["#00A550", "#00A550"],
    txtlogo2: ["#8CC63F", "#8CC63F"],
    objbackdrop: ["#8CC63F", "#8CC63F"],
  };

  const theme = createTheme({
    palette: {
      mode: "dark",
      bgColor1: themeColors.bgColor1[CURRENTTHEME],
      bgColor2: themeColors.bgColor2[CURRENTTHEME],
      bgColor3: themeColors.bgColor3[CURRENTTHEME],
      txtlight1: themeColors.txtlight1[CURRENTTHEME],
      txtlight2: themeColors.txtlight2[CURRENTTHEME],
      txtlight3: themeColors.txtlight3[CURRENTTHEME],
      txtlogo1: themeColors.txtlogo1[CURRENTTHEME],
      txtlogo2: themeColors.txtlogo2[CURRENTTHEME],
      objbackdrop: themeColors.objbackdrop[CURRENTTHEME],
    },

    scrollbar: {
      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-corner": {
        background: "#000000",
      },
      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
        background: "#444444" /* 스크롤바 색상 */,
        // border: '1px solid #555555',
        borderRadius: "10px" /* 스크롤바 둥근 테두리 */,
      },
    },
  });

  const dispatch = useDispatch();
  const _aclCode = useSelector((state) => state.defaultReducer.acl);

  /**
   * 메시지 박스 정보
   */
  const [_openMessageBox, setOpenMessageBox] = useState({
    open: false,
    title: "",
    description: "",
    okButtonCaption: "",
    cancelButtonCaption: "",
    onClose: null,
  });

  /**
   * 로딩 (권한)
   */
  useEffect(() => {
    // 권한 코드 로드
    MemberAPI.GetMyACL().then((res) => {
      dispatch(U2ReduxSlice.acl_set(res.data));
      dispatch(U2ReduxSlice.myInfo_set(props.myInfo));
    });
  }, [dispatch, props.myInfo]);

  /**
   * 권한 체크 함수 (child 에 prop 으로 전달하여 사용 함)
   */
  const handlerACLCheck = useCallback(
    (aclId, useException, showAlert, message) => {
      let hasPermission = Lodash.some(_aclCode, (item) => item === aclId);

      if (!hasPermission) {
        if (showAlert) {
          setOpenMessageBox({
            open: true,
            title: "권한 없음",
            description: !!message
              ? message
              : "해당 기능을 사용할 권한이 없습니다.",
            okButtonCaption: "확인",
            cancelButtonCaption: "",
            onClose: () => {
              setOpenMessageBox({ open: false });
            },
          });
        }

        if (useException) {
          throw new Error("수행 권한이 없습니다");
        }
      }

      return hasPermission;
    },
    [_aclCode]
  );

  return (
    // 권한이 로딩 된 후 화면이 로딩 되도록 처리
    _aclCode.length > 0 ? (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <U2LayoutBaseItem />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              width: "100vw",
              height: "100vh",
              bgcolor: (theme) => theme.palette.bgColor2,
            }}
          >
            <U2LabelerHeader />
            <Routes>
              <Route
                path="/"
                element={<ImageSetManager onACLCheck={handlerACLCheck} />}
              ></Route>
              <Route
                path="/imagesetmanager"
                element={<ImageSetManager onACLCheck={handlerACLCheck} />}
              ></Route>
              <Route
                path="/annotator/:projectId/:sourceImageSetId"
                element={<Annotator onACLCheck={handlerACLCheck} />}
              ></Route>
              <Route
                path="/annotator/:projectId/:sourceImageSetId/:p_imageId"
                element={<Annotator onACLCheck={handlerACLCheck} />}
              ></Route>
              <Route
                path="/demo"
                element={<Demo onACLCheck={handlerACLCheck} />}
              ></Route>
              <Route
                path="/*"
                element={<ImageSetManager onACLCheck={handlerACLCheck} />}
              ></Route>
            </Routes>
          </Box>
          <U2MessageBox
            open={_openMessageBox.open}
            title={_openMessageBox.title}
            description={_openMessageBox.description}
            okButtonCaption={_openMessageBox.okButtonCaption}
            cancelButtonCaption={_openMessageBox.cancelButtonCaption}
            onClose={_openMessageBox.onClose}
          />
        </ThemeProvider>
      </BrowserRouter>
    ) : (
      ""
    )
  );
}

export default Layout;
