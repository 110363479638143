import { Cookies } from "react-cookie";

var CookieUtil =
  typeof exports === "undefined" ? function numeric() {} : exports;
if (typeof global !== "undefined") {
  global.CookieUtil = CookieUtil;
}

const _DOMIAN = {
  production: "u2cloud.co.kr",
  test: "211.232.120.67",
  development: "localhost",
};
const cookies = new Cookies();
const options = {
  path: "/",
  sameSite: "Strict",
  domain: _DOMIAN[process.env.REACT_APP_MODE],
  // httpOnly: true,
};

CookieUtil.fctGetCookie = function fctGetCookie(name) {
  return cookies.get(name);
};

CookieUtil.fctSetCookie = function fctSetCookie(name, value) {
  return cookies.set(name, value, options);
};

CookieUtil.fctRemoveCookie = function fctSetCookie(name) {
  return cookies.remove(name, options);
};

export default CookieUtil;
