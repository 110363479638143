import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function U2MessageBox(props) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!!props.okButtonCaption ? (
          <Button onClick={() => props.onClose("OK")}>
            {props.okButtonCaption}
          </Button>
        ) : (
          ""
        )}
        {!!props.cancelButtonCaption ? (
          <Button onClick={() => props.onClose("CANCEL")}>
            {props.cancelButtonCaption}
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
}

export default U2MessageBox;
