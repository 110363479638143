import Axios from "../Utils/AxiosUtil";

var U2CloudAPI =
  typeof exports === "undefined" ? function numeric() {} : exports;
if (typeof global !== "undefined") {
  global.U2CloudAPI = U2CloudAPI;
}

const U2CLOUDLOGINSERVICE = "/api/u2cloudLogin";
const U2CLOUDSERVICE = "/api/u2cloud";

U2CloudAPI.LoginForDevelop = function LoginForDevelop(email) {
  return Axios.Get(U2CLOUDLOGINSERVICE + "", `/loginForDevelop/${email}`);
};

U2CloudAPI.GetMyInfo = function GetMyInfo() {
  return Axios.Get(U2CLOUDSERVICE, `/me`);
};

U2CloudAPI.GetMembers = function GetMembers() {
  return Axios.Get(U2CLOUDSERVICE, `/me/members`);
};

U2CloudAPI.GetMySubscriptions = function GetMySubscriptions() {
  return Axios.Get(U2CLOUDSERVICE, `/me/subscriptions`);
};

export default U2CloudAPI;
