import React, { useState, useEffect, useRef } from "react";
import { FixedSizeList } from "react-window";
import { WindowScroller } from "react-virtualized";
import Lodash from "lodash";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";

import CircleIcon from "@mui/icons-material/PanoramaFishEye";
import FilledCircleIcon from "@mui/icons-material/Brightness1";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import HalfFilledCircleIcon from "@mui/icons-material/Contrast";
import ExclamationCircleIcon from "@mui/icons-material/ErrorOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import LinkIcon from '@mui/icons-material/Link';
import U2ImageAnnotatorImageListPopupMenu from "./U2ImageAnnotatorImageListPopupMenu";

import {
  ACLCODE,
  ANNOTATORIMAGELIST_POPUPMENU,
  SOURCESTATUS,
} from "../../Enums/Enums";
import U2AuthComponent from "../../Components/Common/U2AuthComponent";
import { Divider, IconButton } from "@mui/material";

const useStyles = makeStyles({
  filedrop: {
    "&:hover": {
      // border: '2px dashed #FFFFFF',
    },
  },
});

export default function U2ImageAnnotatorImageList(props) {
  const FilledMemoIcon = styled(StickyNote2OutlinedIcon)({
    color: "rgb(102,169,230)",
  });

  const [_selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [_images, setImages] = useState([]);

  /**
   * 희귀/일반 이미지 보여주기 선택용 필드, 보여줄지 여부를 논리값으로 표현
   */
  const [_selectedIsRareImages, setSelectedIsRareImages] = useState(false);

  useEffect(() => {
    let statusCode;
    let filterImages;
    // eslint-disable-next-line default-case
    switch (_selectedTabIndex) {
      case 1:
        statusCode = SOURCESTATUS.RAW;
        break;
      case 2:
        statusCode = SOURCESTATUS.ING;
        break;
      case 3:
        statusCode = SOURCESTATUS.COMPLETE;
        break;
      case 4:
        statusCode = SOURCESTATUS.VALID;
        break;
      case 5:
        statusCode = SOURCESTATUS.INVALID;
        break;
    }

    if (_selectedTabIndex === 0) {
      filterImages = props.images;
    } else {
      filterImages = Lodash.filter(
        props.images,
        (item) => item.status === statusCode
      );
    }

    if (_selectedIsRareImages) {
      filterImages = Lodash.filter(
        filterImages,
        (item) => item.isRare === _selectedIsRareImages
      );
    }
    setImages(filterImages);
  }, [props.images, _selectedTabIndex, _selectedIsRareImages]);

  function handlerImageClick(e) {
    props.onImageClick(e.target.src, e.target.id);
  }

  function toggleShowRare(e) {
    setSelectedIsRareImages((prev) => {
      return !prev;
    });
  }

  /**
   * 이미지 URL 복사
   */
  const fctSourceImageLinktoCilpBoard = (imageId) => {
    // 현재 URL 가져오기
    const currentUrl = window.location.href;
    // 현재 URL을 '/'로 분할하여 배열로 저장
    const urlSegments = currentUrl.split('/');
    
    // 필요한 부분 추출 ('annotator' 이후의 두 번째 요소까지)
    const index = urlSegments.indexOf('annotator');
    let urlToCopy = '';

    if (index !== -1 && index + 2 < urlSegments.length) {
      // 'annotator'와 그 다음 두 부분을 결합
      urlToCopy = `${urlSegments.slice(0, index + 3).join('/')}/${imageId}`;
    }

    // 클립보드에 텍스트 복사
    navigator.clipboard.writeText(urlToCopy)
      .then(() => {
        alert('클립보드에 복사되었습니다');
      })
      .catch((err) => {
        console.error('클립보드 복사에 실패했습니다: ', err);
      });
  };

  // function handlerSelectedTabIndexChanged(e, newValue) {
  // 	let statusCode;

  // 	setSelectedTabIndex(newValue);

  // 	switch(newValue) {
  // 		case 1: statusCode = SOURCESTATUS.ING; break;
  // 		case 2: statusCode = SOURCESTATUS.RAW; break;
  // 		case 3: statusCode = SOURCESTATUS.COMPLETE; break;
  // 		case 4: statusCode = SOURCESTATUS.VALID; break;
  // 		case 5: statusCode = SOURCESTATUS.INVALID; break;
  // 	}

  // 	if (newValue === 0) {
  // 		setImages(props.images);
  // 	} else {
  // 		setImages(Lodash.filter(props.images, (item) => item.status === statusCode));
  // 	}
  // }

  const _uploadBoxRef = useRef();
  const _uploadBoxTextRef1 = useRef();
  const _uploadBoxTextRef2 = useRef();
  const _max = 10;

  useEffect(() => {
    const uploadBox = _uploadBoxRef.current;

    const handleFiles = (files) => {
      if (files.length === 1) {
        for (const file of files) {
          // console.log(file)
          if (!file.type.startsWith("image/")) continue;

          const reader = new FileReader();
          reader.onloadend = (e) => {
            const result = e.target.result;
            if (result) {
              props.onImageAdded(file.name, result);
            }
          };
          reader.readAsDataURL(file);
        }
      } else {
        props.onImagesAdded(files);
      }
    };

    const dropHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const files = event.dataTransfer.files;
      handleFiles(files);
    };

    const dragOverHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    uploadBox.addEventListener("drop", dropHandler);
    uploadBox.addEventListener("dragover", dragOverHandler);

    return () => {
      uploadBox.removeEventListener("drop", dropHandler);
      uploadBox.removeEventListener("dragover", dragOverHandler);
    };
  }, [_max, props]);

  const classes = useStyles();

  const [_mouseOverImageId, setMouseOverImageId] = useState();

  function handlerImage_MouseEnter(imageId) {
    setMouseOverImageId(imageId);
  }

  function handlerImage_MouseLeave(imageId) {
    setMouseOverImageId("");
  }

  // 이미지리스트의 한 행 (FixedSizeList)
  const U2ImageAnnotatorImageListRow = ({ index, style }) => {
    const item = _images[index];
    // const item = props.images[index];

    const [_popupMenuAnchor, setPopupMenuAnchor] = useState(null);
    const [_popupMenuImageId, setPopupMenuImageId] = useState();

    function handlerPopupMenu_Open(e, imageId) {
      setPopupMenuImageId(imageId);
      setPopupMenuAnchor(e.currentTarget);
    }

    function handlerMenuClick(buttonId, imageId) {
      props.onACLCheck(
        ACLCODE.원시이미지_수정_실행,
        true,
        true,
        "원시이미지 정보를 수정할 권한이 없습니다."
      );
      setPopupMenuAnchor(null);

      props.onImageListPopupMenuClick(buttonId, imageId);
    }

    function handlerPopupMenu_Close() {
      setPopupMenuAnchor(null);
    }

    return (
      <Box
        key={item.imageId}
        sx={{ height: "165px", cursor: "pointer" }}
        onClick={handlerImageClick}
        onMouseEnter={() => handlerImage_MouseEnter(item.imageId)}
        onMouseLeave={() => handlerImage_MouseLeave(item.imageId)}
        style={style}
        data-index={index}
      >
        <img
          src={!!item.thumbnail ? item.thumbnail : item.image}
          alt={item.title}
          id={item.imageId}
          width="100%"
          height="162px"
        />
        {!!item.memo && (
          <Box
            sx={{
              position: "relative",
              top: "-165px",
              left: "163px",
              width: "24px",
              height: "24px",
            }}
          >
            <Tooltip title={item.memo}>
              <FilledMemoIcon
                onClick={() =>
                  handlerMenuClick(
                    ANNOTATORIMAGELIST_POPUPMENU.EDIT,
                    item.imageId
                  )
                }
              />
            </Tooltip>
          </Box>
        )}
        <Box
          sx={{
            position: "relative",
            top: !!item.memo ? "-51px" : "-28px",
            height: "24px",
            bgcolor:
              _mouseOverImageId === item.imageId
                ? "rgba(30, 150, 30, 0.5)"
                : Number(props.selectedImageId) === Number(item.imageId)
                ? "rgba(30, 200, 30, 0.5)"
                : "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexBasis: "20px",
              paddingTop: "4px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {item.status === SOURCESTATUS.ING ? (
              <Tooltip title="라벨링 진행 중">
                <HalfFilledCircleIcon fontSize="small" />
              </Tooltip>
            ) : item.status === SOURCESTATUS.COMPLETE ? (
              <Tooltip title="라벨링 완료">
                <FilledCircleIcon fontSize="small" />
              </Tooltip>
            ) : item.status === SOURCESTATUS.VALID ? (
              <Tooltip title="검증 완료">
                <CheckCircleIcon fontSize="small" />
              </Tooltip>
            ) : item.status === SOURCESTATUS.INVALID ? (
              <Tooltip title="검증 실패">
                <ExclamationCircleIcon fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip title="대기">
                <CircleIcon fontSize="small" />
              </Tooltip>
            )}
            {item.isRare ? (
              <Tooltip title="중요 이미지">
                <StarIcon sx={{ fontSize: "large" }} />
              </Tooltip>
            ) : (
              <Tooltip title="일반 이미지">
                <StarBorderIcon sx={{ fontSize: "large" }} />
              </Tooltip>
            )}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              color: "#FFFFFF",
              fontSize: "small",
              paddingLeft: "3px",
              paddingTop: "3px",
              paddingRight: "3px",
              height: "22px",
              overflow: "hidden",
              wordBreak: "break-all",
              wordWrap: "break-word",
            }}
          >
            <Tooltip
              title={JSON.stringify({
                ID: item.imageId,
                파일명: item.title,
                상태: item.status,
              })}
            >
              <Box>{item.title}</Box>
            </Tooltip>
          </Box>
          <IconButton onClick={() => {fctSourceImageLinktoCilpBoard(item.imageId)}} aria-label="copy link" 
          sx={{padding:'0px'}}>
            <Tooltip title="이미지 링크 복사">
              <LinkIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <U2AuthComponent
            aclId={[
              ACLCODE.원시이미지_수정_실행,
              ACLCODE.원시이미지_상태변경_실행,
              ACLCODE.원시이미지_삭제_실행,
            ]}
          >
            <Box
              sx={{ flexBasis: "30px", height: "24px", paddingTop: "4px" }}
              display="flex"
              justifyContent="flex-end"
              onClick={(e) => handlerPopupMenu_Open(e, item.imageId)}
            >
              <Tooltip title="원시데이터 관리">
                <MoreHorizIcon fontSize="small" />
              </Tooltip>
            </Box>
          </U2AuthComponent>
        </Box>

        <U2ImageAnnotatorImageListPopupMenu
          open={Boolean(_popupMenuAnchor)}
          anchorEl={_popupMenuAnchor}
          selectedItemId={_popupMenuImageId}
          onClose={handlerPopupMenu_Close}
          onMenuClick={handlerMenuClick}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        width: "100%",
      }}
    >
      <Box sx={{ flexBasis: 100 }}>
        <Box
          ref={_uploadBoxRef}
          className={classes.filedrop}
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            // border: (a ? '2px dashed #FFFFFF' : '2px dashed #999999'),
            border: "2px dashed #999999",
            color: "#999999",
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          <Box ref={_uploadBoxTextRef1} fontSize="large">
            이미지 업로드
          </Box>
          <Box ref={_uploadBoxTextRef2} fontSize="small" marginTop="10px">
            이미지를 이곳으로
            <br />
            드래그 하세요
          </Box>
        </Box>
      </Box>
      <Box sx={{ flexBasis: 60 }}>
        <Box
          sx={{ width: "200px", borderBottom: 1, borderColor: "divider" }}
          display="flex"
        >
          <Box sx={{ width: "175px" }}>
            <Tabs
              value={_selectedTabIndex}
              onChange={(e, newValue) => setSelectedTabIndex(newValue)}
              variant="fullWidth"
            >
              <Tooltip title="전체 보기">
                <Tab
                  label="ALL"
                  sx={{ fontSize: "small", minWidth: "10px", padding: "12px" }}
                />
              </Tooltip>
              <Tooltip title="라벨링 시작 전">
                <Tab
                  icon={<CircleIcon sx={{ fontSize: "medium" }} />}
                  sx={{ fontSize: "small", minWidth: "10px", padding: "12px" }}
                />
              </Tooltip>
              <Tooltip title="라벨링 진행 중">
                <Tab
                  icon={<HalfFilledCircleIcon sx={{ fontSize: "medium" }} />}
                  sx={{ fontSize: "small", minWidth: "10px", padding: "12px" }}
                />
              </Tooltip>
              <Tooltip title="검증대기">
                <Tab
                  icon={<FilledCircleIcon sx={{ fontSize: "medium" }} />}
                  sx={{ fontSize: "small", minWidth: "10px", padding: "12px" }}
                />
              </Tooltip>
              <Tooltip title="검증완료">
                <Tab
                  icon={<CheckCircleIcon sx={{ fontSize: "medium" }} />}
                  sx={{ fontSize: "small", minWidth: "10px", padding: "12px" }}
                />
              </Tooltip>
              <Tooltip title="검증실패">
                <Tab
                  icon={<ExclamationCircleIcon sx={{ fontSize: "medium" }} />}
                  sx={{ fontSize: "small", minWidth: "10px", padding: "12px" }}
                />
              </Tooltip>
            </Tabs>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              width: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title="중요 이미지">
              <Box sx={{ display: "flex" }}>
                {_selectedIsRareImages ? (
                  <StarIcon
                    onClick={toggleShowRare}
                    sx={{ fontSize: "medium" }}
                  />
                ) : (
                  <StarBorderIcon
                    onClick={toggleShowRare}
                    sx={{ fontSize: "medium" }}
                  />
                )}
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      {/* 성능향상을 위해 화면에 보이는 영역에 대해서만 렌더링 할 수 있는 WindowScroller 를 사용 함 */}
      <WindowScroller>
        {({ height, isScrolling, registerChild, scrollTop }) => (
          <Box ref={registerChild}>
            <FixedSizeList
              height={height - 245}
              itemCount={_images.length}
              itemSize={166}
              width={200}
              scrollTop={scrollTop}
              isScrolling={isScrolling}
            >
              {U2ImageAnnotatorImageListRow}
            </FixedSizeList>
          </Box>
        )}
      </WindowScroller>
    </Box>
  );
}
