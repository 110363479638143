import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  CircularProgress,
  ImageListItem,
  Pagination,
} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import Lodash from "lodash";
import StatisticsAPI from "../../API/StatisticsAPI";
import U2AnnotationLabelSelectList from "./U2AnnotationLabelSelectList";
import * as U2ReduxSlice from "../../Store/ReduxSlice";

const CanvasImage = ({ image, boundingBox, scale, handleClick }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      const canvas = canvasRef.current;
      canvas.width = boundingBox.width;
      canvas.height = boundingBox.height;
      const context = canvas.getContext("2d");

      context.drawImage(
        img,
        boundingBox.x,
        boundingBox.y, // source rect with content to crop
        boundingBox.width,
        boundingBox.height,
        0,
        0, // 그리기 시작할 원점
        boundingBox.width,
        boundingBox.height
      );
    };
  }, [image, boundingBox, scale]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: `scale(${scale})`,
      }}
    >
      <canvas ref={canvasRef} onClick={handleClick} />
    </div>
  );
};

const _initState = {
  listCols: 10,
  imgSize: 100,
};

const getLimit = (count) => {
  switch (count) {
    case "micro":
      return 225;
    case "small":
      return 100;
    case "medium":
      return 25;
    case "large":
      return 16;
    default:
      return 100;
  }
};
const getImageSize = (tableProp, size) => {
  switch (size) {
    case "small":
      return tableProp.imgSize * 0.5;
    case "default":
      return tableProp.imgSize;
    case "original":
      return 0;
    case "large":
      return tableProp.imgSize * 2;
    default:
      return 100;
  }
};

export default function U2AnnotationImageCanvasList(props) {
  const dispatch = useDispatch();

  /**
   * 현재페이지(page), 총 페이지 수(totalPages), 총 이미지 수(totalCount), 이미지 배율(scale)
   */
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [scale, setScale] = useState(1);
  /**
   * 페이지 당 조회 수(limit), 이미지 별 축소/확대(imageSize)
   */
  const [limit, setLimit] = useState(100);
  const [imageSize, setImageSize] = useState(0);

  const [_annotations, setAnnotations] = useState([]);

  const [tableProp, setTableProp] = useState(_initState);
  // const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenu, setContextMenu] = useState({
    show: false,
    annotation: null,
  });
  const [labelChange, setLabelChange] = useState({});

  // viewSize      : 보여줄 이미지의 개별 크기 분류
  // viewCount     : 보여줄 이미지의 개수 분류
  // width, height : 보여줄 영역의 크기
  const { viewSize, viewCount, width, height, isLoading, setIsLoading } = props;

  /****************************************************************************************************
   *
   * Data Load
   *
   ****************************************************************************************************/

  // 라벨 변경에 따른 카운팅 동작
  useEffect(() => {
    StatisticsAPI.GetAnnotationAndSourceImageCountByLabelIdAndPaging(
      props.labelId,
      props.showOnlyMyAnnotation,
      props.tagId
    ).then((res) => {
      if (res.status === 200 && res.data.length !== 0) {
        setTotalCount(Number(res.data[0].annotationCount));
      }
    });
  }, [props.labelId, props.showOnlyMyAnnotation, props.tagId]);

  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.labelId, limit, page]);

  const fetchImages = useCallback(() => {
    setIsLoading(true);
    StatisticsAPI.GetAnnotationAndSourceImageByLabelIdAndPaging(
      props.labelId,
      props.showOnlyMyAnnotation,
      props.tagId,
      limit,
      page
    ).then((res) => {
      if (res.status === 200) {
        Lodash.map(res.data, (item, index) => {
          res.data[index].boundingBox = JSON.parse(item.boundingBox);
        });
        setAnnotations(res.data);
        setIsLoading(false);
      } else {
        dispatch(
          U2ReduxSlice.snackbar_open(
            "데이터를 불러오는 중 오류가 발생했습니다."
          )
        );
      }
    });
  }, [props, limit, page, dispatch, setIsLoading]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalCount / limit));
  }, [totalCount, limit]);

  useEffect(() => {
    let listCols = Math.floor(Math.sqrt(limit));
    let minValue = Math.min(width, height) - 26 - listCols * 6; // Pagination 위치 & 개수 별 보정 치
    let imgSize = minValue / listCols;
    setTableProp({ listCols: listCols, imgSize: imgSize });
  }, [limit, width, height]);

  useEffect(() => {
    setLimit(getLimit(viewCount));
  }, [viewCount]);
  useEffect(() => {
    setImageSize(getImageSize(tableProp, viewSize));
  }, [tableProp, viewSize]);

  useEffect(() => {
    setPage(1);
  }, [viewCount, props.labelId]);

  useEffect(() => {
    const maxDimension = Math.max(
      ..._annotations.map((anno) =>
        Math.max(anno.boundingBox.width, anno.boundingBox.height)
      )
    );
    const scale = imageSize === 0 ? 1 : imageSize / maxDimension;
    setScale(scale);
  }, [_annotations, imageSize]);

  /****************************************************************************************************
   *
   * Event Handlers
   *
   ****************************************************************************************************/

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleClick = (annotation) => (e) => {
    setAnchorEl(e.currentTarget);
    setContextMenu({ show: true, annotation: annotation });
  };

  const closeContextMenu = () => {
    setContextMenu({ show: false, annotation: null });
  };

  const handleLabelChange = (id, originalLabel, labelId) => {
    setLabelChange((prevState) => {
      const updatedState = { ...prevState };

      if (!updatedState[id]) {
        updatedState[id] = { originalLabel, labelId };
      } else if (updatedState[id].originalLabel === labelId) {
        delete updatedState[id];
      }
      return updatedState;
    });

    props.onStatisticsChange(originalLabel, labelId);
  };

  return (
    <>
      <Box
        width={width}
        height={height - 32}
        display="flex"
        justifyContent="center"
        alignItems={isLoading ? "center" : "flex-start"}
      >
        {isLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          <ImageList
            variant="standard"
            cols={tableProp.listCols}
            sx={{ margin: "0px" }}
          >
            {Lodash.map(_annotations, (item, index) => {
              return (
                <ImageListItem
                  key={index}
                  style={{
                    width: `${tableProp.imgSize}px`,
                    height: `${tableProp.imgSize}px`,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "1px",
                  }}
                >
                  <CanvasImage
                    image={item.image}
                    boundingBox={item.boundingBox}
                    scale={scale}
                    handleClick={handleClick(item)}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        )}
      </Box>
      <Box display="flex" justifyContent="center" marginTop="5px">
        <Pagination
          count={totalPages}
          page={page}
          size="small"
          onChange={handlePageChange}
        />
      </Box>

      <div onClick={closeContextMenu}>
        {contextMenu.show && (
          <U2AnnotationLabelSelectList
            annotation={contextMenu.annotation}
            onClose={closeContextMenu}
            anchorEl={anchorEl}
            onLabelChange={handleLabelChange}
            labelChangeList={labelChange}
            category={props.category}
          />
        )}
      </div>
    </>
  );
}
