import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import U2RoundedBox from "../Common/U2RoundedBox";

export default function U2NavigationBarColorButton(props) {
  function handlerButtonClick(e) {
    props.onClick(e);
  }

  return (
    <Button
      id={props.id}
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        color: (theme) =>
          theme.palette.mode === "dark" ? "#DDDDDD" : "000000",
        bgcolor: (theme) =>
          props.isSelected ? "#555555" : theme.palette.bgColor1,
        textTransform: "none",
      }}
      onClick={handlerButtonClick}
    >
      <Box sx={{ display: "flex", alignItems: "center" }} id={props.id}>
        {props.text === "라벨 초기화" ? (
          <Box
            sx={{
              width: 23,
              height: 23,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DeleteIcon
              sx={{
                width: 16,
                height: 16,
                marginRight: "8px",
              }}
            />
          </Box>
        ) : props.useColorButtonBackground ? (
          <U2RoundedBox
            sx={{
              width: 16,
              height: 16,
              bgcolor: props.color,
              borderRadius: 1,
              margin: 0,
              marginRight: 1,
              padding: 0,
              border: "0px",
              borderColor: props.color,
            }}
          />
        ) : (
          <U2RoundedBox
            sx={{
              width: 12,
              height: 12,
              borderRadius: 1,
              margin: 0,
              marginRight: 1,
              padding: 0,
              border: "2px solid",
              borderColor: props.color,
            }}
          />
        )}
        {props.text}
      </Box>
      {/* 선택되어진 라벨2 cell 버튼에 체크 아이콘 표시 */}
      {props.labelId2 === props.id && props._selectedLabelTabPage === 3 ? (
        <CheckIcon />
      ) : (
        <></>
      )}
    </Button>
  );
}
