import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

function U2ImageSetTagEditDialog(props) {
  return (
    <Dialog open={props.open} onClose={() => props.onClose("")}>
      <DialogTitle>원시이미지셋 태그 수정</DialogTitle>
      <List sx={{ pt: 0 }}>
        {props.tag.map((item) => (
          <ListItem disableGutters key={item.sourceImageSetTagId}>
            <ListItemButton
              onClick={() => props.onClose(item.sourceImageSetTagId)}
            >
              <ListItemText primary={item.description} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default U2ImageSetTagEditDialog;
