import Box from "@mui/material/Box";

export default function U2RoundedBox(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        padding: 1,
        margin: 0.5,
        bgcolor: (theme) => theme.palette.bgColor1,
        color: (theme) => theme.palette.txtlight1,
        border: "1px solid",
        borderColor: (theme) => theme.palette.bgColor2,
        borderRadius: 2,
        ...sx,
      }}
      {...other}
    />
  );
}
