import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { Box, Tabs, Tab, Popper, Paper, Button } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import Lodash from "lodash";

import U2NavigationBarColorButtonGroup from "../../Components/NavationBars/U2NavigationBarColorButtonGroup";
import AnnotatorAPI from "../../API/AnnotatorAPI";
import * as U2ReduxSlice from "../../Store/ReduxSlice";

export default function U2AnnotationLabelSelectList({
  annotation,
  onClose,
  anchorEl,
  onLabelChange,
  labelChangeList,
  category,
}) {
  const [_selectedLabelTabPage, setSelectedLabelTabPage] = useState(0);
  const [labels, setLabels] = useState({});
  const [_cellInfo, setCellInfo] = useState({});
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    setCellInfo(category.find((obj) => obj.labelId === annotation.labelId));
  }, [category, annotation.labelId]);

  const fctImageAnnotationUpdate = (entity) => {
    let prevLabelId = annotation.labelId;
    let id = annotation.annotationId;
    if (!!entity && _selectedLabelTabPage !== 3) {
      if (annotation.labelId === entity) {
        dispatch(
          U2ReduxSlice.snackbar_open(
            `이미 해당 라벨 입니다. 다시 확인해주세요.`
          )
        );
      } else {
        annotation.labelId = entity;
        AnnotatorAPI.UpdateAnnotation(
          annotation.sourceImageSetId,
          annotation.imageId,
          id,
          annotation
        ).then((res) => {
          if (res.status === 200) {
            // 수정된 이미지에 대해 벗어나기 전 원 복구 확인용 UI 작업
            if (
              labelChangeList &&
              labelChangeList[id] &&
              labelChangeList[id].originalLabel === entity
            ) {
              anchorEl.style.filter = "";
            } else {
              anchorEl.style.filter = "grayscale(100%)";
            }
            onLabelChange(id, prevLabelId, entity);

            dispatch(
              U2ReduxSlice.snackbar_open(`${res.data.label}로 변경되었습니다.`)
            );
          } else {
            dispatch(U2ReduxSlice.snackbar_open(`오류가 발생했습니다.`));
          }
        });
      }
    } else if (_selectedLabelTabPage === 3) {
      if (annotation.labelId2 === entity) {
        dispatch(
          U2ReduxSlice.snackbar_open(
            `이미 해당 라벨 입니다. 다시 확인해주세요.`
          )
        );
      } else {
        annotation.labelId2 = entity;
        AnnotatorAPI.UpdateAnnotation(
          annotation.sourceImageSetId,
          annotation.imageId,
          id,
          annotation
        ).then((res) => {
          if (res.status === 200) {
            // 수정된 이미지에 대해 벗어나기 전 원 복구 확인용 UI 작업
            if (
              labelChangeList &&
              labelChangeList[id] &&
              labelChangeList[id].originalLabel === entity
            ) {
              anchorEl.style.filter = "";
            } else {
              anchorEl.style.filter = "grayscale(100%)";
            }
            // 집계 카운트 변경 함수, 라벨2 변경은 집계항목에 영향을 주지 않음. 추후 줄 때 사용
            // onLabelChange(id, prevLabelId, entity);

            if (entity !== null) {
              dispatch(
                U2ReduxSlice.snackbar_open(
                  `${res.data.label2}로 변경되었습니다.`
                )
              );
            } else if (entity === null) {
              dispatch(
                U2ReduxSlice.snackbar_open(`다중라벨 선택이 해제되었습니다.`)
              );
            }
          } else {
            dispatch(U2ReduxSlice.snackbar_open(`오류가 발생했습니다.`));
          }
        });
      }
    }
  };

  useEffect(() => {
    AnnotatorAPI.GetLabelByProjectId(annotation.projectId).then((res) => {
      setLabels(res.data.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1)));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e, newValue) => {
    e.stopPropagation(); // 탭 전환시 Close 동작 방지
    setSelectedLabelTabPage(newValue);
  };

  return (
    <Popper
      open
      placement="right-start"
      style={{ zIndex: theme.zIndex.tooltip }}
      anchorEl={anchorEl}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper sx={{ border: "1px solid grey" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderBottom: 1,
                borderColor: "divider",
              }}
              onClick={() => {
                window.open(
                  `/annotator/${annotation.projectId}/${annotation.sourceImageSetId}/${annotation.imageId}`,
                  "_blank"
                );
                onClose();
              }}
            >
              <Button>
                <ImageSearchIcon />
                이미지 보기
              </Button>
            </Box>

            <Tabs value={_selectedLabelTabPage} onChange={handleChange}>
              <Tab label="RBC" sx={{ fontSize: "small", minWidth: "0px" }} />
              <Tab label="WBC" sx={{ fontSize: "small", minWidth: "0px" }} />
              <Tab label="PLT" sx={{ fontSize: "small", minWidth: "0px" }} />
              <Tab
                label="다중라벨"
                sx={{ fontSize: "small", minWidth: "0px" }}
              />
            </Tabs>
          </Box>
          <Box
            sx={{
              width: "300px",
              maxHeight: `calc(100vh - 610px)`,
              overflowY: "scroll",
            }}
          >
            {(_selectedLabelTabPage === 0 ||
              _selectedLabelTabPage === 1 ||
              _selectedLabelTabPage === 2 ||
              _selectedLabelTabPage === 3) && (
              <U2NavigationBarColorButtonGroup
                groupName=""
                groupEditorEnabled={false}
                buttons={Lodash.map(
                  Lodash.filter(
                    labels,
                    (item) =>
                      item.category ===
                        (_selectedLabelTabPage === 0
                          ? "RBC"
                          : _selectedLabelTabPage === 1
                          ? "WBC"
                          : _selectedLabelTabPage === 2
                          ? "PLT"
                          : _cellInfo.category) ||
                      item.category === "UNI" ||
                      !item.category
                  ),
                  (item) => ({
                    id: item.labelId,
                    color: item.color,
                    text: item.label,
                  })
                  // 라벨1에서 선택한 것의 라벨2 중복방지
                ).filter((item) =>
                  _selectedLabelTabPage === 3
                    ? item.text !== _cellInfo.label
                    : true
                )}
                useColorButtonBackground={true}
                onSelectedButtonChanged={fctImageAnnotationUpdate}
                annotation={annotation}
                _selectedLabelTabPage={_selectedLabelTabPage}
              />
            )}
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}
