import React, { useState, useEffect } from "react";
import Lodash from "lodash";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import ConvertUtil from "../../Utils/ConvertUtil";
import DrawUtil from "../../Utils/DrawUtil";
import { SOURCESTATUS } from "../../Enums/Enums";

function U2ImagesInfoEditDialog(props) {
  const _formFields = [
    { id: "specimenId", label: "검체등록번호" },
    { id: "scanDate", label: "스캔일자" },
    { id: "scanOption", label: "스캔설정" },
    { id: "stain", label: "염색정보" },
    { id: "memo", label: "메모" },
  ];

  const [_images, setImages] = useState([]);

  useEffect(() => {
    setImages([]);

    for (const file of props.files) {
      // console.log(file)
      if (!file.type.startsWith("image/")) continue;

      const reader = new FileReader();
      reader.onloadend = (e) => {
        const image = e.target.result;
        if (image) {
          // console.log(file.name);
          // console.log(result);
          // console.log(props.images.length);
          DrawUtil.fctGetThumbnail(image, 80, 70).then((res) => {
            let imageInfo = {
              imageId: -1,
              status: SOURCESTATUS.RAW,
              isRare: false,
              fileName: file.name,
              fileSize: image.length,
              title: file.name,
              specimenId: "",
              scanDate: "",
              scanOption: "",
              stain: "",
              creation: new Date().toDateString(),
              image: image,
              thumbnail: res.thumbnail,
              width: res.oriWidth,
              height: res.oriHeight,
            };

            setImages((state) => [...state, imageInfo]);
          });
        }
      };
      reader.readAsDataURL(file);
    }
  }, [props.files]);

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth="md">
      <Formik
        initialValues={{
          ...props.imageInfo,
          specimenId: "",
          scanDate: ConvertUtil.DateToString(new Date()),
          scanOption: "",
          stain: "",
          memo: "",
        }}
        validationSchema={yup.object({
          specimenId: yup.string("").required("필수"),
          scanDate: yup.string("").required("필수"),
          // scanOption: yup.string('').required('필수'),
          // stain: yup.string('').required('필수'),
        })}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          props.onSubmit(data, _images);
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          helperText,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogTitle>원시데이터 정보 등록</DialogTitle>
            <DialogContent>
              <Box sx={{ textAlign: "right" }}>{_images.length} 개</Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                }}
              >
                <Box sx={{ flexGlow: 1, marginLeft: "20px" }}>
                  {_formFields.map((item) =>
                    item.id === "scanDate" ? (
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        key={item.id}
                      >
                        <MuiDatePicker
                          value={values.scanDate}
                          label={item.label}
                          error={Boolean(touched[item.id] && errors[item.id])}
                          inputFormat="yyyy-MM-dd"
                          mask="____-__-__"
                          onChange={(value) =>
                            setFieldValue(
                              "scanDate",
                              ConvertUtil.DateToString(value),
                              true
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              margin="dense"
                              fullWidth
                              variant="standard"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    ) : (
                      <Field
                        key={item.id}
                        margin="dense"
                        fullWidth
                        variant="standard"
                        as={TextField}
                        name={item.id}
                        label={item.label}
                        error={Boolean(touched[item.id] && errors[item.id])}
                        helperText={errors[item.id]}
                      />
                    )
                  )}
                </Box>
                <Box
                  sx={{
                    fontSize: "small",
                    marginTop: "28px",
                    marginLeft: "20px",
                    paddingLeft: "20px",
                    borderLeft: "1px dashed #777777",
                    color: "rgb(77, 191, 253)",
                    width: "520px",
                    height: "310px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  {Lodash.map(_images, (item) => (
                    <Box
                      key={item.fileName}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "8px",
                        height: "60px",
                      }}
                    >
                      <img src={item.thumbnail} width="80px" alt="" />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "11px",
                            u2width: "200px",
                          }}
                        >
                          <Box>{item.fileName}</Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "200px",
                          }}
                        >
                          <Box>{item.fileSize} kb</Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "200px",
                          }}
                        >
                          <Box>{`${item.width} X ${item.height} pixel`}</Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              {/* <Button type="submit" disabled={isSubmitting} onClick={() => fctSave(values)}>추가</Button> */}
              <Button type="submit" disabled={isSubmitting}>
                추가
              </Button>
              <Button onClick={() => props.onClose("CANCEL")}>취소</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default U2ImagesInfoEditDialog;
