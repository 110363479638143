import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

export default function U2ProductLogo(props) {
  const navigate = useNavigate();

  function handlerLogo_Click() {
    navigate("/");
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 58,
        fontSize: "1.9rem",
        fontWeight: "bold",
        cursor: "pointer",
      }}
      onClick={handlerLogo_Click}
    >
      <Box sx={{ color: (theme) => theme.palette.txtlogo1, marginLeft: 1 }}>
        U2
      </Box>
      {props.url !== "/demo" && (
        <>
          <Box sx={{ color: (theme) => theme.palette.txtlogo2 }}>Image</Box>
          <Box sx={{ color: (theme) => theme.palette.txtlogo2 }}>Labeler</Box>
        </>
      )}
      {props.url === "/demo" && (
        <>
          <Box sx={{ color: (theme) => theme.palette.txtlogo2 }}>
            AI Demo&nbsp;
          </Box>
          <Box sx={{ color: "#CCCCCC" }}>PBS Morphology</Box>
        </>
      )}
    </Box>
  );
}
