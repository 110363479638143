import React from "react";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import EditIcon from "@mui/icons-material/Edit";

import CircleIcon from "@mui/icons-material/PanoramaFishEye";
import FilledCircleIcon from "@mui/icons-material/Brightness1";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HalfFilledCircleIcon from "@mui/icons-material/Contrast";
import ExclamationCircleIcon from "@mui/icons-material/ErrorOutline";

import { ANNOTATORIMAGELIST_POPUPMENU } from "../../Enums/Enums";

function U2ImageAnnotatorIntelliModelPopupMenu(props) {
  return (
    <Menu
      open={props.open}
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Paper sx={{ width: 240, maxWidth: "100%", fontSize: "small" }}>
        <MenuList>
          {props.intelliModels.map((item) => (
            // 이미지 상태에는 "배포" 가 없음
            <MenuItem
              key={item.weight_name}
              onClick={() => props.onMenuClick(item.weight_name)}
            >
              <ListItemIcon>
                {" "}
                {item.code === ANNOTATORIMAGELIST_POPUPMENU.RAW ? (
                  <CircleIcon fontSize="small" />
                ) : item.code === ANNOTATORIMAGELIST_POPUPMENU.ING ? (
                  <HalfFilledCircleIcon fontSize="small" />
                ) : item.code === ANNOTATORIMAGELIST_POPUPMENU.COMPLETE ? (
                  <FilledCircleIcon fontSize="small" />
                ) : item.code === ANNOTATORIMAGELIST_POPUPMENU.VALID ? (
                  <CheckCircleIcon fontSize="small" />
                ) : item.code === ANNOTATORIMAGELIST_POPUPMENU.INVALID ? (
                  <ExclamationCircleIcon fontSize="small" />
                ) : (
                  <EditIcon fontSize="small" />
                )}{" "}
              </ListItemIcon>
              <ListItemText>{item.weight_name}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    </Menu>
  );
}

export default U2ImageAnnotatorIntelliModelPopupMenu;
