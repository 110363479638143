import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { emphasize, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

import U2ProductLogo from "./U2ProductLogo";
import U2CloudAPI from "../../API/U2CloudAPI";
import CookieUtil from "../../Utils/CookieUtil";
import EncryptUtil from "../../Utils/EncryptUtil";
import { WINDOWSIZE } from "../../Enums/Enums";

function U2LabelerHeader(props) {
  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor = theme.palette.bgColor2;

    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.txtlight1,
      fontWeight: theme.typography.fontWeightRegular,
      "&:hover, &:focus": {
        backgroundColor: emphasize(backgroundColor, 0.3),
      },
      // '&:active': {
      // 	boxShadow: theme.shadows[1],
      // 	backgroundColor: emphasize(backgroundColor, 0.12),
      // },
    };
  }); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

  const _breadcrumbs = useSelector((state) => state.defaultReducer.breadcrumbs);

  /**
   * 로그인 사용자 정보
   * {
   *      memberId
   *      memberEmail
   *      teamId
   *      memberName
   *      cellNumber
   *      roleId
   *      isExpreience
   *      isMarketingConsent
   *      memberStatusId
   *      joinDate
   *      verifySendDate
   *      memberStatusText
   * }
   */
  const [_userInfo, setUserInfo] = useState({ memberName: "" });

  const navigate = useNavigate();

  useEffect(() => {
    U2CloudAPI.GetMyInfo().then((result) => {
      setUserInfo(result.data);
    });
  }, []);

  function breadcrumb_Click(link) {
    if (!!link) {
      navigate(link);
    }
  }

  function handlerLogoutButton_Cilck() {
    CookieUtil.fctRemoveCookie("accessToken");

    window.open(
      `${
        process.env.REACT_APP_PORTAL
      }/login?redirect=${EncryptUtil.fctEncodeToHex(window.location.href)}`,
      "_self"
    );
  }

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Box id={"Header"} sx={{ flexBasis: WINDOWSIZE.HEADERHEIGHT }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          width: "100vw",
          alignItems: "center",
          bgcolor: (theme) => theme.palette.bgColor1,
        }}
      >
        <U2ProductLogo sx={{ flexBasis: "200px" }} url={currentPath} />
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            flexGrow: 1,
            marginLeft: "20px",
            marginTop: "10px",
            color: (theme) => theme.palette.txtlight1,
          }}
        >
          {_breadcrumbs.map((item) => (
            <StyledBreadcrumb
              key={item.link}
              label={item.label}
              onClick={() => breadcrumb_Click(item.link)}
              sx={{
                bgcolor: (theme) => theme.palette.bgColor2,
                color: (theme) => theme.palette.txtlight2,
              }}
            />
          ))}
        </Breadcrumbs>
        <Box
          sx={{ color: (theme) => theme.palette.txtlight1, marginTop: "5px" }}
        >
          {_userInfo.memberName}
        </Box>
        <Button
          sx={{ flexBasis: "100px", marginTop: "10px" }}
          onClick={handlerLogoutButton_Cilck}
        >
          로그아웃
        </Button>
      </Box>
    </Box>
  );
}

export default U2LabelerHeader;
