import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import U2AuthComponent from "../../Components/Common/U2AuthComponent";

import { SOURCESTATUS_LOOKUPDATASOURCE, ACLCODE } from "../../Enums/Enums";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box, Tooltip } from "@mui/material";

import Lodash from "lodash";
import { useEffect } from "react";

function ImageSetManagerSourceImageSetList(props) {
  // function getSourceImageSetStatus(params) {
  //     let status = Lodash.find(SOURCESTATUS_LOOKUPDATASOURCE, (item) => item.code === params.value);
  //     return status.value;
  // }

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  const columns = [
    {
      field: "sourceImageSetId",
      headerName: "#",
      width: 60,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sourceImageSetName",
      headerName: "이름",
      width: 170,
      align: "left",
    },
    {
      field: "sourceImageSetTagId",
      headerName: "태그",
      width: 90,
      align: "left",
      type: "singleSelect",
      valueOptions: Lodash.map(props.tag, (item) => ({
        value: item.sourceImageSetTagId,
        label: item.tag,
      })),
    },
    {
      field: "sourceImageSetStatus",
      headerName: "상태",
      width: 90,
      align: "left",
      type: "singleSelect",
      // getOptionValue: (value) => value.code,
      // getOptionLabel: (value) => value.value,
      valueOptions: Lodash.map(SOURCESTATUS_LOOKUPDATASOURCE, (item) => ({
        value: item.code,
        label: item.value,
      })),
      // valueGetter: getSourceImageSetStatus
    },
    // {
    //   field: "rareCnt",
    //   headerName: "중요",
    //   width: 40,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       <Tooltip
    //         title={
    //           params.value === -1 ? (
    //             <Box color={"#999999"} fontSize={"1rem"}>
    //               이미지셋 분석 중입니다...
    //             </Box>
    //           ) : params.value === -2 ? (
    //             <Box color={"#999999"} fontSize={"1rem"}>
    //               이미지셋 분석에 실패했습니다.
    //               <br />
    //               계속 갱신되지 않는다면 관리자에게 문의해주세요.
    //             </Box>
    //           ) : (
    //             !!params.row.cellInfo && (
    //               <div>
    //                 {!!params.row.cellInfo &&
    //                   Lodash.map(params.row.cellInfo, (item) =>
    //                     !!item?.className ? (
    //                       <Box
    //                         key={item.className}
    //                         color={item.isRare ? "#FFFFFF" : "#999999"}
    //                         fontSize={"1rem"}
    //                       >
    //                         {item.className} : {item.CNT}
    //                       </Box>
    //                     ) : !!item?.modelName ? (
    //                       <Box
    //                         key={item.modelName}
    //                         color={"#999999"}
    //                         fontSize={"1rem"}
    //                       >
    //                         <hr />
    //                         {"사용 모델"} : {item.modelName}
    //                       </Box>
    //                     ) : (
    //                       ""
    //                     )
    //                   )}
    //               </div>
    //             )
    //           )
    //         }
    //         arrow
    //       >
    //         {params.value >= 30 ? (
    //           <span>
    //             <StarIcon />
    //           </span>
    //         ) : params.value >= 10 ? (
    //           <StarBorderIcon />
    //         ) : params.value === -1 ? (
    //           <MoreHorizIcon />
    //         ) : params.value === -2 ? (
    //           <ErrorOutlineIcon />
    //         ) : (
    //           <span />
    //         )}
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "memo",
      headerName: "메모",
      width: 100,
      headerAlign: "center",
      align: "left",
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Box>{params.value}</Box>
        </Tooltip>
      ),
    },
    {
      field: "tot",
      headerName: "전체",
      width: 100,
      headerAlign: "right",
      align: "right",
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "raw",
      headerName: "미진행",
      width: 100,
      headerAlign: "right",
      align: "right",
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "ing",
      headerName: "진행중",
      width: 100,
      headerAlign: "right",
      align: "right",
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "com",
      headerName: "검증대기",
      width: 100,
      headerAlign: "right",
      align: "right",
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "val",
      headerName: "검증완료",
      width: 100,
      headerAlign: "right",
      align: "right",
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "inv",
      headerName: "검증실패",
      width: 100,
      headerAlign: "right",
      align: "right",
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "rel",
      headerName: "배포",
      width: 100,
      headerAlign: "right",
      align: "right",
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "valLastDate",
      headerName: "이미지 검증완료일",
      width: 160,
      headerAlign: "center",
      align: "center",
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Tooltip title="소속 이미지의 검증완료일 중 가장 최근 일자">
          <Box>{"이미지 검증완료일"}</Box>
        </Tooltip>
      ),
    },
    {
      field: "X",
      headerName: "편집",
      width: 160,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (gridRenderCellParams) => (
        <Box>
          <U2AuthComponent aclId={ACLCODE.원시이미지셋_수정_실행}>
            <IconButton
              size="small"
              onClick={(e) =>
                props.onEditButton_Click(e, gridRenderCellParams.id)
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </U2AuthComponent>
          <U2AuthComponent aclId={ACLCODE.원시이미지셋_삭제_실행}>
            <IconButton
              size="small"
              onClick={(e) =>
                props.onDeleteButton_Click(e, gridRenderCellParams.id)
              }
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </U2AuthComponent>
          <U2AuthComponent aclId={ACLCODE.원시이미지셋_업로드_실행}>
            <IconButton
              size="small"
              onClick={(e) =>
                props.onUploadButton_Click(e, gridRenderCellParams.id)
              }
            >
              <CloudUploadOutlinedIcon fontSize="small" />
            </IconButton>
          </U2AuthComponent>
          <U2AuthComponent aclId={ACLCODE.원시이미지셋_업로드_실행}>
            <IconButton
              size="small"
              onClick={(e) =>
                props.onAnalysisButton_Click(e, gridRenderCellParams.id)
              }
            >
              <BarChartIcon fontSize="small" />
            </IconButton>
          </U2AuthComponent>
        </Box>
      ),
    },
  ];

  function handlerGetRowId(row) {
    return row.sourceImageSetId;
  }

  function handlerRowClick(gridRowParams) {
    props.onRow_Click(
      gridRowParams.row.projectId,
      gridRowParams.row.sourceImageSetId
    );
  }

  function handlerCellClick(gridCellParams, event) {
    if (
      gridCellParams.field === "sourceImageSetStatus" ||
      gridCellParams.field === "sourceImageSetTagId"
    ) {
      props.onRowCell_Click(event, gridCellParams.field, gridCellParams.row);
    }
  }

  // function handlerPaginationModelChange(pagination) {
  //     props.onDataGrid_QueryConditionChange({
  //         Page:{
  //             page:pagination.page,
  //             pageSize:pagination.pageSize
  //         }
  //     });
  // }

  // function handlerFilterModelChange(filter) {
  //     let field = (filter.items.length > 0) ? filter.items[0].field : '';
  //     let value = (filter.items.length > 0) ? filter.items[0].value : '';
  //     let operator = (filter.items.length > 0) ? filter.items[0].operator : '';

  //     props.onDataGrid_QueryConditionChange({
  //         Filter: {field:field, value:value, operator:operator}
  //     });
  // }

  // function handlerSortModelChange(sort) {
  //     props.onDataGrid_QueryConditionChange({
  //         Sort:{
  //             field:(sort.length > 0) ? sort[0].field : '',
  //             sort:(sort.length > 0) ? sort[0].sort : ''
  //         }
  //     });
  // }

  useEffect(() => {
    // console.log(props.sourceImageSets);
  }, [props.sourceImageSets]);

  return (
    <Box sx={{ marginTop: 2, height: `calc(100vh - 355px)` }}>
      {!!props.sourceImageSets && (
        <DataGrid
          slots={{ toolbar: QuickSearchToolbar }}
          scrollbarSize={1}
          rows={props.sourceImageSets}
          columns={columns}
          pageSizeOptions={[25, 50, 100]}
          disableRowSelectionOnClick
          columnVisibilityModel={{
            X: props.isEditable,
          }}
          checkboxSelection
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "sourceImageSetId",
                  sort: "desc",
                },
              ],
            },
            // pagination: { paginationModel: {page:0, pageSize:props.pageSize}},
          }}
          // paginationMode={'server'}
          // filterMode={'server'}
          // sortingMode={'server'}
          rowCount={props.sourceImageSets.length}
          getRowId={handlerGetRowId}
          onRowClick={handlerRowClick}
          onCellClick={handlerCellClick}
          onRowSelectionModelChange={props.onDataGrid_SelectionModelChange}
          // onPaginationModelChange={handlerPaginationModelChange}
          // onFilterModelChange={handlerFilterModelChange}
          // onSortModelChange={handlerSortModelChange}
        />
      )}
    </Box>
  );
}

export default ImageSetManagerSourceImageSetList;
