import { useState, useRef, useEffect } from "react";
import { Layer, Rect, Image, Text, Group } from "react-konva";
import DrawUtil from "../../Utils/DrawUtil";
import { WINDOWSIZE } from "../../Enums/Enums";

function U2AnnotationPreviewLayer(props) {
  const _layerRef = useRef(null);
  const _layerDraggable = true;
  const [_scale, setScale] = useState({ scale: 1, scaleBy: 0 });
  const _imageRef = useRef();
  const [_image, setImage] = useState({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    imageUrl: "",
    image: undefined,
  });

  const [_labelStrokeEnabled, setLabelStrokeEnabled] = useState(true);
  const [_labelFillEnabled, setLabelFillEnabled] = useState(true);

  /**************************************************************************************************************
   *
   * ImageAnnotatorBoardLabelingLayer props changed
   *
   **************************************************************************************************************/
  // props.scale changed
  useEffect(() => {
    setScale(props.scale);
  }, [props.scale]);

  // props.imageUrl changed
  useEffect(() => {
    if (!!props.imageInfo) {
      let imageSize = {
        width: props.imageInfo.width,
        height: props.imageInfo.height,
      };
      let stageSize = { width: 550, height: 480 };

      let newImagePosition = DrawUtil.fctCalcRectangleCoordinateForCenterStart(
        stageSize,
        imageSize
      );

      DrawUtil.fctGetImage(props.imageInfo.imageUrl).then((image) => {
        // console.log(image);

        // console.log({
        //     imageSize: imageSize,
        //     stageSize: stageSize,
        //     newImagePosition: newImagePosition,
        //     imageUrl: props.imageInfo.imageUrl,
        //     image: image,
        // });

        // console.log(
        //     {lx:_layerRef.current.x(), ly:_layerRef.current.y(), lw:_layerRef.current.width(), lh:_layerRef.current.height()}
        // )

        setImage({
          width: imageSize.width,
          height: imageSize.height,
          x: newImagePosition.x,
          y: newImagePosition.y,
          image: image,
        });
      });
    } else {
      setImage(null);
    }
  }, [props.imageInfo]);

  // _scale changed
  useEffect(() => {
    let scaleBy = _scale.scaleBy;
    let newScale = _scale.scale;
    let oldScale = _scale.scale - _scale.scaleBy;

    let layer;
    let layerInfo;
    let center;
    let centeredToMousePointer = true;
    let useAnimationEffect = false;

    if (!!scaleBy && scaleBy !== 0) {
      layer = _layerRef.current;

      layerInfo = {
        x: _layerRef.current.x(),
        y: _layerRef.current.y(),
        width: _layerRef.current.width(),
        height: _layerRef.current.height(),
      };

      let mousePosition = layer.getRelativePointerPosition();
      if (
        mousePosition.x >= _image.x &&
        mousePosition.x <= _image.x + _image.width &&
        mousePosition.y >= _image.y &&
        mousePosition.y <= _image.y + _image.height
      ) {
        // 마우스가 이미지 영역 안에 있을 때에는 마우스를 중심으로 확대
        centeredToMousePointer = true;
      } else {
        // 마우스가 이미지 영역 외에 있을 때에는 이미지를 중심으로 확대
        centeredToMousePointer = false;
      }

      if (centeredToMousePointer) {
        center = layer.getRelativePointerPosition();
      } else {
        center = { x: layerInfo.width / 2, y: layerInfo.height / 2 };
      }

      center = {
        x: oldScale * center.x + layerInfo.x,
        y: oldScale * center.y + layerInfo.y,
      };

      let newPosition = {
        x:
          layer.x() -
          ((center.x - layerInfo.x) / oldScale) * (newScale - oldScale),
        y:
          layer.y() -
          ((center.y - layerInfo.y) / oldScale) * (newScale - oldScale),
      };

      // console.log({
      //     centeredToMousePointer: centeredToMousePointer,
      //     newScale: newScale,
      //     oldScale: oldScale,
      //     layer: {x: layerInfo.x, y: layerInfo.y},
      //     center: center,
      //     newLayer: {...newPosition, width: layerInfo.width * newScale, height: layerInfo.height * newScale }
      // });

      if (useAnimationEffect) {
        // 스케일 변경 시 애니매이션 주기
        layer.to({
          x: newPosition.x,
          y: newPosition.y,
          scaleX: newScale,
          scaleY: newScale,
          duration: 0.1,
        });
      } else {
        // 스케일 변경 시 애니매이션 주지 않기
        layer.x(newPosition.x);
        layer.y(newPosition.y);
        layer.scaleX(newScale);
        layer.scaleY(newScale);
      }
    }
  }, [_scale, _image]);

  // 부모 뷰 에서 화면 중앙이동 버튼 클릭 시 처리
  useEffect(() => {
    let layer = _layerRef.current;

    let layerSize = {
      width: layer.width() * layer.scaleX(),
      height: layer.height() * layer.scaleY(),
    };
    let stageSize = { width: 550, height: 480 };
    let newLayerPosition = DrawUtil.fctCalcRectangleCoordinateForCenterStart(
      stageSize,
      layerSize
    );

    layer.x(newLayerPosition.x);
    layer.y(newLayerPosition.y);
  }, [props.moveToCenterTrigger]);

  useEffect(() => {
    setLabelStrokeEnabled(props.labelStrokeEnabled);
  }, [props.labelStrokeEnabled]);

  useEffect(() => {
    setLabelFillEnabled(props.labelFillEnabled);
  }, [props.labelFillEnabled]);

  /**************************************************************************************************************
   *
   * Event Handler
   *
   **************************************************************************************************************/
  function handleLayerWheel(e) {
    e.evt.preventDefault();

    fctZoom(e.evt.deltaY < 0);
  }

  function fctZoom(isZoomIn) {
    let oldScale = _scale.scale;
    let scaleBy = isZoomIn ? 0.2 : -0.2;
    let newScale =
      Math.round(Math.max(0.2, Math.min(4.1, oldScale + scaleBy)) * 10) / 10;
    if (newScale >= 0.3 && newScale <= 4) {
      setScale({ scale: newScale, scaleBy: scaleBy });
    }
  }

  /**************************************************************************************************************
   *
   * return
   *
   **************************************************************************************************************/
  return (
    <Layer
      ref={_layerRef}
      draggable={_layerDraggable}
      onWheel={handleLayerWheel}
    >
      <Rect
        id={"LabelingLayerBackground"}
        width={window.innerWidth * 5}
        height={window.innerHeight * 5}
        x={
          (window.innerWidth * -4 -
            WINDOWSIZE.SIDEWIDTH -
            WINDOWSIZE.STAGEHORZOFFSET) /
          2
        }
        y={
          (window.innerHeight * -4 -
            WINDOWSIZE.HEADERHEIGHT -
            WINDOWSIZE.STAGEVERTOFFSET) /
          2
        }
        fill={"#1D1D1D"}
        stroke={"#555555"}
        opacity={1.0}
      ></Rect>
      <Image ref={_imageRef} {..._image} />
      {!!props.annotations &&
        props.annotations.map((item) => (
          <Group key={item.annotationId}>
            <Rect
              id={String(item.annotationId)}
              {...item.boundingBox}
              x={item.boundingBox.x + _image.x}
              y={item.boundingBox.y + _image.y}
              fill={item.color}
              stroke={"#000000"}
              strokeWidth={1}
              opacity={0.3}
              strokeEnabled={_labelStrokeEnabled}
              fillEnabled={_labelFillEnabled}
            />
            <Text
              id={String(item.annotationId)}
              x={item.boundingBox.x + _image.x}
              y={item.boundingBox.y + _image.y}
              text={item.label}
            />
          </Group>
        ))}
    </Layer>
  );
}

export default U2AnnotationPreviewLayer;
