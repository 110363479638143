import axios from "axios";

var AxiosUtil =
  typeof exports === "undefined" ? function numeric() {} : exports;
if (typeof global !== "undefined") {
  global.AxiosUtil = AxiosUtil;
}

const instance = axios.create({
  baseURL: "/",
  validateStatus: (status) => status,
});

AxiosUtil.Get = function Get(service, url, params) {
  return instance
    .get(service + url, params)
    .then((res) => {
      return { status: res.status, data: res.data };
    })
    .catch((error) => {
      // 오류 처리
      if (error.code === "ECONNABORTED" || error.code === "ETIMEDOUT") {
        console.error("API 서버 문제", error.message);
      } else {
        console.error("오류가 발생했습니다:", error.message);
      }
      return { status: error.response?.status || 500, data: null }; // 오류 확인은 null 검사로
    });
};

AxiosUtil.Put = function Put(service, url, params) {
  // console.log(params)
  return instance
    .put(service + url, params, {
      headers: {
        "Content-Type": `application/json`,
      },
    })
    .then((res) => {
      return { status: res.status, data: res.data };
    })
    .catch((error) => {
      // 오류 처리
      if (error.code === "ECONNABORTED" || error.code === "ETIMEDOUT") {
        console.error("API 서버 문제", error.message);
      } else {
        console.error("오류가 발생했습니다:", error.message);
      }
      return { status: error.response?.status || 500, data: null }; // 오류 확인은 null 검사로
    });
};

AxiosUtil.Patch = function Patch(service, url, params) {
  // console.log(params)
  return instance
    .patch(service + url, params, {
      headers: {
        "Content-Type": `application/json`,
      },
    })
    .then((res) => {
      return { status: res.status, data: res.data };
    })
    .catch((error) => {
      // 오류 처리
      if (error.code === "ECONNABORTED" || error.code === "ETIMEDOUT") {
        console.error("API 서버 문제", error.message);
      } else {
        console.error("오류가 발생했습니다:", error.message);
      }
      return { status: error.response?.status || 500, data: null }; // 오류 확인은 null 검사로
    });
};

AxiosUtil.Post = function Post(service, url, params) {
  // console.log(params)
  return instance
    .post(service + url, params, {
      headers: {
        "Content-Type": `application/json`,
      },
    })
    .then((res) => {
      return { status: res.status, data: res.data };
    })
    .catch((error) => {
      // 오류 처리
      if (error.code === "ECONNABORTED" || error.code === "ETIMEDOUT") {
        console.error("API 서버 문제", error.message);
      } else {
        console.error("오류가 발생했습니다:", error.message);
      }
      return { status: error.response?.status || 500, data: null }; // 오류 확인은 null 검사로
    });
};

AxiosUtil.Delete = function Delete(service, url, params) {
  // console.log(params)
  return instance
    .delete(service + url, params, {
      headers: {
        "Content-Type": `application/json`,
      },
    })
    .then((res) => {
      return { status: res.status, data: res.data };
    })
    .catch((error) => {
      // 오류 처리
      if (error.code === "ECONNABORTED" || error.code === "ETIMEDOUT") {
        console.error("API 서버 문제", error.message);
      } else {
        console.error("오류가 발생했습니다:", error.message);
      }
      return { status: error.response?.status || 500, data: null }; // 오류 확인은 null 검사로
    });
};

export default AxiosUtil;
