import Lodash from "lodash";
import { SOURCESTATUS } from "../Enums/Enums";

class SourceImageSetEntity {
  keyColumn = "sourceImageSetId";

  // 기본값
  value = {
    sourceImageSetId: -1,
    sourceImageSetStatus: SOURCESTATUS.RAW,
    rareCnt: 0,
    cellInfo: null,
  };

  constructor(input, originList) {
    if (!!originList) {
      // 기존 값을 수정할 경우
      let origin = Lodash.find(
        originList,
        (item) => item[this.keyColumn] === input[this.keyColumn]
      );
      this.value = { ...origin, ...input };
    } else {
      // 신규로 생성할 경우
      this.value = { ...this.value, ...input };
    }

    this.keyValue = this.value[this.keyColumn];
  }
}

export default SourceImageSetEntity;
