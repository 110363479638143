import { useState } from "react";
import { useTheme } from "@emotion/react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import U2RoundedBox from "../../Components/Common/U2RoundedBox";
import U2AnnotationImageCanvasList from "./U2AnnotationImageCanvasList";

function U2AnnotationStatisticsDetailList(props) {
  const [tab, setTab] = useState(0);
  const theme = useTheme();

  // 셀 이미지 일괄조회 옵션용 state
  const [count, setCount] = useState("small");
  const [size, setSize] = useState("default");
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { field: "sourceImageSetName", headerName: "셋 이름", width: 140 },
    { field: "title", headerName: "이미지 이름", width: 210 },
    {
      field: "annotationCount",
      headerName: "어노테이션 수",
      width: 190,
      align: "right",
    },
  ];

  function handlerGetRowId(row) {
    return row.imageId;
  }

  function handlerRowClick(gridRowParams) {
    props.onRow_Click(
      gridRowParams.row.sourceImageSetId,
      gridRowParams.row.sourceImageSetName,
      gridRowParams.row.imageId,
      gridRowParams.row.title,
      props.labelInfo.labelId,
      props.labelInfo.label,
      gridRowParams.row.annotationCount
    );
  }

  return (
    <U2RoundedBox
      sx={{
        width: "595px",
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        bgcolor: (theme) => theme.palette.bgColor2,
      }}
    >
      <Box
        sx={{ flexDirection: "row", backgroundColor: theme.palette.bgColor2 }}
      >
        <Button
          sx={{ backgroundColor: tab === 0 ? theme.palette.bgColor3 : "" }}
          onClick={() => setTab(0)}
        >
          이미지 별 어노테이션 조회
        </Button>
        <Button
          sx={{ backgroundColor: tab === 1 ? theme.palette.bgColor3 : "" }}
          onClick={() => setTab(1)}
        >
          셀 이미지 일괄 조회
        </Button>
      </Box>
      <Box
        sx={{
          fontSize: "20px",
          padding: "5px 10px 0px 10px",
          height: "40px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: theme.palette.bgColor3,
        }}
      >
        {props.labelInfo.label}
        {tab === 1 && (
          <div>
            {/** 셀 이미지 일괄 조회용 옵션 창 */}
            <FormControl disabled={isLoading}>
              <InputLabel id="imageViewCount-label">개수</InputLabel>
              <Select
                labelId="imageViewCount-label"
                label="이미지 수"
                id="imageViewCount-select"
                value={count}
                size="small"
                onChange={(e) => {
                  setCount(e.target.value);
                }}
              >
                <MenuItem value={"micro"}>더 많이</MenuItem>
                <MenuItem value={"small"}>많이</MenuItem>
                <MenuItem value={"medium"}>보통</MenuItem>
                <MenuItem value={"large"}>적게</MenuItem>
              </Select>
            </FormControl>
            <FormControl disabled={isLoading}>
              <InputLabel id="imageSize-label">배율</InputLabel>
              <Select
                labelId="imageSize-label"
                label="이미지 크기"
                id="imageSize-select"
                value={size}
                size="small"
                onChange={(e) => {
                  setSize(e.target.value);
                }}
              >
                <MenuItem value={"small"}>작게</MenuItem>
                <MenuItem value={"default"}>보통</MenuItem>
                <MenuItem value={"large"}>크게</MenuItem>
                <MenuItem value={"original"}>원본</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </Box>
      {tab === 0 && (
        <Box
          sx={{
            height: "537px",
            paddingTop: 1,
            backgroundColor: theme.palette.bgColor3,
          }}
        >
          <DataGrid
            rows={props.annotationList}
            columns={columns}
            pageSize={1}
            rowsPerPageOptions={[5]}
            disableRowSelectionOnClick
            getRowId={handlerGetRowId}
            onRowClick={handlerRowClick}
          />
        </Box>
      )}
      {tab === 1 && (
        <Box
          sx={{
            height: "537px",
            paddingTop: 1,
            backgroundColor: theme.palette.bgColor3,
          }}
        >
          <U2AnnotationImageCanvasList
            labelId={props.labelInfo.labelId}
            width={595}
            height={537}
            viewSize={size}
            viewCount={count}
            onStatisticsChange={props.onStatisticsChange}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            showOnlyMyAnnotation={props.showOnlyMyAnnotation}
            tagId={props.tagId}
            category={props.annotationStatistics}
          />
        </Box>
      )}
    </U2RoundedBox>
  );
}

export default U2AnnotationStatisticsDetailList;
