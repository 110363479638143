import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Backdrop from "@mui/material/Backdrop";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";

import { snackbar_close } from "../../Store/ReduxSlice";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function U2LayoutBaseItem() {
  const _isBackdropOpen = useSelector(
    (state) => state.defaultReducer.isBackdropOpen
  );
  const _backdropProgressValue = useSelector(
    (state) => state.defaultReducer.backdropProgressValue
  );
  const _snackbar = useSelector((state) => state.defaultReducer.snackbar);

  const dispatch = useDispatch();

  const handlerSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(snackbar_close());
  };

  return (
    <>
      <Backdrop
        sx={{
          color: (theme) => theme.palette.objbackdrop,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={_isBackdropOpen}
      >
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress color="inherit" />
          {!!_backdropProgressValue && (
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {`${Math.round(_backdropProgressValue)}%`}
              </Typography>
            </Box>
          )}
        </Box>
      </Backdrop>
      <Snackbar
        open={_snackbar.open}
        autoHideDuration={2000}
        onClose={handlerSnackbarClose}
        message={_snackbar.message}
        // action={action}
      />
    </>
  );
}

export default U2LayoutBaseItem;
