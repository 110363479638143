import { createSlice } from "@reduxjs/toolkit";
import Lodash from "lodash";

export const reduxSlice = createSlice({
  name: "defaultRedux",
  initialState: {
    isBackdropOpen: false,
    snackbar: { open: false, message: "" },
    breadcrumbs: [],
    acl: [],
    myInfo: [],
  },
  reducers: {
    isBackdropOpen_set: (state, action) => {
      // action.payload
      state.isBackdropOpen = action.payload;
    },
    backdropProgressValue_set: (state, action) => {
      state.backdropProgressValue = action.payload;
    },
    snackbar_open: (state, action) => {
      // action.payload { message }
      state.snackbar = { open: true, message: action.payload };
    },
    snackbar_close: (state, action) => {
      state.snackbar = { open: false, message: "" };
    },
    breadcrumbs_set: (state, action) => {
      // action.payload [ {lable, link} ]
      state.breadcrumbs = action.payload;
    },
    acl_set: (state, action) => {
      state.acl = Lodash.map(action.payload, (item) => item.aclId);
    },
    acl_check: (state, action) => {
      state.snackbar = { open: true, message: "aaaa" };
    },
    myInfo_set: (state, action) => {
      state.myInfo = action.payload;
    },
  },
});

export const {
  isBackdropOpen_set,
  backdropProgressValue_set,
  snackbar_open,
  snackbar_close,
  breadcrumbs_set,
  acl_set,
  acl_check,
  myInfo_set,
} = reduxSlice.actions;

export default reduxSlice.reducer;
