import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Stack,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Lodash from "lodash";

import U2AnnotationStatisticsList from "./U2AnnotationStatisticsList";
import U2AnnotationStatisticsDetailList from "./U2AnnotationStatisticsDetailList";
import U2AnnotationPreviewDialog from "./U2AnnotationPreviewDialog";

import StatisticsAPI from "../../API/StatisticsAPI";

function U2AnnotationStatisticsDialog(props) {
  const [_annotationStatistics, setAnotationStatistics] = useState([]);
  const [_annotationList, setAnnotationList] = useState([]);
  const [_selectedLabelInfo, setSelectedLabelInfo] = useState({
    labelId: -1,
    label: "라벨을 선택하세요",
  });
  const [_isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [_selectedImageInfo, setSelectedImageInfo] = useState({});
  const [_showOnlyMyAnnotation, setShowOnlyMyAnnotation] = useState(false);
  const [_tags, setTags] = useState();
  const [_tag, setTag] = useState(0);
  const [_isBackdropOpen, setIsBackdropOpen] = useState(false);

  useEffect(() => {
    setTags([{ sourceImageSetTagId: 0, tag: "[모든 태그]" }, ...props.tag]);
  }, [props.tag]);

  useEffect(() => {
    setIsBackdropOpen(true);

    StatisticsAPI.GetLabelWithAnnotationCount(
      _showOnlyMyAnnotation,
      _tag,
      props.projectId
    ).then((res) => {
      setAnotationStatistics(res.data);
      setIsBackdropOpen(false);
    });
  }, [_showOnlyMyAnnotation, props.projectId, _tag]);

  function handlerAnnotationStatisticsListRow_Click(
    projectId,
    labelId,
    label,
    showOnlyMyAnnotation,
    tagId
  ) {
    StatisticsAPI.GetSourceImageWithAnnotationCountByLabelId(
      projectId,
      labelId,
      showOnlyMyAnnotation,
      tagId
    ).then((res) => {
      setAnnotationList(res.data);
      setSelectedLabelInfo({ labelId: labelId, label: label });
    });
  }

  function handlerAnnotationStatisticsDetailListRow_Click(
    sourceImageSetId,
    sourceImageSetName,
    imageId,
    title,
    labelId,
    label,
    annotationCount
  ) {
    setIsPreviewOpen(true);
    setSelectedImageInfo({
      sourceImageSetId: sourceImageSetId,
      sourceImageSetName: sourceImageSetName,
      imageId: imageId,
      title: title,
      labelId: labelId,
      label: label,
      annotationCount: annotationCount,
    });
  }

  function handlerAnnotationStatisticsChange(prevLabelId, labelId) {
    setAnotationStatistics(
      _annotationStatistics.map((statistics) => {
        if (statistics["labelId"] === prevLabelId) {
          statistics.annotationCount -= 1;
        } else if (statistics["labelId"] === labelId) {
          statistics.annotationCount += 1;
        }
        return statistics;
      })
    );
  }

  function handlerTag_OnChange(e) {
    setTag(e.target.value);
  }

  function handlerShowOnlyMyAnnotationCheckBox_Change(e) {
    setShowOnlyMyAnnotation(e.target.checked);
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.onClose("")}
        // fullWidth={true}
        maxWidth={"700px"}
      >
        <DialogTitle>
          <Stack direction={"row"}>
            <Box>어노테이션 현황</Box>
            <FormControl
              variant="standard"
              sx={{
                marginLeft: "auto",
                marginRight: "30px",
                marginTop: "3px",
                width: "130px",
              }}
            >
              {_tags && (
                <Select onChange={handlerTag_OnChange} value={_tag}>
                  {Lodash.map(_tags, (item) => (
                    <MenuItem
                      key={item.sourceImageSetTagId}
                      value={item.sourceImageSetTagId}
                    >
                      {item.tag}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={_showOnlyMyAnnotation}
                  onChange={handlerShowOnlyMyAnnotationCheckBox_Change}
                />
              }
              sx={{}}
              label="내 어노테이션만 보기"
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <U2AnnotationStatisticsList
                annotationStatistics={_annotationStatistics}
                onRow_Click={(labelId, label) =>
                  handlerAnnotationStatisticsListRow_Click(
                    props.projectId,
                    labelId,
                    label,
                    _showOnlyMyAnnotation,
                    _tag
                  )
                }
              />
            </Box>
            <Box sx={{ flexGrow: 1, marginLeft: "30px" }}>
              <U2AnnotationStatisticsDetailList
                labelInfo={_selectedLabelInfo}
                annotationList={_annotationList}
                onRow_Click={handlerAnnotationStatisticsDetailListRow_Click}
                onStatisticsChange={handlerAnnotationStatisticsChange}
                showOnlyMyAnnotation={_showOnlyMyAnnotation}
                tagId={_tag}
                annotationStatistics={_annotationStatistics}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose("CANCEL")}>닫기</Button>
        </DialogActions>
        {_isPreviewOpen && (
          <U2AnnotationPreviewDialog
            open={_isPreviewOpen}
            imageInfo={_selectedImageInfo}
            onClose={() => setIsPreviewOpen(false)}
          />
        )}
        <Backdrop
          sx={{
            color: (theme) => theme.palette.objbackdrop,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={_isBackdropOpen}
        >
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress color="inherit" />
          </Box>
        </Backdrop>
      </Dialog>
    </>
  );
}

export default U2AnnotationStatisticsDialog;
