import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

function U2ImageSetNameEditDialog(props) {
  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth="sm"
      onClose={props.onClose}
    >
      <DialogTitle>
        {props.isEditMode ? "원시이미지셋 이름 수정" : "원시이미지셋 추가"}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="원시이미지셋 이름"
          fullWidth
          variant="standard"
          value={props.value}
          onKeyDown={props.onKeyDown}
          onChange={props.onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose("OK")}>
          {props.isEditMode ? "수정" : "추가"}
        </Button>
        <Button onClick={() => props.onClose("CANCEL")}>취소</Button>
      </DialogActions>
    </Dialog>
  );
}

export default U2ImageSetNameEditDialog;
