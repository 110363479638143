export const IMAGEANNOTATIORBUTTONIDS = {
  TOOL_MOVE: "TOOL_MOVE",
  TOOL_BOUNDINGBOX: "TOOL_BOUNDINGBOX",
  TOOL_INTELLIBOX: "TOOL_INTELLIBOX",
  TOOL_INTELLILABEL: "TOOL_INTELLILABEL",
  TOOL_INTELLIMODEL: "TOOL_INTELLIMODEL",
  EDIT_DELETE: "EDIT_DELETE",
  // EDIT_SAVE: 'EDIT_SAVE',
  EDIT_UNDO: "EDIT_UNDO",
};

Object.freeze(IMAGEANNOTATIORBUTTONIDS);

export const SOURCESTATUS = {
  RAW: "RAW",
  ING: "ING",
  COMPLETE: "COM",
  VALID: "VAL",
  INVALID: "INV",
  RELEASE: "REL",
};

Object.freeze(SOURCESTATUS);

export const SOURCESTATUS_LOOKUPDATASOURCE = [
  { code: SOURCESTATUS.RAW, value: "미진행" },
  { code: SOURCESTATUS.ING, value: "진행중" },
  { code: SOURCESTATUS.COMPLETE, value: "검증대기" },
  { code: SOURCESTATUS.VALID, value: "검증완료" },
  { code: SOURCESTATUS.INVALID, value: "검증실패" },
  { code: SOURCESTATUS.RELEASE, value: "배포" },
];

Object.freeze(SOURCESTATUS_LOOKUPDATASOURCE);

export const DRAWMODE = {
  NONE: "NONE",
  READY: "READY",
  DRAWING: "DRAWING",
  TRANSFORM: "TRANSFORM",
};

Object.freeze(DRAWMODE);

export const WINDOWSIZE = {
  HEADERHEIGHT: 60,
  LEFTSIDEWIDTH: 200,
  RIGHTSIDEWIDTH: 150,
  CBCSIDEWIDTH:150,
  SIDEWIDTH: 500,
  STAGEHORZOFFSET: 145,
  STAGEVERTOFFSET: 40,
};

Object.freeze(WINDOWSIZE);

export const ANNOTATORIMAGELIST_POPUPMENU = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  RAW: "RAW",
  ING: "ING",
  COMPLETE: "COM",
  VALID: "VAL",
  INVALID: "INV",
  RELEASE: "REL",
};

Object.freeze(ANNOTATORIMAGELIST_POPUPMENU);

export const ACLCODE = {
  원시이미지셋_조회_실행: 15,
  원시이미지셋_추가_실행: 1,
  원시이미지셋_수정_실행: 2,
  원시이미지셋_삭제_실행: 3,
  원시이미지셋_상태변경_배포제외: 4,
  원시이미지셋_상태변경_배포: 5,
  원시이미지셋_업로드_실행: 19,
  원시이미지_추가_실행: 6,
  원시이미지_수정_실행: 7,
  원시이미지_삭제_실행: 8,
  원시이미지_상태변경_실행: 9,
  어노테이션_조회_실행: 16,
  어노테이션_통계_실행: 18,
  어노테이션_편집_실행: 10,
  어노테이션_삭제_실행: 11,
  어노테이션_인텔리_실행: 17,
  라벨_추가_실행: 12,
  라벨_수정_실행: 13,
  라벨_삭제_실행: 14,
};

Object.freeze(ACLCODE);
