import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import ClearIcon from "@mui/icons-material/Clear";

import Lodash from "lodash";

import U2RoundedBox from "../../Components/Common/U2RoundedBox";

function ImageSetManagerLabelList(props) {
  return Lodash.map(
    Lodash.uniqBy(props.labels, "category").sort((a, b) =>
      !a.category || a.category === "UNI" || a.category > b.category ? 1 : -1
    ),
    (category) => (
      <Box
        key={category.category}
        sx={{
          marginTop: "5px",
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          flexWrap: "wrap",
          // overflow: 'hidden',
          // overflowY: "scroll",
          // height:'62px',
        }}
      >
        <U2RoundedBox
          sx={{
            width: "50px",
            textAlign: "center",
            bgcolor: "#383838",
            border: "0px",
            borderColor: "#CCCCCC",
            margin: "3px",
            padding: "2px",
            cursor: props.labelSelectEnabled ? "pointer" : "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>{!category.category ? "UNI" : category.category}</Box>
        </U2RoundedBox>
        {props.labels
          .filter((item) => item.category === category.category)
          .map((item) => (
            <U2RoundedBox
              key={item.labelId}
              sx={{
                bgcolor: item.color,
                border:
                  props.selectedlabelId === item.labelId ? "2px solid" : "0px",
                borderColor: "#CCCCCC",
                margin: "3px",
                padding: props.selectedlabelId === item.labelId ? "0px" : "2px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: props.labelSelectEnabled ? "pointer" : "auto",
              }}
            >
              <Box
                sx={{ marginLeft: "4px" }}
                onClick={() =>
                  props.labelSelectEnabled &&
                  props.onLabel_Click(item.labelId, item.label)
                }
              >
                {item.label}
              </Box>
              {props.ShowLabelDeleteButton ? (
                <IconButton
                  size="small"
                  sx={{ marginLeft: "1px" }}
                  onClick={() => props.onLabelDeleteButton_Click(item.labelId)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ) : (
                <Box sx={{ width: "6px" }} />
              )}
            </U2RoundedBox>
          ))}
      </Box>
    )
  );
}

export default ImageSetManagerLabelList;
