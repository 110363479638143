import React from "react";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import ConvertUtil from "../../Utils/ConvertUtil";

function U2ImageInfoEditDialog(props) {
  const _formFields = [
    { id: "title", label: "이름" },
    { id: "specimenId", label: "검체등록번호" },
    { id: "scanDate", label: "스캔일자" },
    { id: "scanOption", label: "스캔설정" },
    { id: "stain", label: "염색정보" },
    { id: "memo", label: "메모" },
  ];

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth="sm"
      // onClose={props.onClose}
    >
      <Formik
        initialValues={{ ...props.imageInfo }}
        validationSchema={yup.object({
          title: yup.string("").required("필수"),
          specimenId: yup.string("").required("필수"),
          scanDate: yup.string("").required("필수"),
          // scanOption: yup.string('').required('필수'),
          // stain: yup.string('').required('필수'),
          status: yup.string("").required("필수"),
        })}
        onSubmit={(data, { setSubmitting }) => {
          console.log(data);
          setSubmitting(true);
          props.onSubmit(data);
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          helperText,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DialogTitle>
              {props.isEditMode
                ? "원시데이터 정보 수정"
                : "원시데이터 정보 등록"}
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    flexBasis: 170,
                    fontSize: "small",
                    marginTop: "28px",
                    paddingRight: "20px",
                    borderRight: "1px dashed #777777",
                    color: "rgb(77, 191, 253)",
                    textAlign: "right",
                  }}
                >
                  <img
                    src={props.imageInfo.image}
                    width="170px"
                    alt={props.imageInfo.fileName}
                  />
                  <Box sx={{ marginTop: "10px", color: "#BBBBBB" }}>파일명</Box>
                  <Box>{props.imageInfo.fileName}</Box>
                  <Box sx={{ marginTop: "10px", color: "#BBBBBB" }}>
                    등록일자
                  </Box>
                  <Box>{props.imageInfo.creationDate}</Box>
                  <Box sx={{ marginTop: "10px", color: "#BBBBBB" }}>용량</Box>
                  <Box>{parseInt(props.imageInfo.fileSize / 1024)} kb</Box>
                  <Box sx={{ marginTop: "10px", color: "#BBBBBB" }}>크기</Box>
                  <Box>{`(w X h) ${values.width} X ${values.height} pixel`}</Box>
                </Box>
                <Box sx={{ flexGlow: 1, marginLeft: "20px" }}>
                  <Field
                    margin="dense"
                    fullWidth
                    variant="standard"
                    as={TextField}
                    sx={{ display: "none" }}
                    name="sourceImageSetId"
                  />
                  <Field
                    margin="dense"
                    fullWidth
                    variant="standard"
                    as={TextField}
                    sx={{ display: "none" }}
                    name="imageId"
                  />
                  <Field
                    margin="dense"
                    fullWidth
                    variant="standard"
                    as={TextField}
                    sx={{ display: "none" }}
                    name="width"
                  />
                  <Field
                    margin="dense"
                    fullWidth
                    variant="standard"
                    as={TextField}
                    sx={{ display: "none" }}
                    name="height"
                  />
                  <Field
                    margin="dense"
                    fullWidth
                    variant="standard"
                    as={TextField}
                    sx={{ display: "none" }}
                    name="status"
                  />
                  {_formFields.map((item) =>
                    item.id === "scanDate" ? (
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        key={item.id}
                      >
                        <MuiDatePicker
                          value={values.scanDate}
                          label={item.label}
                          error={Boolean(touched[item.id] && errors[item.id])}
                          inputFormat="yyyy-MM-dd"
                          mask="____-__-__"
                          onChange={(value) =>
                            setFieldValue(
                              "scanDate",
                              ConvertUtil.DateToString(value),
                              true
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              // sx={{ width: '12rem', '& input': { paddingRight: '0' } }}
                              margin="dense"
                              fullWidth
                              variant="standard"
                              // label={item.label}
                              // name={item.id}
                              {...params}
                            />
                          )}
                          // {...props}
                        />
                      </LocalizationProvider>
                    ) : (
                      <Field
                        key={item.id}
                        margin="dense"
                        fullWidth
                        variant="standard"
                        as={TextField}
                        name={item.id}
                        label={item.label}
                        error={Boolean(touched[item.id] && errors[item.id])}
                        helperText={errors[item.id]}
                      />
                    )
                  )}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button type="submit" disabled={isSubmitting}>
                {props.isEditMode ? "수정" : "추가"}
              </Button>
              <Button onClick={() => props.onClose("CANCEL")}>취소</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
export default U2ImageInfoEditDialog;
