import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import {
  ACLCODE,
  SOURCESTATUS,
  SOURCESTATUS_LOOKUPDATASOURCE,
} from "../../Enums/Enums";

function U2ImageSetStatusEditDialog(props) {
  return (
    <Dialog open={props.open} onClose={() => props.onClose("")}>
      <DialogTitle>원시이미지셋 상태 수정</DialogTitle>
      <List sx={{ pt: 0 }}>
        {SOURCESTATUS_LOOKUPDATASOURCE.map(
          (item) =>
            // 배포상태로 변경 권한이 있는 경우에만 "배포" 버튼을 표시 함
            (item.code !== SOURCESTATUS.RELEASE ||
              props.onACLCheck(ACLCODE.원시이미지셋_상태변경_배포)) && (
              <ListItem disableGutters key={item.code}>
                <ListItemButton onClick={() => props.onClose(item.code)}>
                  <ListItemText primary={item.value} />
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
    </Dialog>
  );
}

export default U2ImageSetStatusEditDialog;
