import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import ReactCatcher from "react-catcher";

import CookieUtil from "./Utils/CookieUtil";
import EncryptUtil from "./Utils/EncryptUtil";
import U2CloudAPI from "./API/U2CloudAPI";

var isLogin = false;
var isSubscription = false;

async function fctLoad() {
  let devToken;
  let myInfo;
  let mySubscriptions;

  try {
    if (process.env.NODE_ENV === "development") {
      // 개발모드일 경우 test@u2bio.com 계정으로 자동 로그인
      // devToken = await U2CloudAPI.LoginForDevelop('test@u2bio.com');
      devToken = await U2CloudAPI.LoginForDevelop("smile@u2bio.com");

      CookieUtil.fctSetCookie("accessToken", devToken.data.token);
      myInfo = (await U2CloudAPI.GetMyInfo()).data;

      isLogin = true;
    } else {
      // 배포모드일 경우 accessToken 이 정상적인지 검사
      if (!!CookieUtil.fctGetCookie("accessToken")) {
        let result = await U2CloudAPI.GetMyInfo();

        if (result.status === 200) {
          isLogin = true;
          myInfo = result.data;
        } else {
          isLogin = false;
          CookieUtil.fctRemoveCookie("accessToken");
        }
      }
    }

    if (isLogin) {
      // 구독여부 확인
      mySubscriptions = (await U2CloudAPI.GetMySubscriptions()).data;

      if (!mySubscriptions || mySubscriptions.length === 0) {
        alert("U2이미지라벨러 구독신청이 필요합니다");
      } else {
        isSubscription = true;
      }
    }
  } catch {
    CookieUtil.fctRemoveCookie("accessToken");
    isLogin = false;
  } finally {
  }

  if (!isLogin) {
    // 로그인 하지 않은 경우 사용자 포털로 이동
    window.open(
      `${
        process.env.REACT_APP_PORTAL
      }/login?redirect=${EncryptUtil.fctEncodeToHex(window.location.href)}`,
      "_self"
    );
  } else if (!isSubscription) {
    // 구독신청하지 않은 경우 구독 신청 페이지로 이동
    window.open(
      `${process.env.REACT_APP_PORTAL}/service/subscription/edit`,
      "_self"
    );
  } else {
    const root = ReactDOM.createRoot(document.getElementById("root"));

    root.render(
      <React.StrictMode>
        <ReactCatcher
          fallback={<h1>Error!!</h1>}
          errorHandler={(e, info) => {
            const error_message = `error: ${e}, errorInfo: ${JSON.stringify(
              info
            )}`;
            console.log(error_message);
          }}
        >
          <App myInfo={myInfo} />
        </ReactCatcher>
      </React.StrictMode>
    );
  }
}

fctLoad();
