import { useState } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import EditIcon from "@mui/icons-material/Edit";

import U2NavigationBarColorButton from "./U2NavigationBarColorButton";

export default function U2NavigationBarColorButtonGroup(props) {
  const [_selectedButton, setSelectedButton] = useState();

  function handlerButtonClick(e) {
    if (e !== null) {
      setSelectedButton(e.target.id);
      props.onSelectedButtonChanged(Number(e.target.id));
    } else if (e === null) {
      setSelectedButton(null);
      props.onSelectedButtonChanged(null);
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          display={props.groupName !== "" ? "block" : "none"}
          sx={{ marginTop: 1, marginBottom: 1, fontWeight: "bold" }}
        >
          {props.groupName}
        </Box>
        <Box display={props.groupEditorEnabled ? "block" : "none"}>
          <IconButton size="small" onClick={props.onGroupEditorButton_Click}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {props._selectedLabelTabPage === 3 ? (
        <U2NavigationBarColorButton
          key={0}
          useColorButtonBackground={true}
          isSelected={false}
          color={"red"}
          text="라벨 초기화"
          onClick={() => handlerButtonClick(null)}
          labelId2={null}
          _selectedLabelTabPage={3}
        />
      ) : (
        <></>
      )}

      {props.buttons.map((item) => {
        if (item.id !== props._selectedLabelId) {
          return (
            <U2NavigationBarColorButton
              key={item.id}
              {...item}
              useColorButtonBackground={props.useColorButtonBackground}
              isSelected={
                !!_selectedButton && Number(_selectedButton) === item.id
              }
              onClick={handlerButtonClick}
              labelId2={props.annotation ? props.annotation.labelId2 : null}
              _selectedLabelTabPage={props._selectedLabelTabPage}
            />
          );
        }
        return null;
      })}
    </>
  );
}
