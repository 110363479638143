import Lodash from "lodash";

class AnnotationEntity {
  keyColumn = "annotationId";

  // 기본값
  value = {
    annotationId: -1,
    // teamId,
    // userId,
    // imageId,
    // labelId,
    labelId2: null,
    boundingBox: "",
  };

  constructor(input, originList) {
    if (!!originList) {
      // 기존 값을 수정할 경우
      let origin = Lodash.find(
        originList,
        (item) => item[this.keyColumn] === input[this.keyColumn]
      );
      this.value = { ...origin, ...input };
    } else {
      // 신규로 생성할 경우
      this.value = { ...this.value, ...input };
    }

    this.keyValue = this.value[this.keyColumn];
  }
}

export default AnnotationEntity;
