import { useState, useEffect, useCallback, useRef } from "react";
import Lodash from "lodash";
// import { useDispatch } from 'react-redux';
import Box from "@mui/material/Box";
import { Stack, TextField, Typography } from "@mui/material";
import { Stage } from "react-konva";

// import * as U2ReduxSlice from '../../Store/ReduxSlice';
import U2RoundedBox from "../../Components/Common/U2RoundedBox";
import U2AnnotationPreviewLayer from "../ImageSetManager/U2AnnotationPreviewLayer";
import AnnotatorAPI from "../../API/AnnotatorAPI";
import DrawUtil from "../../Utils/DrawUtil";

function Demo(props) {
  const [_labels, setLabels] = useState();
  const [_image, setImage] = useState();
  const [_annotations, setAnnotations] = useState([]);
  const [_chat, setChat] = useState("");
  const [_question, setQuestion] = useState();
  const [_answer, setAnswer] = useState();
  const [_tmpAnswer, setTmpAnswer] = useState([]);

  // const dispatch = useDispatch();
  const _uploadBoxRef = useRef();

  useEffect(() => {
    AnnotatorAPI.GetLabelByProjectId(48).then((res) => {
      setLabels(res.data.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1)));
    });

    // AnnotatorAPI.GetSourceImage(354, 66634).then(res => {
    //     if (res.data.length > 0) {
    //         DrawUtil.fctGetImage(res.data[0].image).then((image) => {
    //             setImage({
    //                 imageId: res.data[0].imageId,
    //                 imageUrl: res.data[0].image,
    //                 width: res.data[0].width,
    //                 height: res.data[0].height,
    //                 status: res.data[0].status,
    //                 // image: image,
    //             });
    //         });
    //     }
    // });
  }, []);

  useEffect(() => {
    const uploadBox = _uploadBoxRef.current;

    const handleFiles = (files) => {
      if (files.length === 1) {
        for (const file of files) {
          // console.log(file)
          if (!file.type.startsWith("image/")) continue;

          const reader = new FileReader();
          reader.onloadend = (e) => {
            const result = e.target.result;
            if (result) {
              DrawUtil.fctGetThumbnail(result, 80, 70).then((res) => {
                let imageInfo = {
                  imageId: -1,
                  status: "RAW",
                  imageUrl: result,
                  width: res.oriWidth,
                  height: res.oriHeight,
                };

                console.log(imageInfo);
                setAnnotations([]);
                setImage(imageInfo);
              });
            }
          };
          reader.readAsDataURL(file);
        }
      }
    };

    const dropHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const files = event.dataTransfer.files;
      handleFiles(files);
    };

    const dragOverHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    uploadBox.container().addEventListener("drop", dropHandler);
    uploadBox.container().addEventListener("dragover", dragOverHandler);

    return () => {
      uploadBox.container().removeEventListener("drop", dropHandler);
      uploadBox.container().removeEventListener("dragover", dragOverHandler);
    };
  }, []);

  function handlerChat_Change(e) {
    setChat(e.target.value);
  }

  function handlerChat_KeyDown(e) {
    if (e.key === "Enter") {
      fctInference();
    }
  }

  const fctInference = useCallback(() => {
    setQuestion(_chat);

    setAnswer("네, 분석하겠습니다. 잠시만 기다려주세요.");
    setChat("");
    fctIntelliBox(_image, _labels);
  }, [_chat, _image, _labels]);

  function fctIntelliBox(image, labels) {
    var keyincrement = 0;
    var startTime = new Date();

    AnnotatorAPI.GetIntelliAnnotation(
      "demo",
      "230602_U2L_001",
      -1,
      image.width,
      image.height,
      image.imageUrl
    ).then((res2) => {
      if (res2.data.result_code === "OK") {
        let entities = Lodash.map(res2.data.result, (inf) => {
          let label = Lodash.find(labels, (label) => label.label === inf.class);

          if (!!label) {
            return {
              annotationId: keyincrement--,
              imageId: -1,
              labelId: label.labelId,
              isIntelli: true,
              isManual: false,
              label: label.label,
              prob: Math.trunc(inf.prob * 100),
              color: label.color,
              boundingBox: DrawUtil.fctCalcRectangleCoordinate(
                Number(inf.x1),
                Number(inf.y1),
                inf.x2,
                inf.y2
              ),
            };
          } else {
            return {
              annotationId: keyincrement--,
              imageId: -1,
              labelId: -1,
              label: inf.class,
              isIntelli: true,
              isManual: false,
              prob: Math.trunc(inf.prob * 100),
              color: "#FFFF00",
              boundingBox: DrawUtil.fctCalcRectangleCoordinate(
                Number(inf.x1),
                Number(inf.y1),
                inf.x2,
                inf.y2
              ),
            };
          }
        });

        var endTime = new Date();

        let infTime = endTime - startTime;

        let count = entities.length;
        let classCount = Lodash.size(Lodash.uniqBy(entities, "label"));
        let countByClass = Lodash.countBy(entities, "label");
        let classes = Object.keys(countByClass);
        var confSum = 0;

        let answer =
          "\n분석에 " +
          infTime / 1000 +
          " 초가 소요되었습니다.\n이 이미지에서는 총 " +
          classCount +
          "종, " +
          count +
          "개의 혈구가 발견되었";

        if (count > 0) {
          answer = answer + "으며, 발견된 혈구는 다음과 같습니다.\n";

          for (var i = 0; i < classes.length; i++) {
            answer =
              answer +
              " - " +
              classes[i] +
              " " +
              countByClass[classes[i]] +
              "개\n";
            // if (i < classes.length - 1) {
            //     answer = answer + ', ';
            // } else {
            //     answer = answer + ' 입니다.';
            // }
          }

          for (i = 0; i < count; i++) {
            confSum = confSum + entities[i].prob;
          }

          answer =
            answer +
            " 평균 confidence 는 " +
            Math.round(confSum / count) / 100.0 +
            " 입니다.";
        } else {
          answer = answer + "습니다.\n";
        }

        setTimeout(function () {
          setAnnotations(entities);
          setTmpAnswer(answer.split(" "));
        }, 400);
      } else {
        // dispatch(U2ReduxSlice.snackbar_open('인퍼런스 중 오류가 발생하였습니다'));
      }

      // dispatch(U2ReduxSlice.isBackdropOpen_set(false));
    });
  }

  useEffect(() => {
    const timerId = setInterval(() => {
      const word = _tmpAnswer.shift();
      if (!!word) {
        setAnswer((state) => state + " " + word);
      }

      if (_tmpAnswer.length === 0) {
        clearTimeout(timerId);
      }
    }, 100);
  }, [_tmpAnswer]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <U2RoundedBox
          id="container"
          sx={{
            flexBasis: "50%",
            marginTop: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Box  sx={{backgroundColor:'#007700', width:'100%', height:'100%'}}> */}
          <Stage
            ref={_uploadBoxRef}
            container={"container"}
            width={550}
            height={480}
          >
            <U2AnnotationPreviewLayer
              id="LabelingLayer"
              scale={{ scale: 1, scaleBy: 0 }}
              imageInfo={_image}
              annotations={_annotations}
            ></U2AnnotationPreviewLayer>
          </Stage>
          {/* </Box> */}
        </U2RoundedBox>
        <U2RoundedBox
          sx={{
            flexBasis: "50%",
            marginTop: "2px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack sx={{ flexBasis: "60px" }}>
            {!!_question && (
              <Box>
                <Stack sx={{ color: "#77FFFF" }}>smile@u2bio.com</Stack>
                <Stack sx={{ marginTop: "3px" }}>{_question}</Stack>
              </Box>
            )}
          </Stack>
          <Stack sx={{ flexGrow: 1 }}>
            {!!_answer && _answer.length > 0 && (
              <Box>
                <Stack sx={{ color: "#77FF77" }}>U2AI</Stack>
                <Stack sx={{ marginTop: "3px" }}>
                  <Typography sx={{ whiteSpace: "pre-line" }}>
                    {_answer}
                  </Typography>
                </Stack>
              </Box>
            )}
          </Stack>
          <Stack sx={{ flexBasis: "30px" }}>
            <TextField
              fullWidth
              value={_chat}
              onChange={handlerChat_Change}
              onKeyDown={handlerChat_KeyDown}
            ></TextField>
          </Stack>
        </U2RoundedBox>
      </Box>
    </>
  );
}

export default Demo;
