import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import EditIcon from "@mui/icons-material/Edit";

import U2NavigationBarIconButton from "./U2NavigationBarIconButton";

export default function U2NavigationBarIconButtonGroup(props) {
  const [_selectedButton, setSelectedButton] = useState();

  useEffect(() => {
    setSelectedButton(props.selectedButtonId);
  }, [props.selectedButtonId]);

  function handlerButtonClick(e) {
    // asActRadioGroup = true 일 때에만 버튼이 선택된 것 처럼 보이도록 함
    if (props.actAsRadioGroup) {
      setSelectedButton(e.target.id);
      props.onSelectedButtonChanged(e, e.target.id);
    } else {
      props.onButtonClick(e.target.id);
    }
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          display={props.groupName !== "" ? "block" : "none"}
          sx={{ marginTop: 1, marginBottom: 1, fontWeight: "bold" }}
        >
          {props.groupName}
        </Box>
        <Box display={props.groupEditorEnabled ? "block" : "none"}>
          <IconButton size="small" onClick={props.onGroupEditorButton_Click}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {props.buttons.map(
        (item) =>
          item.enabled && (
            <U2NavigationBarIconButton
              key={item.id}
              tooltip={item.tooltip}
              {...item}
              isSelected={!!_selectedButton && _selectedButton === item.id}
              onClick={(e) => handlerButtonClick(e)}
            />
          )
      )}
    </>
  );
}
