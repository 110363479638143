import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as U2ReduxSlice from "../../Store/ReduxSlice";
import Lodash from "lodash";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import FilterCenterFocusIcon from "@mui/icons-material/FilterCenterFocus";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import UndoIcon from "@mui/icons-material/Undo";
// import SaveIcon from '@mui/icons-material/Save';
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import U2NavigationBarIconButtonGroup from "../../Components/NavationBars/U2NavigationBarIconButtonGroup";
import U2NavigationBarColorButtonGroup from "../../Components/NavationBars/U2NavigationBarColorButtonGroup";

import { ACLCODE, IMAGEANNOTATIORBUTTONIDS } from "../../Enums/Enums";
import U2ImageAnnotatorIntelliModelPopupMenu from "./U2ImageAnnotatorIntelliModelPopupMenu";
import U2AuthComponent from "../../Components/Common/U2AuthComponent";

import { useParams } from "react-router-dom";
import AnnotatorAPI from "../../API/AnnotatorAPI";

export default function U2ImageAnnotatortoolBox(props) {
  const [_toolButtons, setToolButtons] = useState([]);
  const [_modelName, setModelName] = useState("240912_U2L_001_v10");
  const [_popupMenuAnchor, setPopupMenuAnchor] = useState(null);
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [openMemo, setOpenMemo] = useState(false);
  const [_oldMemo, setOldMemo] = useState("");
  const [_memo, setMemo] = useState("");

  const _isOpenIntelliModelPopup = Boolean(_popupMenuAnchor);

  const { projectId, sourceImageSetId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    setToolButtons([
      {
        id: IMAGEANNOTATIORBUTTONIDS.TOOL_MOVE,
        icon: <OpenWithIcon />,
        text: "이동 (M)",
        enabled: true,
        tooltip: "",
      },
      {
        id: IMAGEANNOTATIORBUTTONIDS.TOOL_BOUNDINGBOX,
        icon: <CheckBoxOutlineBlankIcon />,
        text: "바운딩 박스 (B)",
        enabled: props.boundingBoxButtonEnabled,
        tooltip: "",
      },
      // {
      //     id:IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLIBOX,
      //     icon: <CheckBoxOutlineBlankIcon />,
      //     text: '인텔리 박스 (I)',
      //     enabled: props.intelliButtonEnabled,
      //     tooltip: ''
      // },
      {
        id: IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLILABEL,
        icon: <AutoAwesomeOutlinedIcon />,
        text: "인텔리 라벨 (L)",
        enabled: props.intelliButtonEnabled,
      },
      {
        id: IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLIMODEL,
        icon: <DashboardOutlinedIcon />,
        text: _modelName,
        enabled: props.intelliButtonEnabled,
        tooltip:
          _modelName !== "모델 선택"
            ? _modelName + " 모델이 선택되었습니다"
            : "모델을 선택하세요",
      },
    ]);
  }, [props.boundingBoxButtonEnabled, props.intelliButtonEnabled, _modelName]);

  const _editButtons = [
    {
      id: IMAGEANNOTATIORBUTTONIDS.EDIT_DELETE,
      icon: <DisabledByDefaultRoundedIcon />,
      text: "삭제 (Del)",
      enabled: true,
    },
    {
      id: IMAGEANNOTATIORBUTTONIDS.EDIT_UNDO,
      icon: <UndoIcon />,
      text: "실행 취소",
      enabled: true,
      disabled: props.isEditUndoDisabled,
    },
    // { id:IMAGEANNOTATIORBUTTONIDS.EDIT_SAVE, icon: <SaveIcon />, text: '저장', enabled: props.saveButtonEnabled },
  ];

  const [_selectedLabelTabPage, setSelectedLabelTabPage] = useState(0);

  const handleChange = (e, newValue) => {
    setSelectedLabelTabPage(newValue);
  };

  async function handlerToolButtonGroup_selectedButtonChanged(
    e,
    selectedToolButtonId,
    modelName
  ) {
    if (selectedToolButtonId === IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLIMODEL) {
      props.onToolButtonGroup_SelectedButtonChanged(selectedToolButtonId);
      setPopupMenuAnchor(e.currentTarget);
    } else if (
      selectedToolButtonId === IMAGEANNOTATIORBUTTONIDS.TOOL_INTELLILABEL
    ) {
      props.onToolButtonGroup_SelectedButtonChanged(
        selectedToolButtonId,
        modelName
      );
    } else {
      props.onToolButtonGroup_SelectedButtonChanged(selectedToolButtonId);
    }
  }

  function handlerIntelliModelPopupMenu_Close() {
    setPopupMenuAnchor(null);
  }

  function handlerIntelliModelPopupMenu_Click(modelName) {
    setModelName(modelName);
    setPopupMenuAnchor(null);
  }

  const handleClick = () => {
    if (open2) {
      setOpen2(!open2);
      setOpen(!open);
    }
    setOpen(!open);
  };

  const handleClick2 = () => {
    if (open) {
      setOpen(!open);
      setOpen2(!open2);
    }
    setOpen2(!open2);
  };

  useEffect(() => {
    setMemo(props.memo);
    setOldMemo(props.memo);
  }, [props.memo]);

  const handleTooltipOpen = () => {
    setOpenMemo(true);
  };

  function handlerCancelMemo() {
    // 확인을 눌렀을 때 실행할 코드
    setMemo(_oldMemo);
    setOpenMemo(false);
    if (_oldMemo !== _memo) {
      dispatch(U2ReduxSlice.snackbar_open("취소 되었습니다."));
    }
  }

  function handlerChangeMemo() {
    AnnotatorAPI.PatchSourceImageSet(projectId, sourceImageSetId, {
      memo: _memo,
    }).then((e) => {
      if (e.status === 200) {
        dispatch(U2ReduxSlice.snackbar_open("메모가 저장 되었습니다."));
        setOldMemo(_memo);
      } else {
        dispatch(U2ReduxSlice.snackbar_open("에러가 발생하였습니다."));
      }
    });
    setOpenMemo(false);
  }

  return (
    <List>
      <Tooltip
        placement="bottom-start"
        open={openMemo}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <Box
            sx={{
              width: "300px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              marginTop: 1,
            }}
          >
            <TextField
              id="outlined-basic"
              defaultValue={_memo}
              multiline
              fullWidth
              sx={{ marginBottom: 1 }}
              // disabled={openMemoText}
              onChange={(e) => setMemo(e.target.value)}
            />
            <Box>
              <Button onClick={handlerCancelMemo}>취소</Button>
              <Button onClick={handlerChangeMemo}>저장</Button>
            </Box>
          </Box>
        }
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            display={props.groupName !== "" ? "block" : "none"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#DDDDDD" : "000000",
              bgcolor: props.isSelected ? "#555555" : "#1D1D1D",
              textTransform: "none",
            }}
            startIcon={<EditIcon />}
            onClick={handleTooltipOpen}
          >
            메모 &nbsp;
            {/* <EditIcon fontSize="small" /> */}
          </Button>
        </Box>
      </Tooltip>
      {/* 도구 버튼 */}
      <U2NavigationBarIconButtonGroup
        groupName="도구"
        groupEditorEnabled={false}
        buttons={_toolButtons}
        actAsRadioGroup={true}
        selectedButtonId={props.selectedToolButtonId}
        onSelectedButtonChanged={(e, selectedToolButtonId) =>
          handlerToolButtonGroup_selectedButtonChanged(
            e,
            selectedToolButtonId,
            _modelName
          )
        }
      />

      {/* 줌 버튼 */}
      <ButtonGroup variant="outlined" sx={{ margin: 1 }}>
        <Button
          sx={{ width: 44, borderColor: "#AAAAAA", color: "#AAAAAA" }}
          onClick={() => props.onZoomButtonClick(1.2)}
        >
          <ZoomInIcon />
        </Button>
        <Button
          sx={{ width: 44, borderColor: "#AAAAAA", color: "#AAAAAA" }}
          onClick={() => props.onMoveToCenterButtonClick()}
        >
          <FilterCenterFocusIcon />
        </Button>
        <Button
          sx={{ width: 44, borderColor: "#AAAAAA", color: "#AAAAAA" }}
          onClick={() => props.onZoomButtonClick(0.8)}
        >
          <ZoomOutIcon />
        </Button>
      </ButtonGroup>

      {/* 줌 % */}
      <Box sx={{ textAlign: "center" }}>
        {Math.round(props.scale * 1000) / 10}%
      </Box>

      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

      {/* 삭제 버튼 */}
      <U2NavigationBarIconButtonGroup
        groupName=""
        groupEditorEnabled={false}
        buttons={_editButtons}
        actAsRadioGroup={false}
        onButtonClick={props.onEditButtonGroup_ButtonClick}
      />

      <Divider sx={{ marginTop: 1, marginBottom: 0 }} />

      <Box sx={{ height: `calc(100vh - 640px)` }}>
        <ListItemButton
          onClick={handleClick}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: 0,
            pr: 0,
          }}
        >
          {/* 라벨 헤더 */}
          <U2NavigationBarColorButtonGroup
            groupName="라벨"
            groupEditorEnabled={props.labelEditButtonEnabled}
            buttons={[]}
            onSelectedButtonChanged={
              props.onLabelButtonGroup_SelectedButtonChanged
            }
            onGroupEditorButton_Click={props.onLabelEditorButton_Click}
            _selectedLabelId={
              props._selectedAnnotation === null
                ? "not data"
                : props._selectedAnnotation.labelId2
            }
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          {/* 라벨 */}
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={_selectedLabelTabPage}
                onChange={handleChange}
                variant="fullWidth"
              >
                <Tab label="RBC" sx={{ fontSize: "small", minWidth: "0px" }} />
                <Tab label="WBC" sx={{ fontSize: "small", minWidth: "0px" }} />
                <Tab label="PLT" sx={{ fontSize: "small", minWidth: "0px" }} />
                <Tab label="OBJ" sx={{ fontSize: "small", minWidth: "0px" }} />
              </Tabs>
            </Box>
            <Box sx={{ height: `calc(100vh - 800px)`, overflowY: "scroll" }}>
              {(_selectedLabelTabPage === 0 ||
                _selectedLabelTabPage === 1 ||
                _selectedLabelTabPage === 2) && (
                <U2NavigationBarColorButtonGroup
                  groupName=""
                  groupEditorEnabled={false}
                  buttons={Lodash.map(
                    Lodash.filter(
                      props.labels,
                      (item) =>
                        item.category ===
                          (_selectedLabelTabPage === 0
                            ? "RBC"
                            : _selectedLabelTabPage === 1
                            ? "WBC"
                            : "PLT") ||
                        item.category === "UNI" ||
                        !item.category
                    ),
                    (item) => ({
                      id: item.labelId,
                      color: item.color,
                      text: item.label,
                    })
                  )}
                  useColorButtonBackground={true}
                  onSelectedButtonChanged={
                    props.onLabelButtonGroup_SelectedButtonChanged
                  }
                  _selectedLabelId={
                    props._selectedAnnotation === null
                      ? "not data"
                      : props._selectedAnnotation.labelId2
                  }
                />
              )}
              {_selectedLabelTabPage === 3 && (
                <U2NavigationBarColorButtonGroup
                  groupName=""
                  groupEditorEnabled={false}
                  buttons={props.annotations.map((item) => ({
                    id: item.annotationId,
                    color: item.color,
                    text: item.label,
                  }))}
                  useColorButtonBackground={false}
                  onSelectedButtonChanged={
                    props.onAnnotationButtonGroup_SelectedButtonChanged
                  }
                  _selectedLabelId={
                    props._selectedAnnotation === null
                      ? "not data"
                      : props._selectedAnnotation.labelId2
                  }
                />
              )}
            </Box>
          </Box>
        </Collapse>

        <Divider sx={{ marginTop: 0, marginBottom: 0 }} />

        <ListItemButton
          onClick={handleClick2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: 0,
            pr: 0,
          }}
        >
          {/* 라벨 헤더 */}
          <ListItemIcon
            sx={{ marginTop: 1, marginBottom: 1, fontWeight: "bold" }}
          >
            다중라벨
          </ListItemIcon>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title="Delete">
              <IconButton
                sx={{ padding: "5px" }}
                onClick={() => {
                  props.onLabelButtonGroup_SelectedButtonChanged2(null);
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {open2 ? <ExpandLess /> : <ExpandMore />}
          </Box>
        </ListItemButton>

        <Collapse in={open2} timeout="auto" unmountOnExit>
          {/* 라벨 */}
          <Box>
            <Box sx={{ height: `calc(100vh - 752px)`, overflowY: "scroll" }}>
              {(_selectedLabelTabPage === 0 ||
                _selectedLabelTabPage === 1 ||
                _selectedLabelTabPage === 2) && (
                <U2NavigationBarColorButtonGroup
                  groupName=""
                  groupEditorEnabled={false}
                  buttons={Lodash.map(
                    Lodash.filter(
                      props.labels,
                      (item) =>
                        item.category ===
                          (_selectedLabelTabPage === 0
                            ? "RBC"
                            : _selectedLabelTabPage === 1
                            ? "WBC"
                            : "PLT") ||
                        item.category === "UNI" ||
                        !item.category
                    ),
                    (item) => ({
                      id: item.labelId,
                      color: item.color,
                      text: item.label,
                    })
                  )}
                  useColorButtonBackground={true}
                  onSelectedButtonChanged={
                    props.onLabelButtonGroup_SelectedButtonChanged2
                  }
                  _selectedLabelId={
                    props._selectedAnnotation === null
                      ? "not data"
                      : props._selectedAnnotation.labelId
                  }
                />
              )}
              {_selectedLabelTabPage === 3 && (
                <U2NavigationBarColorButtonGroup
                  groupName=""
                  groupEditorEnabled={false}
                  buttons={props.annotations.map((item) => ({
                    id: item.annotationId,
                    color: item.color,
                    text: item.label,
                  }))}
                  useColorButtonBackground={false}
                  onSelectedButtonChanged={
                    props.onAnnotationButtonGroup_SelectedButtonChanged
                  }
                  _selectedLabelId={
                    props._selectedAnnotation === null
                      ? "not data"
                      : props._selectedAnnotation.labelId
                  }
                />
              )}
            </Box>
          </Box>
        </Collapse>

        <Divider sx={{ marginTop: 0, marginBottom: 2 }} />
      </Box>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              size="small"
              onChange={(e) =>
                props.onLabelStrokeEnableChanged(e.target.checked)
              }
            />
          }
          label="선"
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              size="small"
              onChange={(e) => props.onLabelFillEnableChanged(e.target.checked)}
            />
          }
          label="채우기"
        />
        <U2AuthComponent aclId={ACLCODE.어노테이션_인텔리_실행}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                size="small"
                onChange={(e) =>
                  props.onLabelManualEnableChanged(e.target.checked)
                }
              />
            }
            label="Manual"
          />
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                size="small"
                onChange={(e) =>
                  props.onLabelIntelliEnableChanged(e.target.checked)
                }
              />
            }
            label="Intelli"
          />
        </U2AuthComponent>
      </FormGroup>

      {!!_isOpenIntelliModelPopup && (
        <U2ImageAnnotatorIntelliModelPopupMenu
          open={_isOpenIntelliModelPopup}
          anchorEl={_popupMenuAnchor}
          intelliModels={props.intelliModels}
          onMenuClick={handlerIntelliModelPopupMenu_Click}
          onClose={handlerIntelliModelPopupMenu_Close}
        />
      )}
    </List>
  );
}
