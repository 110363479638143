import { Provider } from "react-redux";
import ReduxStore from "./Store/ReduxStore";

import "./App.css";

// import React, { useCallback, useEffect } from 'react'
// import MUITest from './Views/Tests/MUI';
import Layout from "./Views/Layout";

function App(props) {
  return (
    <Provider store={ReduxStore}>
      <Layout myInfo={props.myInfo} />
      {/* <DragAndDrop></DragAndDrop>
			<DrawRectangle></DrawRectangle>
			<MUITest></MUITest>
			<U2ImageAnnotatorImageList></U2ImageAnnotatorImageList> */}
    </Provider>
  );
}

export default App;
