import { useState, useEffect, forwardRef, useRef, useCallback } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { Stage, Layer, Rect } from "react-konva";

import * as U2ReduxSlice from "../../Store/ReduxSlice";
import U2ImageAnnotatorBoardGuideLayer from "./U2ImageAnnotatorBoardGuideLayer";
import U2ImageAnnotatorBoardLabelingLayer from "./U2ImageAnnotatorBoardLabelingLayer";
import { WINDOWSIZE } from "../../Enums/Enums";
import DrawUtil from "../../Utils/DrawUtil";

export const U2ImageAnnotatorBoard = forwardRef((props, ref) => {
  const [_selectedTool, setSelectedTool] = useState("TOOL_BOUNDINGBOX");
  const [_selectedLabel, setSelectedLabel] = useState("");
  const [_mousePosition, setMousePosition] = useState([0, 0]);
  const [_imageLayerScale, setImageLayerScale] = useState({
    scale: 1,
    scaleBy: 0.0,
  });
  const [_drawMode, setDrawMode] = useState("");
  const [_drawMousePosition, setDrawMousePosition] = useState({ x: 0, y: 0 });
  const [_drawStartPosition, setDrawStartPosition] = useState({ x: 0, y: 0 });
  const [_layer, setLayer] = useState({ x: 0, y: 0 });
  const [_stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [_moveToCenterTrigger, setMoveToCenterTrigger] = useState(1);
  const _scaleRef = useRef(props.scale);
  const _layerRef = useRef(_layer);
  const _refStage = useRef();

  const dispatch = useDispatch();

  // 선택된 라벨 변경 시 처리
  useEffect(() => {
    setSelectedLabel(props.selectedLabelButton);
    // console.log(props.selectedLabelButton)
  }, [props.selectedLabelButton]);

  // 선택된 툴 변경 시 처리
  useEffect(() => {
    setSelectedTool(props.selectedToolButton);
  }, [props.selectedToolButton]);

  // 마우스 이동 이벤트 발생 시 처리
  const handleStageMouseMove = (e) => {
    let position = e.target.getStage().getRelativePointerPosition();

    setMousePosition(position);

    // console.log(position)
  };

  // 부모 뷰 에서 확대 / 축소 버튼 클릭 시 처리
  useEffect(() => {
    setImageLayerScale(props.scale);
    _scaleRef.current = props.scale;
  }, [props.scale]);

  // 부모 뷰 에서 화면 중앙이동 버튼 클릭 시 처리
  useEffect(() => {
    setMoveToCenterTrigger(props.moveToCenterTrigger);
    setLayer({ x: 0, y: 0 });
  }, [props.moveToCenterTrigger]);

  // 창 크기 변경 시 스테이지 크기를 변경 START
  useEffect(() => {
    window.addEventListener("resize", fctFitStageIntoParentContainer);
    return () => {
      window.addEventListener("resize", fctFitStageIntoParentContainer);
    };
  }, []);

  useEffect(() => {
    _layerRef.current = _layer;
  }, [_layer]);

  useEffect(() => {
    fctFitStageIntoParentContainer();
  }, []);

  function fctFitStageIntoParentContainer() {
    setStageSize({
      width:
        window.innerWidth - WINDOWSIZE.SIDEWIDTH - WINDOWSIZE.STAGEHORZOFFSET,
      height:
        window.innerHeight -
        WINDOWSIZE.HEADERHEIGHT -
        WINDOWSIZE.STAGEVERTOFFSET,
    });
    // console.log({width: window.innerWidth, height: window.innerHeight})
  }
  // 창 크기 변경 시 스테이지 크기를 변경 END

  // 그리기 상태를 Guide Layer 에 표시 함
  function handlerLabelingLayerDataChanged(e) {
    let drawMode = _drawMode;
    let drawMousePosition = _drawMousePosition;
    let drawStartPosition = _drawStartPosition;

    if (e.drawMode !== undefined) {
      drawMode = e.drawMode;
    }

    if (e.mousePosition !== undefined && e.mousePosition !== null) {
      drawMousePosition = e.mousePosition;
    } else if (drawMousePosition === undefined) {
      drawMousePosition = { x: 0, y: 0 };
    }

    if (e.startPosition !== undefined && e.startPosition !== null) {
      drawStartPosition = e.startPosition;
    } else if (drawStartPosition === undefined) {
      drawStartPosition = { x: 0, y: 0 };
    }

    setDrawMode(drawMode);
    setDrawMousePosition(drawMousePosition);
    setDrawStartPosition(drawStartPosition);
  }

  // ctrl + c 클릭 시 이미지를 클립보드에 복사
  useHotkeys(
    "ctrl+c",
    () => {
      fctCaptureStage();
    },
    [props.imageInfo]
  );

  const fctCaptureStage = useCallback(() => {
    let imageSize = {
      width: _scaleRef.current.scale * props.imageInfo.width,
      height: _scaleRef.current.scale * props.imageInfo.height,
    };

    let OGwidth = props.imageInfo.width;
    let OGheight = props.imageInfo.height;

    let stageSize = {
      width:
        window.innerWidth - WINDOWSIZE.SIDEWIDTH - WINDOWSIZE.STAGEHORZOFFSET,
      height:
        window.innerHeight -
        WINDOWSIZE.HEADERHEIGHT -
        WINDOWSIZE.STAGEVERTOFFSET,
    };
    let newImagePosition = DrawUtil.fctCalcRectangleCoordinateForCenterStart(
      stageSize,
      imageSize
    );

    var img = new Image();

    img.onload = async function () {
      var canvas = document.createElement("canvas");
      canvas.width = Math.floor(imageSize.width);
      canvas.height = Math.floor(imageSize.height);

      var context = canvas.getContext("2d");
      context.drawImage(
        img,
        Math.floor(
          _scaleRef.current.scale * -newImagePosition.x -
            _layerRef.current.x -
            (_scaleRef.current.scale * (imageSize.width - OGwidth)) / 2
        ),
        Math.floor(
          _scaleRef.current.scale * -newImagePosition.y -
            _layerRef.current.y -
            (_scaleRef.current.scale * (imageSize.height - OGheight)) / 2
        )
      );

      const dataUrl = canvas.toDataURL();
      const blob = await (await fetch(dataUrl)).blob();
      const clipboardItem = new ClipboardItem({ "image/png": blob });

      await navigator.clipboard.write([clipboardItem]);

      dispatch(U2ReduxSlice.snackbar_open("클립보드에 이미지를 복사했습니다."));
    };

    img.src = _refStage.current.toDataURL();
  }, [
    _scaleRef,
    _layerRef,
    props.height,
    props.width,
    props.imageInfo,
    dispatch,
  ]);

  return (
    <div id="stage-parent">
      <div id="container">
        <Stage
          ref={_refStage}
          container={"container"}
          {..._stageSize}
          onMouseMove={handleStageMouseMove}
        >
          <Layer>
            <Rect
              width={window.innerWidth}
              height={window.innerHeight}
              x={0}
              y={0}
              fill={"#1D1D1D"}
            ></Rect>
          </Layer>
          <U2ImageAnnotatorBoardLabelingLayer
            id="LabelingLayer"
            scale={_imageLayerScale}
            setLayer={setLayer}
            setImageLayerScale={setImageLayerScale}
            selectedTool={_selectedTool}
            selectedLabel={_selectedLabel}
            imageInfo={props.imageInfo}
            labelStrokeEnabled={props.labelStrokeEnabled}
            labelFillEnabled={props.labelFillEnabled}
            labelManualEnabled={props.labelManualEnabled}
            labelIntelliEnabled={props.labelIntelliEnabled}
            moveToCenterTrigger={_moveToCenterTrigger}
            annotations={props.annotations}
            selectedAnnotation={props.selectedAnnotation}
            selectedBoundingBox={props.selectedBoundingBox}
            transformBoxes={props.transformBoxes}
            onDataChanged={handlerLabelingLayerDataChanged}
            onBoundingBoxAdded={props.onBoundingBoxAdded}
            onBoundingBoxClick={props.onBoundingBoxClick}
            onTransformBoxDragMove={props.onTransformBoxDragMove}
            onTransformBoxDragEnd={props.onTransformBoxDragEnd}
            onKeyDown={props.onKeyDown}
            onLayerWheel={props.onLayerWheel}
            onMoveToCenterEnd={props.onMoveToCenterEnd}
            onMoveToCenterButtonClick={props.onMoveToCenterButtonClick}
          />
          <U2ImageAnnotatorBoardGuideLayer
            guideLineVisible={_selectedTool === "TOOL_BOUNDINGBOX"}
            mousePosition={_mousePosition}
            drawMode={_drawMode}
            drawMousePosition={_drawMousePosition}
            drawStartPosition={_drawStartPosition}
          />
        </Stage>
      </div>
    </div>
  );
});

export default U2ImageAnnotatorBoard;
