import { useState, useRef, useEffect } from "react";
// import Konva from 'konva';
import { Layer, Rect, Image, Text, Group, Line } from "react-konva";
import DrawUtil from "../../Utils/DrawUtil";
import { DRAWMODE, WINDOWSIZE } from "../../Enums/Enums";

function U2ImageAnnotatorBoardLabelingLayer(props) {
  const DRAWINGINFOOFFSETX = 20;
  const DRAWINGINFOOFFSETY = 100;
  const TRANSFORMBOXCOLOR = "#FF0000";

  const _layerRef = useRef(null);
  const [_layerDraggable, setLayerDraggable] = useState(false);
  const [_layerPosition, setLayerPosition] = useState({ x: 0, y: 0 });
  const [_scale, setScale] = useState({ scale: 1, scaleBy: 0 });
  const _imageRef = useRef();
  const [_image, setImage] = useState({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
    imageUrl: "",
    image: undefined,
  });
  // const [_filter, setFilter] = useState()
  const [_drawMode, setDrawMode] = useState(DRAWMODE.NONE);
  const [_boundingBoxStartPosition, setBoundingBoxStartPosition] = useState({
    x: 0,
    y: 0,
  });
  const [_boundingBox, setBoundingBox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  /**************************************************************************************************************
   *
   * ImageAnnotatorBoardLabelingLayer props changed
   *
   **************************************************************************************************************/
  // props.scale changed
  useEffect(() => {
    setScale(props.scale);
  }, [props.scale]);

  // props.imageUrl changed
  useEffect(() => {
    if (!!props.imageInfo) {
      let imageSize = {
        width: props.imageInfo.width,
        height: props.imageInfo.height,
      };
      let stageSize = {
        width:
          window.innerWidth - WINDOWSIZE.SIDEWIDTH - WINDOWSIZE.STAGEHORZOFFSET,
        height:
          window.innerHeight -
          WINDOWSIZE.HEADERHEIGHT -
          WINDOWSIZE.STAGEVERTOFFSET,
      };

      let newImagePosition = DrawUtil.fctCalcRectangleCoordinateForCenterStart(
        stageSize,
        imageSize
      );

      // console.log({
      //     imageSize: imageSize,
      //     stageSize: stageSize,
      //     newImagePosition: newImagePosition,
      // })

      DrawUtil.fctGetImage(props.imageInfo.imageUrl).then((image) => {
        setImage({
          width: imageSize.width,
          height: imageSize.height,
          x: newImagePosition.x,
          y: newImagePosition.y,
          // imageUrl: props.imageInfo.imageUrl,
          image: image,
          // filters: [Konva.Filters.HSV],
          // hue: 100
        });
      });
    } else {
      setImage(null);
    }
  }, [props.imageInfo]);

  // props.selectedTool changed
  useEffect(() => {
    setLayerDraggable(props.selectedTool === "TOOL_MOVE");
  }, [props.selectedTool]);

  // _scale changed
  useEffect(() => {
    let scaleBy = _scale.scaleBy;
    let newScale = _scale.scale;
    let oldScale = _scale.scale - _scale.scaleBy;

    let layer;
    let layerInfo;
    let center;
    let centeredToMousePointer = true;
    let useAnimationEffect = false;

    if (!!scaleBy && scaleBy !== 0) {
      layer = _layerRef.current;

      layerInfo = {
        x: _layerRef.current.x(),
        y: _layerRef.current.y(),
        width: _layerRef.current.width(),
        height: _layerRef.current.height(),
      };

      let mousePosition = layer.getRelativePointerPosition();
      if (
        mousePosition.x >= _image.x &&
        mousePosition.x <= _image.x + _image.width &&
        mousePosition.y >= _image.y &&
        mousePosition.y <= _image.y + _image.height
      ) {
        // 마우스가 이미지 영역 안에 있을 때에는 마우스를 중심으로 확대
        centeredToMousePointer = true;
      } else {
        // 마우스가 이미지 영역 외에 있을 때에는 이미지를 중심으로 확대
        centeredToMousePointer = false;
      }

      if (centeredToMousePointer) {
        center = layer.getRelativePointerPosition();
      } else {
        center = { x: layerInfo.width / 2, y: layerInfo.height / 2 };
      }

      center = {
        x: oldScale * center.x + layerInfo.x,
        y: oldScale * center.y + layerInfo.y,
      };

      let newPosition = {
        x:
          layer.x() -
          ((center.x - layerInfo.x) / oldScale) * (newScale - oldScale),
        y:
          layer.y() -
          ((center.y - layerInfo.y) / oldScale) * (newScale - oldScale),
      };

      // console.log({
      //     centeredToMousePointer: centeredToMousePointer,
      //     newScale: newScale,
      //     oldScale: oldScale,
      //     layer: {x: layerInfo.x, y: layerInfo.y},
      //     center: center,
      //     newLayer: {...newPosition, width: layerInfo.width * newScale, height: layerInfo.height * newScale }
      // });

      if (useAnimationEffect) {
        // 스케일 변경 시 애니매이션 주기
        layer.to({
          x: newPosition.x,
          y: newPosition.y,
          scaleX: newScale,
          scaleY: newScale,
          duration: 0.1,
        });
      } else {
        // 스케일 변경 시 애니매이션 주지 않기
        layer.x(newPosition.x);
        layer.y(newPosition.y);
        layer.scaleX(newScale);
        layer.scaleY(newScale);
      }

      setLayerPosition(newPosition);
      props.setLayer(newPosition);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_scale]);

  // 부모 뷰 에서 화면 중앙이동 버튼 클릭 시 처리 --> 잘 안되서 보류
  useEffect(() => {
    let layer = _layerRef.current;

    let layerSize = {
      width: layer.width() * layer.scaleX(),
      height: layer.height() * layer.scaleY(),
    };
    let stageSize = {
      width:
        window.innerWidth - WINDOWSIZE.SIDEWIDTH - WINDOWSIZE.STAGEHORZOFFSET,
      height:
        window.innerHeight -
        WINDOWSIZE.HEADERHEIGHT -
        WINDOWSIZE.STAGEVERTOFFSET,
    };
    let newLayerPosition = DrawUtil.fctCalcRectangleCoordinateForCenterStart(
      stageSize,
      layerSize
    );

    layer.x(newLayerPosition.x);
    layer.y(newLayerPosition.y);

    setLayerPosition(newLayerPosition);
    props.setLayer(newLayerPosition);
  }, [props.moveToCenterTrigger]);

  // 바운딩 박스 선택 해제 시 DRAWING MODE 변경 (NONE)
  useEffect(() => {
    if (props.selectedAnnotation === null) {
      setDrawMode(DRAWMODE.NONE);
    }
  }, [props.selectedAnnotation]);

  /**************************************************************************************************************
   *
   * Event Handler
   *
   **************************************************************************************************************/
  function handleLayerMouseDown(e) {
    // console.log('handleLayerMouseDown');

    let drawMode = _drawMode;
    let startPosition = undefined;

    if (e.target === _imageRef.current) {
      drawMode = DRAWMODE.NONE;
    }

    if (props.selectedTool === "TOOL_BOUNDINGBOX") {
      if (_drawMode !== DRAWMODE.TRANSFORM && !!props.selectedLabel) {
        startPosition = _layerRef.current.getRelativePointerPosition();

        setBoundingBoxStartPosition(startPosition);
        drawMode = DRAWMODE.READY;

        // console.log( { postion: position, isDrawing: true} )
      }
    }

    setDrawMode(drawMode);

    if (startPosition !== undefined) {
      props.onDataChanged({ drawMode: drawMode, startPosition: startPosition });
    } else {
      props.onDataChanged({ drawMode: drawMode });
    }

    if (drawMode !== DRAWMODE.TRANSFORM) {
      props.onBoundingBoxClick();
    }
  }

  function handleLayerMouseMove(e) {
    let drawMode = _drawMode;
    let position = _layerRef.current.getRelativePointerPosition();

    if (_drawMode === DRAWMODE.READY || _drawMode === DRAWMODE.DRAWING) {
      if (
        position.x !== _boundingBoxStartPosition.x ||
        position.y !== _boundingBoxStartPosition.y
      ) {
        // console.log( { postion: position, _boundingBoxStartPosition: _boundingBoxStartPosition, x: true} )
        drawMode = DRAWMODE.DRAWING;
        setDrawMode(drawMode);
      }

      let boundingBox = DrawUtil.fctCalcRectangleCoordinate(
        _boundingBoxStartPosition.x,
        _boundingBoxStartPosition.y,
        position.x,
        position.y,
        _imageRef.current.position(),
        _imageRef.current.size()
      );
      setBoundingBox(boundingBox);
      // console.log(boundingBox);
    }

    props.onDataChanged({ drawMode: drawMode, mousePosition: position });
  }

  function handleLayerMouseUp(e) {
    // console.log('setIsDrawing (false)')

    if (
      props.selectedTool === "TOOL_BOUNDINGBOX" &&
      _drawMode === DRAWMODE.DRAWING
    ) {
      fctAddBoundingBox();
    }

    if (_drawMode === DRAWMODE.READY || _drawMode === DRAWMODE.DRAWING) {
      setDrawMode(DRAWMODE.NONE);
      props.onDataChanged({ drawMode: DRAWMODE.NONE });
    }
  }

  function handleLayerWheel(e) {
    e.evt.preventDefault();

    if (!!props.imageInfo) {
      props.onLayerWheel(e);
    }
  }

  function handleBoundingBoxClick(e) {
    // console.log('handleBoundingBoxClick');
    if (_drawMode !== DRAWMODE.DRAWING) {
      setDrawMode(DRAWMODE.TRANSFORM);

      props.onBoundingBoxClick(Number(e.target.id()));
      props.onDataChanged({ drawMode: DRAWMODE.TRANSFORM });
    }
  }

  /**************************************************************************************************************
   *
   * Function
   *
   **************************************************************************************************************/
  function fctAddBoundingBox() {
    if (!!_boundingBox) {
      // 그려진 바운딩박스의 크기가 0보다 크고 동일한 영역이 어노테이션 목록에 없을 때 추가 함
      if (_boundingBox.width > 20 && _boundingBox.height > 20) {
        // let exists = props.annotations.some((item) => item.boundingBox === _boundingBox);
        // if (!exists) {
        props.onBoundingBoxAdded({
          ..._boundingBox,
          x: _boundingBox.x - _image.x,
          y: _boundingBox.y - _image.y,
        });
        // }
      }
    }
  }

  const handleDragEnd = (e) => {
    const newX = e.target;
    const newY = e.target;

    // 이미지가 영역을 벗어나는지 검사
    // 왼쪽 경계를 벗어났을 경우
    if (
      -newX.canvas.width / 2 >
      newX._lastPos.x -
        (newX.width() - (newX.width() - props.imageInfo.width) * _scale.scale) /
          2
    ) {
      props.onMoveToCenterButtonClick();

      // 오른쪽 경계를 벗어났을 경우
    } else if (
      newX.canvas.width / 2 <
      newX._lastPos.x -
        (newX.width() - (newX.width() + props.imageInfo.width) * _scale.scale) /
          2
    ) {
      props.onMoveToCenterButtonClick();
    }

    // 위쪽 경계를 벗어났을 경우
    if (
      -newY.canvas.height / 2 >
      newY._lastPos.y -
        (newY.height() -
          (newY.height() - props.imageInfo.height) * _scale.scale) /
          2
    ) {
      props.onMoveToCenterButtonClick();

      // 아래쪽 경계를 벗어났을 경우
    } else if (
      newY.canvas.height / 2 <
      newY._lastPos.y -
        (newY.height() -
          (newY.height() + props.imageInfo.height) * _scale.scale) /
          2
    ) {
      props.onMoveToCenterButtonClick();
    }

    // 이미지의 새로운 위치를 저장
    // setLayerPosition({ x: e.target.x(0), y: e.target.y(0) });
    // props.setLayer({ x: e.target.x(0), y: e.target.y(0) });
    // props.onMoveToCenterButtonClick();
  };

  function handleLayerDragmove(e) {
    setLayerPosition(e.target.position());
    props.setLayer(e.target.position());
  }

  function handlerTransformBox_DragMove(e) {
    props.onTransformBoxDragMove(e, { x: _image.x, y: _image.y });
  }

  function handlerTransformBox_MouseOver(e) {
    let id = e.target.id();

    if (id === "1" || id === "5") {
      document.body.style.cursor = "nwse-resize";
    }
    if (id === "3" || id === "7") {
      document.body.style.cursor = "nesw-resize";
    } else if (id === "2" || id === "6") {
      document.body.style.cursor = "ns-resize";
    } else if (id === "4" || id === "8") {
      document.body.style.cursor = "ew-resize";
    }
  }

  function handlerTransformBox_MouseOut(e) {
    document.body.style.cursor = "default";
  }

  function handlerAnnotation_MouseOver(e) {
    document.body.style.cursor = "pointer";
  }

  function handlerAnnotation_MouseOut(e) {
    document.body.style.cursor = "default";
  }

  // useEffect(() =>{
  //     console.log(props.annotations)
  // }, [props.annotations])

  // useEffect(() =>{
  //     console.log(props.labelIntelliEnabled)
  // }, [props.labelIntelliEnabled])

  // useEffect(() =>{
  //     console.log(props.labelManualEnabled)
  // }, [props.labelManualEnabled])

  useEffect(() => {
    if (_image) {
      _imageRef.current.cache();
    }
  }, [_image]);

  /**************************************************************************************************************
   *
   * return
   *
   **************************************************************************************************************/
  return (
    <Layer
      ref={_layerRef}
      draggable={_layerDraggable}
      onMouseDown={handleLayerMouseDown}
      onMouseMove={handleLayerMouseMove}
      onMouseUp={handleLayerMouseUp}
      onWheel={handleLayerWheel}
      onDragMove={handleLayerDragmove}
      onDragEnd={handleDragEnd}
    >
      <Rect
        id={"LabelingLayerBackground"}
        width={window.innerWidth * 5}
        height={window.innerHeight * 5}
        x={
          (window.innerWidth * -4 -
            WINDOWSIZE.SIDEWIDTH -
            WINDOWSIZE.STAGEHORZOFFSET) /
          2
        }
        y={
          (window.innerHeight * -4 -
            WINDOWSIZE.HEADERHEIGHT -
            WINDOWSIZE.STAGEVERTOFFSET) /
          2
        }
        fill={"#1D1D1D"}
        stroke={"#555555"}
        opacity={1.0}
      ></Rect>
      <Image ref={_imageRef} {..._image}></Image>
      <Rect
        id={"DrawingBoxLine"}
        visible={_boundingBox.width > 0 && _drawMode === DRAWMODE.DRAWING}
        {..._boundingBox}
        stroke={"#000000"}
        strokeWidth={1}
        opacity={0.3}
      ></Rect>
      {!!_image &&
        props.annotations.map(
          (item) =>
            ((item.isManual && props.labelManualEnabled) ||
              (item.isIntelli && props.labelIntelliEnabled)) && (
              <Group key={item.annotationId}>
                <Rect
                  id={String(item.annotationId)}
                  {...item.boundingBox}
                  x={item.boundingBox.x + _image.x}
                  y={item.boundingBox.y + _image.y}
                  fill={item.color}
                  stroke={"#000000"}
                  strokeWidth={1}
                  opacity={0.3}
                  strokeEnabled={props.labelStrokeEnabled}
                  fillEnabled={props.labelFillEnabled}
                  onClick={handleBoundingBoxClick}
                  onMouseOver={handlerAnnotation_MouseOver}
                  onMouseOut={handlerAnnotation_MouseOut}
                />
                <Text
                  id={String(item.annotationId)}
                  x={item.boundingBox.x + _image.x}
                  y={item.boundingBox.y + _image.y}
                  text={item.label + (!!item.prob ? ` (${item.prob}%)` : "")}
                />
                <Text
                  id={String(item.annotationId)}
                  x={item.boundingBox.x + _image.x}
                  y={item.boundingBox.y + _image.y + 12}
                  text={item.label2 !== null ? item.label2 : ""}
                />
              </Group>
            )
        )}
      <Group>
        <Line
          points={props.transformBoxes.lines.map((item, index) =>
            index % 2 === 0 ? item + _image.x : item + _image.y
          )}
          stroke={TRANSFORMBOXCOLOR}
          strokeWidth={1}
        />
        {props.transformBoxes.boxes.map((item) => (
          <Rect
            key={item.key}
            id={item.key}
            {...item.box}
            x={item.box.x + _image.x}
            y={item.box.y + _image.y}
            stroke={TRANSFORMBOXCOLOR}
            strokeWidth={1}
            fill={TRANSFORMBOXCOLOR}
            draggable
            onMouseOver={handlerTransformBox_MouseOver}
            onMouseOut={handlerTransformBox_MouseOut}
            onDragMove={handlerTransformBox_DragMove}
            onDragEnd={props.onTransformBoxDragEnd}
          />
        ))}
      </Group>
      {false && process.env.NODE_ENV === "development" && (
        <Group>
          <Text
            x={DRAWINGINFOOFFSETX}
            y={DRAWINGINFOOFFSETY}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={"Layer Location"}
          ></Text>
          <Text
            x={DRAWINGINFOOFFSETX}
            y={DRAWINGINFOOFFSETY + 20}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={"Layer Size"}
          ></Text>
          <Text
            x={DRAWINGINFOOFFSETX}
            y={DRAWINGINFOOFFSETY + 40}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={"Scale"}
          ></Text>
          <Text
            x={DRAWINGINFOOFFSETX}
            y={DRAWINGINFOOFFSETY + 60}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={"ImageInfo"}
          ></Text>
          <Text
            x={DRAWINGINFOOFFSETX}
            y={DRAWINGINFOOFFSETY + 80}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={"Selected BB"}
          ></Text>
          <Text
            x={DRAWINGINFOOFFSETX}
            y={DRAWINGINFOOFFSETY + 100}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={"Bounding Boxes"}
          ></Text>

          <Text
            x={DRAWINGINFOOFFSETX + 110}
            y={DRAWINGINFOOFFSETY}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={
              !!_layerPosition ? _layerPosition.x + " " + _layerPosition.y : ""
            }
          ></Text>
          <Text
            x={DRAWINGINFOOFFSETX + 110}
            y={DRAWINGINFOOFFSETY + 20}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={""}
          ></Text>
          <Text
            x={DRAWINGINFOOFFSETX + 110}
            y={DRAWINGINFOOFFSETY + 40}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={_scale.scale}
          ></Text>
          <Text
            x={DRAWINGINFOOFFSETX + 110}
            y={DRAWINGINFOOFFSETY + 60}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={JSON.stringify(
              !!_image
                ? {
                    x: _image.x,
                    y: _image.y,
                    w: _image.width,
                    h: _image.height,
                  }
                : ""
            )}
          ></Text>
          <Text
            x={DRAWINGINFOOFFSETX + 110}
            y={DRAWINGINFOOFFSETY + 80}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={
              !!props.selectedBoundingBox
                ? JSON.stringify(props.selectedBoundingBox)
                : ""
            }
          ></Text>
          <Text
            x={DRAWINGINFOOFFSETX + 110}
            y={DRAWINGINFOOFFSETY + 100}
            fontSize={14}
            fill={"#FFFFFF"}
            opacity={0.3}
            width={300}
            text={JSON.stringify(
              props.annotations.map((item) => item.boundingBox)
            ).replace(/},/gi, "}\r\n")}
          ></Text>
        </Group>
      )}
    </Layer>
  );
}

export default U2ImageAnnotatorBoardLabelingLayer;
