import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  DialogContent,
  DialogActions,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import Lodash from "lodash";

import { Stage } from "react-konva";
import U2AnnotationPreviewLayer from "./U2AnnotationPreviewLayer";
import AnnotatorAPI from "../../API/AnnotatorAPI";
import DrawUtil from "../../Utils/DrawUtil";
import StatisticsAPI from "../../API/StatisticsAPI";

function U2AnnotationPreviewDialog(props) {
  const [_image, setImage] = useState();
  const [_annotations, setAnnotations] = useState();
  const [_isShowOtherAnnotation, setIsShowOtherAnnotation] = useState(true);

  useEffect(() => {
    AnnotatorAPI.GetSourceImage(
      props.imageInfo.sourceImageSetId,
      props.imageInfo.imageId
    ).then((res) => {
      if (res.data.length > 0) {
        DrawUtil.fctGetImage(res.data[0].image).then((image) => {
          setImage({
            imageId: res.data[0].imageId,
            imageUrl: res.data[0].image,
            width: res.data[0].width,
            height: res.data[0].height,
            status: res.data[0].status,
            // image: image,
          });
        });

        AnnotatorAPI.GetAnnotation(0, props.imageInfo.imageId).then((res) => {
          // console.log(res.data)
          setAnnotations(
            Lodash.map(res.data, (item) => ({
              ...item,
              boundingBox: JSON.parse(item.boundingBox),
            }))
          );
        });
        // StatisticsAPI.GetAnnotationBySourceImageIdAndLabelId(props.imageInfo.imageId, props.imageInfo.labelId).then(res => {
        //     setAnnotations(Lodash.map(res.data, (item) => ({...item, boundingBox: JSON.parse(item.boundingBox)})));
        // });
      }
    });
  }, [props.imageInfo]);

  function handleCheckBox_change(e) {
    let isShowOthers = e.target.checked;

    setIsShowOtherAnnotation(isShowOthers);

    if (isShowOthers) {
      AnnotatorAPI.GetAnnotation(0, props.imageInfo.imageId).then((res) => {
        // console.log(res.data)
        setAnnotations(
          Lodash.map(res.data, (item) => ({
            ...item,
            boundingBox: JSON.parse(item.boundingBox),
          }))
        );
      });
    } else {
      StatisticsAPI.GetAnnotationBySourceImageIdAndLabelId(
        props.imageInfo.imageId,
        props.imageInfo.labelId
      ).then((res) => {
        // console.log(res.data)
        setAnnotations(
          Lodash.map(res.data, (item) => ({
            ...item,
            boundingBox: JSON.parse(item.boundingBox),
          }))
        );
      });
    }
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.onClose("")}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <Box>{`${props.imageInfo.sourceImageSetName} / ${props.imageInfo.title}`}</Box>
          <Box>{`${props.imageInfo.label}; ${props.imageInfo.annotationCount}개`}</Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={_isShowOtherAnnotation}
                  onChange={handleCheckBox_change}
                />
              }
              label="다른 어노테이션 함께 보기"
            />
          </FormGroup>
        </DialogTitle>
        <DialogContent>
          <div id="container">
            <Stage
              container={"container"}
              width={550}
              height={480}
              // onMouseMove={handleStageMouseMove}
            >
              <U2AnnotationPreviewLayer
                id="LabelingLayer"
                scale={{ scale: 1, scaleBy: 0 }}
                // selectedTool={_selectedTool}
                // selectedLabel={_selectedLabel}
                imageInfo={_image}
                // labelStrokeEnabled={_labelStrokeEnabled}
                // labelFillEnabled={_labelFillEnabled}
                // moveToCenterTrigger={_moveToCenterTrigger}
                annotations={_annotations}
                // selectedAnnotationId={props.selectedAnnotationId}
                // selectedBoundingBox={props.selectedBoundingBox}
                // transformBoxes={props.transformBoxes}

                // onDataChanged={handlerLabelingLayerDataChanged}
                // onBoundingBoxAdded={props.onBoundingBoxAdded}
                // onBoundingBoxClick={props.onBoundingBoxClick}
                // onTransformBoxDragMove={props.onTransformBoxDragMove}
                // onTransformBoxDragEnd={props.onTransformBoxDragEnd}
                // onKeyDown={props.onKeyDown}
                // onLayerWheel={props.onLayerWheel}
                // onMoveToCenterEnd={props.onMoveToCenterEnd}
              />
            </Stage>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose("CANCEL")}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default U2AnnotationPreviewDialog;
